import './App.css';
import React, { useEffect } from 'react';
import RouterComponent from './RouteComponent';
import { FormProvider, ReviewMatrixProvider, LoanOfficerProvider, ApplicationStatusProvider, TestApplicationProvider, UserRoleProvider } from './context/FormContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function App() {
  localStorage.setItem('LoName', null)
  localStorage.setItem('Lofn', null)
  localStorage.setItem('Loln', null)
  localStorage.setItem('Lopn', null)
  localStorage.setItem('Loextn', null)
  localStorage.setItem('Loemail', null)
  localStorage.setItem('RsaName', null)
  localStorage.setItem('Rsafn', null)
  localStorage.setItem('Rsaln', null)
  localStorage.setItem('Rsapn', null)
  localStorage.setItem('Rsaextn', null)
  localStorage.setItem('Rsaemail', null)
  return (
    <FormProvider>
      <ReviewMatrixProvider>
        <TestApplicationProvider>
          <LoanOfficerProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ApplicationStatusProvider>
                <UserRoleProvider>
                  <RouterComponent />
                </UserRoleProvider> 
              </ApplicationStatusProvider>
            </MuiPickersUtilsProvider>
          </LoanOfficerProvider>
        </TestApplicationProvider>
      </ReviewMatrixProvider>
    </FormProvider>
  );
}

export default App;
