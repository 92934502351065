import React,{ useState, useContext} from 'react';
import { FormContext, LoanOfficerContext, ApplicationStatusContext, TestApplicationContext } from '../../../context/FormContext';
import { Grid, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import "../../styles/CommonHeader.css";
import baseUrl from '../../config/config';

export default function CommonHeader(props) {

    const [forms, setForms] = useContext(FormContext);
    const [newEntries,setNewEntries] = useContext(LoanOfficerContext);
    const [newStatus,setNewStatus] = useContext(ApplicationStatusContext);
    const [isTest,setIsTest] = useContext(TestApplicationContext);
    const [cxSpecialist,setCxSpecialist] = useState();
    const [statusOffer,setStatusOffer] = useState();
    const [openSnackbar,setOpenSnackbar] = useState(false);
    const [severity_type, setSeverityType] = useState('');
    const [hoverText, setHoverText] = useState('');
    const [detail,setDetail] = useState();
    const vertical = 'top';
    const horizontal = 'center';

    const appendMonth = (n) => {
        let num = n + 1;
        if (num <= 9) {
            return "0" + num;
        }
        return num
    }
    const appendDate = (n) => {
        let num = n;
        if (num <= 9) {
            return "0" + num;
        }
        return num
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleHover = (e) => {
        const selectedOption = e.target?.options[e.target.selectedIndex]?.text;
        setHoverText(selectedOption);
    };

    const dateFormat = (data) => {
        let date = new Date(data)
        let formatted_date = (appendMonth(date.getMonth())) + "-" + appendDate(date.getDate()) + "-" + date.getFullYear();
        return formatted_date.toString();
    }
    const formatPhoneNumber = (phoneNumber) => {
        const cleanedNumber = phoneNumber.replace(/\D/g, '');
        const formattedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
    
        return formattedNumber;
      };

    const handleClose = ()=>{
        setOpenSnackbar(false);
    }

    const handleCxSpecialist = (e) =>{
        let isCreate = localStorage.getItem("isCreate");
        if (isCreate === 'true'){
            setCxSpecialist(e.target.cx_specialist);
            props.setCxSpecialist(e.target.cx_specialist);
        }else{
            let method = 'PATCH';
            let tokenStr = localStorage.getItem('access_token')
            let body={
                "cx_specialist":e.target.value 
            }
            fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/update-cx-specialist/`, {
                method,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(async response => {
                    const results = await response.json()
                    if (response.status === 200){
                        setCxSpecialist(body.cx_specialist);
                        props.setCxSpecialist(body.cx_specialist);
                    }
                    // setCxSpecialist(e.target.value);
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
    }

    const handleApplicationStatus = (e) =>{
        let isCreate = localStorage.getItem("isCreate");
        if (isCreate === 'true'){
            setStatusOffer(e.target.application_status);
            setNewStatus(e.target.application_status);
        }else{
            let method = 'PATCH';
            let tokenStr = localStorage.getItem('access_token')
            let body={
                "application_status":e.target.value === '0' ? 1 : Number(e.target.value)
            }
            fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/update-application-status/`, {
                method,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
            .then(async response => {
                const results = await response.json()
                if (response.status === 200) {
                    setStatusOffer(body.application_status);
                    setNewStatus(body.application_status);
                    setOpenSnackbar(true)
                    setSeverityType("success");
                    // setForms(results.trade_in_application);
                    setDetail(results.message);
                    setIsTest(results.is_test_app);
                }else{
                    setOpenSnackbar(true)
                    setSeverityType("error");
                    setDetail(results.detail);
                }
                // setCxSpecialist(e.target.value);
            })
            .catch((error) => {
                console.log('error', error)
            })
        }
    }

    return(
        <Grid container className='header-container'>
            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                <div class="views-widget">
                    <select
                        name="cxSpecialist"
                        className="form_select"
                        style={{ color: '#000',whiteSpace:"nowrap",textOverflow:"ellipsis" }}
                        onChange={(e)=>handleCxSpecialist(e)}
                        value={cxSpecialist !== undefined ? cxSpecialist : props.cxSpecialist}
                    >
                        <option value="0">Select CX Specialist</option>
                       {props.cxSpecialists && props.cxSpecialists.map((item, index) => (
                            <option key={index} value={item.id} >
                                {item.get_user_full_name}
                            </option>
                        ))}
                    </select>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingRight:"6px" }} className='align-end row formDiv'>
                <div class="views-widget tooltip" onMouseEnter={handleHover}>
                    <select
                        name="statusOffer"
                        className="form_select"
                        style={{ color: '#000',whiteSpace:"nowrap",textOverflow:"ellipsis",cursor:"pointer" }}
                        onChange={(e)=>handleApplicationStatus(e)}
                        value={newStatus}
                    >
                        {/* <option value="0">Select Application Status</option> */}
                        {props.statusOffer && props.statusOffer.map((item, index) => (
                            <option key={index} value={item.application_status_id} >
                                {item.application_status}
                            </option>
                        ))}
                    </select>
                    <span className="tooltiptext">{hoverText}</span>
                </div>
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert onClose={handleClose} severity={severity_type}>
                    <div className="error" style={{ color: 'white' }}>
                        <span>{detail}</span>
                    </div>
                </Alert>
            </Snackbar>
        </Grid>
    )
}