import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useFormik } from 'formik';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import baseUrl from '../../config/config';
import '../../styles/Bgimages.css';
import '../../styles/style.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/login">
						<CloseIcon /></NavLink>
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function Resetpassword(props) {
	const [open, setOpen] = React.useState(true);
	const handleClose = () => {
		setOpen(false);
	};
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [detail, setDetail] = React.useState('');
	const vertical = 'top';
	const horizontal = 'center';
	const handleClose1 = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};
	const initialValues = {
		new_password: '',
		confirm_password: '',
		token: '',
		email: ''
	}
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [token, setToken] = useState('');
	const [severity_type, setSeverityType] = React.useState('');
	const [showNewPassword, setShowNewPassword] = React.useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);

	const handleKeyDown = e => {
		if (e.key === " ") {
		  e.preventDefault();
		}
	  };

	const handleClickNew = () => {
		setShowNewPassword(prev => !prev);
	}
	const handleClickConfirm = () => {
		setShowConfirmPassword(prev => !prev);
	}

	useEffect(() => {
		if (props.history && props.history.location && props.history.location.state &&
			props.history.location.state.length > 0) {
			setEmail(props.history.location.state[0].email)
			setPassword(props.history.location.state[0].password)
			setToken(props.history.location.state[0].token)
		}
	}, [props])

	const onSubmit = values => {
		if (values.new_password !== values.confirm_password) {
		}
		var tokenStr = localStorage.getItem('access_token')
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get('token');
		const email = urlParams.get('email');
		setIsLoading(true);
		fetch(`${baseUrl.baseUrl}reset-password/`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				token: token, email: email,
				new_password: values.new_password, confirm_password: values.confirm_password
			})
		})
			.then(async response => {
				const results = await response.json()
				if (response.status === 200) {
					setDetail(results.message);
					setSeverityType("success");
					setOpenSnackbar(true);
					await sleep(2000);
					setIsLoading(false);
					history.push('./')
				}
				else {
					setDetail(results.detail)
					setSeverityType("error");
					setOpenSnackbar(true);
					setIsLoading(false);
				}
			})
	}

	const validate = values => {
		let errors = {}

		if (!values.new_password) {
			errors.new_password = 'Please enter new password'
		} else if (values.new_password.length <= 6) {
			errors.new_password = "Must be more than 6 characters";
		}

		if (!values.confirm_password) {
			errors.confirm_password = 'Please enter confirm password'
		} else if (values.confirm_password.length <= 6) {
			errors.confirm_password = "Must be more than 6 characters";
		}
		return errors;
	}

	const formik = useFormik({
		initialValues,
		onSubmit,
		validate
	})

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}
	return (
		<Dialog onClose={handleClose} disableBackdropClick={true} aria-labelledby="customized-dialog-title" open={open} className="bgimage" style={{ backgroundImage: 'url("/assets/images/loginbg.png")' }}>
			<DialogTitle className="modal_header" id="customized-dialog-title" onClose={handleClose}  >
				Reset Password
			</DialogTitle>
			<DialogContent dividers className="modal_body" >
				<Typography variant="h6" gutterBottom className="ForgotBold" style={{ fontSize: '14px', fontFamily: 'PoppinsRegualar', color: 'black' }}>
					<form autoComplete="off" className="color" onSubmit={formik.handleSubmit}>
						<TextField
							margin="normal"
							fullWidth
							id="new_password"
							name="new_password"
							type={showNewPassword ? "text" : "password"}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.new_password}
							onKeyDown={handleKeyDown}
							autoComplete="off"
							placeholder="New Password"
							style={{ color: 'black' }}
							className="showPsw"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockOpenRoundedIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={handleClickNew}
											edge="end"
										>
											{showNewPassword ? <Visibility className="showPsw" /> : <VisibilityOff className="showPsw" />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
						{formik.touched.new_password && formik.errors.new_password ? <div className="error">{formik.errors.new_password}</div> : null}
						<TextField
							margin="normal"
							fullWidth
							id="confirm_password"
							name="confirm_password"
							type={showConfirmPassword ? "text" : "password"}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.confirm_password}
							autoComplete="off"
							onKeyDown={handleKeyDown}
							placeholder="Confirm Password"
							style={{ color: 'black' }}
							className="showPsw"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockOpenRoundedIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={handleClickConfirm}
											edge="end"
										>
											{showConfirmPassword ? <Visibility className="showPsw" /> : <VisibilityOff className="showPsw" />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
						{formik.touched.confirm_password && formik.errors.confirm_password ? <div className="error">{formik.errors.confirm_password}</div> : null}
						<Grid container spacing={2} className="form_layout_btn" style={{ justifyContent: 'center' }}>
							<Button
								className="primary_btn"
								variant="contained"
								type="submit"
								disabled={isLoading === true}
								color="primary" >
								{isLoading === false ? 'Submit' : <CircularProgress size={24} color="white" />}
							</Button>
							<Button
								className="primary_btn_border"
								variant="outlined"
								color="primary"
								style={{ background: '#fff' }}>
								<NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/login">
									Cancel
								</NavLink>
							</Button>
						</Grid>
					</form>
				</Typography>
			</DialogContent>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleClose1}
				anchorOrigin={{ vertical, horizontal }} >
				<Alert onClose={handleClose1} severity={severity_type}>
					<div className="error" style={{ color: 'white' }}> <span>{detail}</span></div>
				</Alert>
			</Snackbar>
		</Dialog>
	);
}

