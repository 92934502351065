import React,{useEffect, useState, useContext} from 'react';
import CommonHeader from './CommonHeader';
import { Grid, TextField, Button, Radio, RadioGroup, FormControlLabel, TableFooter, Checkbox } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import RemoveIcon from '@material-ui/icons/Remove';
import Paper from '@material-ui/core/Paper';
import { useFormik } from 'formik';
import { NavLink } from "react-router-dom";
import Reviewmodal from './Reviewmodal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import baseUrl from '../../config/config';
import { makeStyles } from '@material-ui/core/styles';
import Modalpopup from '../commonViews/Modal';
import { FormContext, ReviewMatrixContext, ApplicationStatusContext } from '../../../context/FormContext';
import PageLoader from '../commonViews/PageLoader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import "../../styles/CommonHeader.css";
import "../../styles/styleNew.css";
import '../../styles/style.css';

const show = {
    display: 'flex',
}
const hide = {
    display: 'none'
}

const guarantee_period = ["90","120","150"]

export default function ValuationInformation(props) {
    let valuationProviderData = {};
    let isAvmUpdatedValue = false;
    let estimatedAvg;
    let minimumAvg;
    let maximumAvg;
    const [forms, setForms] = useContext(FormContext);
    const [calculatedValues,setCalculatedValues] = useContext(ReviewMatrixContext);
    const [newStatus,setNewStatus] = useContext(ApplicationStatusContext);
    const [valuationDetails, setValuationDetails] = useState(false);
    const [open, setOpen] = useState(false);
    const [manualOpen, setManualOpen] = useState(false);
    const [manualOption, setManualOption] = useState();
    const [manualOptionsOpen, setManualOptionsOpen] = useState(false);
    const [validationSource, setValidationSource] = useState([]);
    const [displayProvider, setDisplayProvider] = useState([])
    const [avmValuationType, setAvmValuationType] = useState(false)
    const [openAVMValuesPopup, setOpenAVMValuesPopup] = useState(false)
    const [valuationProvider, setValuationProvider] = useState([])
    const [displayPropertyError, setDisplayPropertyError] = useState(true)
    const [mgpLoader, setMgpLoader] = useState(false);
    const [checkBoxValuePrevious, setCheckBoxValuePrevious] = useState([])
    const [checkBoxValue, setCheckBoxValue] = useState([])
    const [avmValuationTypeValue, setAvmValuationTypeValue] = useState(null)
    const [openAVMValuationSourcePopup, setOpenAVMValuationSourcePopup] = useState(false)
    const [avmValuationManual, setAvmValuationManual] = useState(false)
    const [offerRes, setOfferRes] = useState([{}])
    const [offerMatrixObj, setOfferMatrixObj] = useState([]);
    const [loadProgressing, setLoadProgressing] = useState(false);
    const [detail, setDetail] = React.useState('');
    const [severity_type, setSeverityType] = React.useState('');
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
    const [radioevent, setRadioEvent] = useState()
    const [radiopTerm, setRadioPterm] = useState()
    const [radioCol, setRadioCol] = useState()
    const [radioruleVer, setRadioRulever] = useState()
    const [radioruleId, setRadioRuleId] = useState()
    const [homevalue, setHomeValue] = useState()
    const [resultDpcc, setResultDpcc] = useState()
    const [getAVMLoader, setGetAVMLoader] = useState(false);
    const [avmProviderRes, setAvmProviderRes] = useState([])
    const [addressAVMValidate, setAddressAVMValidate] = useState([])
    const [disabledDeleteIcons, setDisabledDeleteIcons] = useState({});
    const [deleteClicked, setDeleteClicked] = useState(false);
    const [deleteAlertText, setDeleteAlertText] = useState();
    const [historyId, setHistoryId] = useState();
    //const [nuthshellAPILoader, setNutshellAPILoader] = useState(false)
    const [submittedCRM, setSubmittedCRM] = useState(false);
    const [updatedCheckedRows,setUpdatedCheckedRows] = useState(() => {
        const initialCheckedRows = {};
        forms?.valuation_details_history.forEach(row => {
            initialCheckedRows[row.valuation_details_history_id] = row.selected_for_average_calc;
        });
        return initialCheckedRows;
    })
    const [checkedRows, setCheckedRows] = useState(() => {
        const initialCheckedRows = {};
        forms?.valuation_details_history.forEach(row => {
            initialCheckedRows[row.valuation_details_history_id] = row.selected_for_average_calc;
        });
        return initialCheckedRows;
    }); 
    const [trueRows, setTrueRows] = useState(() => {
        const initialTrueRows = {};
        forms?.valuation_details_history.forEach(row => {
            initialTrueRows[row.valuation_details_history_id] = row.selected_for_average_calc;
        });
        let trueids = Object.keys(initialTrueRows).filter(id => initialTrueRows[id] === true);
        return trueids;
    });
    const [ppgPercentage, setPpgPercentage] = useState(''); 
    const [ppgAmount, setPpgAmount] = useState(''); 
    const [marketInsights,setMarketInsights] = useState();
    const [garageSpaces,setGarageSpaces] = useState();
    const [guaranteePeriod, setGuaranteePeriod] = useState(''); 
    const [manualResults, setManualResults] = useState(); 
    const [matrixSelect, setMatrixSelect] = useState(false); 
    const [manualSelect, setManualSelect] = useState(false); 
    const [manualCalc, setManualCalc] = useState(true); 
    const [plunkLoader, setPlunkLoader] = useState(false); 
    const [averageLoader, setAverageLoader] = useState(false); 
    const [plunkMarketInsights, setPlunkMarketInsights] = useState(false); 
    const [recalculatedAmt, setRecalculateAmt] = useState();
    const [recalculateStatus, setRecalculuteStatus] = useState(false);
    const vertical = 'top';
    const horizontal = 'center';

    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    
    const handleDeleteClose = () =>{
        setOpenDeleteAlert(false);
    }

    const initialValues = {
        propertyInfoEstimated: null,
        propertyInfoMinValue: '',
        propertyInfoMaxValue: '',
        veros_avm_confidence_score: null,
        veros_avm_fsd_value: null,
        is_entry_type_manual: avmValuationManual,
    }

    const validate = values => {
        let errors = {};

    if (!values.propertyInfoValuation ||
        valuationDetails === false ||
        (valuationDetails === true && (!values.propertyInfoEstimated || values.propertyInfoEstimated === ""))
    ) {
        errors.propertyInfoEstimated = "Please fill the Valuation details section mandatory(*) fields."
    }

    return errors

    }

    const onSubmit = values => {
        let downPaymentVal = formik.values?.downpayment_percentage?.toString().replace(/%/g, '')
        let closingCostVal = formik.values?.closing_cost_percentage?.toString().replace(/%/g, '')
        let commisionPerVal = formik.values?.commision_percentage?.toString().replace(/%/g, '')
        let ppgPerVal = formik.values?.ppg_percent?.toString().replace(/%/g, '')
        if(downPaymentVal === undefined || downPaymentVal === ""){
            formik.setFieldValue('downpayment_percentage',0)
        } 
        if(formik?.values?.months_of_piti === undefined || formik?.values?.months_of_piti === ""){
            formik.setFieldValue('months_of_piti',0)
        } 
        if(closingCostVal === undefined || closingCostVal === ""){
            formik.setFieldValue('closing_cost_percentage',0)
        } 
        if(commisionPerVal === undefined || commisionPerVal === ""){
            formik.setFieldValue('commision_percentage',0)
        } 
        if(ppgPerVal === undefined || ppgPerVal === ""){
            formik.setFieldValue('ppg_percent',0)
        }
        setMgpLoader(true)
        setDisplayPropertyError(false)
        let provider = valuationProvider && valuationProvider?.valuation_provider?.filter(prov => displayProvider == prov?.valuation_provider)
        let tokenStr = localStorage.getItem('access_token')
        let body = {
            existing_property: {
                street_address: forms.existing_property.street_address,
                city: forms.existing_property.city,
                state: forms.existing_property.state.state_id,
                county: forms.existing_property.county.county_id,
                zip_code: forms.existing_property.zip_code.zip_code_id,
                estimated_home_value: values.propertyInfoEstimated.toString().replace(/,/g, ''),
                valuation_source: Number(values.propertyInfoValuation),
                property_type: forms.existing_property.property_type.property_type_id,
                owner_estimated_home_value: parseFloat(forms?.existing_property?.owner_estimated_home_value?.replace(/,/g, '')),
                home_condition:forms.existing_property?.home_condition?.home_condition_id,
                house_built_year:forms.existing_property.house_built_year,
                condo_unit_number:forms.existing_property.condo_unit_number,
                condo_association_name:forms.existing_property.condo_association_name,
                property_in_sqft:parseFloat(forms?.existing_property?.property_in_sqft?.replace(/,/g, '')),
                tax_escrow_flag:forms.existing_property.tax_escrow_flag,
                insurance_escrow_flag:forms.existing_property.insurance_escrow_flag,
                reason_for_sale: forms.existing_property.reason_for_sale,
                ready_for_selling: forms.existing_property.ready_for_selling,
                new_home_purchase_price: parseFloat(forms?.existing_property?.new_home_purchase_price?.replace(/,/g, '')),
                how_did_you_hear_about_us:forms.existing_property.how_did_you_hear_about_us,
                owner_occupied: forms.existing_property.owner_occupied,
                purchase_date:forms.existing_property.purchase_date,
                purchase_price:parseFloat(forms?.existing_property?.purchase_price?.replace(/,/g, '')),
                takeout_offer:parseFloat(forms?.existing_property?.takeout_offer?.replace(/,/g, '')),
                does_home_have_a_septic_system: forms?.existing_property?.does_home_have_a_septic_system,
                takeout_offer_percentage:forms.existing_property.takeout_offer_percentage,
                defects:forms.existing_property.defects,
                alterations_and_problems:forms.existing_property.alterations_and_problems,
                valuation_provider: (provider != undefined || provider != null) && provider[0]?.valuation_provider_id != (null || undefined) ? provider[0]?.valuation_provider_id : forms?.existing_property?.valuation_provider?.valuation_provider == displayProvider ? forms?.existing_property?.valuation_provider?.valuation_provider_id : null,

                veros_avm_minimum_value: values.propertyInfoValuation == 2 ? (avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_minimum_value !== null && forms?.existing_property?.veros_avm_minimum_value !== undefined && forms?.existing_property?.valuation_provider?.valuation_provider.toLowerCase().includes('veros') && forms?.existing_property?.veros_avm_minimum_value == values.propertyInfoMinValue && parseFloat(values?.propertyInfoMinValue?.toString().replace(',', ''))) ? forms?.existing_property?.veros_avm_minimum_value :
                    ((avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === false) || (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === true && avmProviderRes?.[0]?.['veros']?.veros_avm_minimum_value == parseFloat(values?.propertyInfoMinValue.toString().replace(',', ''))) ? avmProviderRes?.[0]?.['veros']?.veros_avm_minimum_value : displayProvider && displayProvider.toString().toLowerCase().includes('veros') ? parseFloat(values?.propertyInfoMinValue.toString().replace(',', '')) : null) : null,

                veros_avm_maximum_value: values.propertyInfoValuation == 2 ? (avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_maximum_value !== null && forms?.existing_property?.veros_avm_maximum_value !== undefined && forms?.existing_property?.valuation_provider?.valuation_provider.toLowerCase().includes('veros') && forms?.existing_property?.veros_avm_maximum_value == values.propertyInfoMaxValue && parseFloat(values?.propertyInfoMaxValue.toString().replace(',', ''))) ? forms?.existing_property?.veros_avm_maximum_value :
                    ((avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === false) || (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === true && avmProviderRes?.[0]?.['veros']?.veros_avm_maximum_value == parseFloat(values?.propertyInfoMaxValue.toString().replace(',', ''))) ? avmProviderRes?.[0]?.['veros']?.veros_avm_maximum_value : displayProvider && displayProvider.toString().toLowerCase().includes('veros') ? parseFloat(values?.propertyInfoMaxValue.toString().replace(',', '')) : null) : null,

                veros_avm_value: values.propertyInfoValuation == 2 ? (avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_value !== null && forms?.existing_property?.veros_avm_value !== undefined && forms?.existing_property?.valuation_provider?.valuation_provider.toLowerCase().includes('veros') && forms?.existing_property?.veros_avm_value == values.propertyInfoEstimated && parseFloat(values?.propertyInfoEstimated.toString().replace(',', ''))) ? forms?.existing_property?.veros_avm_value :
                    ((avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === false) || (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === true && avmProviderRes?.[0]?.['veros']?.veros_avm_value == parseFloat(values?.propertyInfoEstimated.toString().replace(',', ''))) ? avmProviderRes?.[0]?.['veros']?.veros_avm_value : displayProvider && displayProvider.toString().toLowerCase().includes('veros') ? parseFloat(values?.propertyInfoEstimated.toString().replace(',', '')) : null) : null,

                veros_avm_confidence_score: values.propertyInfoValuation == 2 ? (avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_confidence_score !== null && forms?.existing_property?.veros_avm_confidence_score !== undefined && forms?.existing_property?.valuation_provider?.valuation_provider.toLowerCase().includes('veros') && forms?.existing_property?.veros_avm_confidence_score == values.veros_avm_confidence_score && parseFloat(values?.veros_avm_confidence_score.toString().replace(',', ''))) ? forms?.existing_property?.veros_avm_confidence_score :
                    ((avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === false) || (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === true && avmProviderRes?.[0]?.['veros']?.veros_avm_confidence_score == parseFloat(values?.veros_avm_confidence_score.toString().replace(',', ''))) ? avmProviderRes?.[0]?.['veros']?.veros_avm_confidence_score : displayProvider && displayProvider.toString().toLowerCase().includes('veros') ? parseFloat(values?.veros_avm_confidence_score?.toString()?.replace(',', '')) : null) : values.propertyInfoValuation != 1 && avmValuationType == false && displayProvider?.toString().toLowerCase().includes('veros') && values.veros_avm_fsd_value !== (null || '') ? values.veros_avm_confidence_score === ""? null : Number(values.veros_avm_confidence_score?.toString().replace(',', '')) : null,

                veros_avm_fsd_value: values.propertyInfoValuation == 2 ? (avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_fsd_value !== null && forms?.existing_property?.veros_avm_fsd_value !== undefined && forms?.existing_property?.valuation_provider?.valuation_provider.toLowerCase().includes('veros') && forms?.existing_property?.veros_avm_fsd_value == values.veros_avm_fsd_value && parseFloat(values?.veros_avm_fsd_value.toString().replace(',', ''))) ? forms?.existing_property?.veros_avm_fsd_value :
                    ((avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === false) || (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined && avmValuationManual === true && avmProviderRes?.[0]?.['veros']?.veros_avm_fsd_value == parseFloat(values?.veros_avm_fsd_value?.toString().replace(',', ''))) ? avmProviderRes?.[0]?.['veros']?.veros_avm_fsd_value : displayProvider && displayProvider.toString().toLowerCase().includes('veros') ? parseFloat(values?.veros_avm_fsd_value?.toString()?.replace(',', '')) : null) : values.propertyInfoValuation != 1 && avmValuationType == false && displayProvider?.toString().toLowerCase().includes('veros') && values.veros_avm_fsd_value !== (null || '') ? values.veros_avm_fsd_value : null,

                plunk_avm_value: values.propertyInfoValuation == 2 ? (avmProviderRes.length === 0 && forms?.existing_property?.plunk_avm_value !== null && forms?.existing_property?.plunk_avm_value !== undefined && forms?.existing_property?.valuation_provider?.valuation_provider.toLowerCase().includes('plunk') && forms?.existing_property?.plunk_avm_value == values.propertyInfoEstimated && parseFloat(values?.propertyInfoEstimated.toString().replace(',', ''))) ? forms?.existing_property?.plunk_avm_value :
                    ((avmProviderRes.length === 1 && avmProviderRes?.[0]?.['plunk'] !== null && avmProviderRes?.[0]?.['plunk'] !== undefined && avmValuationManual === false) || (avmProviderRes.length === 1 && avmProviderRes?.[0]?.['plunk'] !== null && avmProviderRes?.[0]?.['plunk'] !== undefined && avmValuationManual === true && avmProviderRes?.[0]?.['plunk']?.plunk_avm_value == values.propertyInfoEstimated && parseFloat(values?.propertyInfoEstimated.toString().replace(',', '')))) ? avmProviderRes?.[0]?.['plunk']?.plunk_avm_value :
                        ((avmProviderRes.length === 2 && avmProviderRes?.[1]?.['plunk'] !== null && avmProviderRes?.[1]?.['plunk'] !== undefined && avmValuationManual === false) || (avmProviderRes.length === 1 && avmProviderRes?.[0]?.['plunk'] !== null && avmProviderRes?.[1]?.['plunk'] !== undefined && avmValuationManual === true && avmProviderRes?.[1]?.['plunk']?.plunk_avm_value == values.propertyInfoEstimated && parseFloat(values?.propertyInfoEstimated.toString().replace(',', ''))) ? avmProviderRes?.[1]?.['plunk']?.plunk_avm_value : (displayProvider && displayProvider?.toString().toLowerCase() === 'plunk' ? parseFloat(values?.propertyInfoEstimated.toString().replace(',', '')) : null)) : null,

                minimum_value: (values.propertyInfoMinValue === null || values.propertyInfoMinValue === '') ? null : parseFloat(values?.propertyInfoMinValue?.toString().replace(/,/g, '')).toFixed(2),
                maximum_value: (values.propertyInfoMaxValue === null || values.propertyInfoMaxValue === '') ? null : parseFloat(values?.propertyInfoMaxValue?.toString().replace(/,/g, '')).toFixed(2),
            },
            existing_mortgage:{
                mort1_payoff_amount:forms?.existing_mortgage?.mort1_payoff_amount,
                mort1_curr_piti_payment:forms?.existing_mortgage?.mort1_curr_piti_payment,
                mort2_type:forms?.existing_mortgage?.mort2_type?.mortgage_type_id,
                mort2_payoff_amount:forms?.existing_mortgage?.mort2_payoff_amount,
                mort2_curr_piti_payment:forms?.existing_mortgage?.mort2_curr_piti_payment,
                about_other_loans_or_liens:forms?.existing_mortgage?.about_other_loans_or_liens,
                describe_fees:forms?.existing_mortgage?.describe_fees,
                monthly_amount:forms?.existing_mortgage?.monthly_amount,
            },
            borrower:{
                first_name:forms.borrowers[0]?.first_name,
                last_name:forms.borrowers[0]?.last_name,
                email:forms.borrowers[0]?.email,
                phone_no:forms.borrowers[0]?.phone_no,
                extension:forms.borrowers[0]?.extension,
            },
            co_borrower:{
                first_name:forms.borrowers[1]?.first_name,
                last_name:forms.borrowers[1]?.last_name,
                email:forms.borrowers[1]?.email,
                phone_no:forms.borrowers[1]?.phone_no,
                extension:forms.borrowers[1]?.extension,
            },
            is_avm_updated: isAvmUpdatedValue == true ? true : false,
            is_entry_type_manual: avmValuationManual,
            downpayment_percentage: Number(downPaymentVal),
            months_of_piti: Number(formik?.values?.months_of_piti),
            closing_cost_percentage: Number(closingCostVal),
            commision_percentage: Number(commisionPerVal),
            ppg_percent: Number(ppgPerVal),
            selected_for_average_calc:Object.keys(updatedCheckedRows).filter(id => updatedCheckedRows[id]).map(Number)
        }

        let url = '', method = '';

        if (forms?.trade_in_application_id) {
            url = `${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/`
            method = 'PATCH'
        } else {
            url = `${baseUrl.baseUrl}trade-in-application/`
            method = 'POST'
        }
        fetch(url, {
            method,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setDetail(results.message);
                    props.setIscalculated(false);
                    setRecalculuteStatus(false);
                    setNewStatus(results?.trade_in_application?.application_status?.application_status_id);
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    setForms(results.trade_in_application)
                    setGarageSpaces(results.trade_in_application?.veros_avm_property_data[0]?.garage_spaces)
                    setMarketInsights(results.trade_in_application?.plunk_market_insights_data[0]);
                    await sleep(1000);
                    setOpenSnackbar(false);
                    setMgpLoader(false)
                    isAvmUpdatedValue = false
                    if(downPaymentVal) {
                        formik.setFieldValue('downpayment_percentage', results?.trade_in_application?.calculated_offer?.downpayment_percentage)
                    }
                    if(formik?.values?.months_of_piti < 1) {
                        formik.setFieldValue('months_of_piti', results?.trade_in_application?.calculated_offer?.months_of_piti)
                    }
                    if(closingCostVal) {
                        formik.setFieldValue('closing_cost_percentage', results?.trade_in_application?.calculated_offer?.closing_cost_percentage)
                    }
                    if(commisionPerVal) {
                        formik.setFieldValue('commision_percentage', results?.trade_in_application?.calculated_offer?.commision_percentage)
                    }
                    if(ppgPerVal) {
                        formik.setFieldValue('ppg_percent', results?.trade_in_application?.calculated_offer?.ppg_percent)
                    }
                }
                else {
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setMgpLoader(false)
                    }
                    else {
                        setDetail("something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setMgpLoader(false)
                    }
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }


    const averageValues = () =>{
        setAverageLoader(true);
        let downPaymentVal = formik.values?.downpayment_percentage?.toString().replace(/%/g, '')
        let closingCostVal = formik.values?.closing_cost_percentage?.toString().replace(/%/g, '')
        let commisionPerVal = formik.values?.commision_percentage?.toString().replace(/%/g, '')
        let ppgPerVal = formik.values?.ppg_percent?.toString().replace(/%/g, '')
        let tokenStr = localStorage.getItem('access_token')
        let method = 'PATCH';
        let url = `${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/`;
        let body = {
            existing_property: {
                street_address: forms.existing_property.street_address,
                city: forms.existing_property.city,
                state: forms.existing_property.state.state_id,
                county: forms.existing_property.county.county_id,
                zip_code: forms.existing_property.zip_code.zip_code_id,
                estimated_home_value: estimatedAvg !== undefined ? estimatedAvg : 0 ,
                valuation_source: 8,
                property_type: forms.existing_property.property_type.property_type_id,
                owner_estimated_home_value: parseFloat(forms?.existing_property?.owner_estimated_home_value?.replace(/,/g, '')),
                home_condition:forms.existing_property?.home_condition?.home_condition_id,
                house_built_year:forms.existing_property.house_built_year,
                property_in_sqft:parseFloat(forms?.existing_property?.property_in_sqft?.replace(/,/g, '')),
                condo_unit_number:forms.existing_property.condo_unit_number,
                condo_association_name:forms.existing_property.condo_association_name,
                tax_escrow_flag:forms.existing_property.tax_escrow_flag,
                insurance_escrow_flag:forms.existing_property.insurance_escrow_flag,
                reason_for_sale: forms.existing_property.reason_for_sale,
                defects:forms.existing_property.defects,
                alterations_and_problems:forms.existing_property.alterations_and_problems,
                ready_for_selling: forms.existing_property.ready_for_selling,
                new_home_purchase_price: parseFloat(forms?.existing_property?.new_home_purchase_price?.replace(/,/g, '')),
                how_did_you_hear_about_us:forms.existing_property.how_did_you_hear_about_us,
                owner_occupied: forms.existing_property.owner_occupied,
                purchase_date:forms.existing_property.purchase_date,
                purchase_price:parseFloat(forms?.existing_property?.purchase_price?.replace(/,/g, '')),
                takeout_offer:parseFloat(forms?.existing_property?.takeout_offer?.replace(/,/g, '')),
                takeout_offer_percentage:forms.existing_property.takeout_offer_percentage,
                does_home_have_a_septic_system: forms?.existing_property?.does_home_have_a_septic_system,
                valuation_provider: null,
                veros_avm_minimum_value: null,
                veros_avm_maximum_value: null,
                veros_avm_value: null,
                veros_avm_confidence_score: null,
                veros_avm_fsd_value: null,
                plunk_avm_value: null,
                minimum_value: minimumAvg !== undefined ? minimumAvg : 0 ,
                maximum_value: maximumAvg !== undefined ? maximumAvg : 0 ,
            },
            existing_mortgage:{
                mort1_payoff_amount:forms?.existing_mortgage?.mort1_payoff_amount,
                mort1_curr_piti_payment:forms?.existing_mortgage?.mort1_curr_piti_payment,
                mort2_type:forms?.existing_mortgage?.mort2_type?.mortgage_type_id,
                mort2_payoff_amount:forms?.existing_mortgage?.mort2_payoff_amount,
                mort2_curr_piti_payment:forms?.existing_mortgage?.mort2_curr_piti_payment,
                about_other_loans_or_liens:forms?.existing_mortgage?.about_other_loans_or_liens,
                describe_fees:forms?.existing_mortgage?.describe_fees,
                monthly_amount:forms?.existing_mortgage?.monthly_amount,
            },
            borrower:{
                first_name:forms.borrowers[0]?.first_name,
                last_name:forms.borrowers[0]?.last_name,
                email:forms.borrowers[0]?.email,
                phone_no:forms.borrowers[0]?.phone_no,
                extension:forms.borrowers[0]?.extension,
            },
            co_borrower:{
                first_name:forms.borrowers[1]?.first_name,
                last_name:forms.borrowers[1]?.last_name,
                email:forms.borrowers[1]?.email,
                phone_no:forms.borrowers[1]?.phone_no,
                extension:forms.borrowers[1]?.extension,
            },
            is_avm_updated: isAvmUpdatedValue == true ? true : false,
            is_entry_type_manual: true,
            downpayment_percentage: Number(downPaymentVal),
            months_of_piti: Number(formik?.values?.months_of_piti),
            closing_cost_percentage: Number(closingCostVal),
            commision_percentage: Number(commisionPerVal),
            ppg_percent: Number(ppgPerVal),
            selected_for_average_calc:Object.keys(checkedRows).filter(id => checkedRows[id]).map(Number)
        }
        fetch(url, {
            method,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setDetail(results.message);
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    setForms(results.trade_in_application)
                    formik.setFieldValue('propertyInfoEstimated', results?.trade_in_application?.existing_property?.estimated_home_value ? results.trade_in_application.existing_property.estimated_home_value : null)
                    formik.setFieldValue('propertyInfoValuation', 8)
                    formik.setFieldValue('propertyInfoMinValue', results?.trade_in_application?.existing_property?.minimum_value ? results.trade_in_application.existing_property.minimum_value : '')
                    formik.setFieldValue('propertyInfoMaxValue', results?.trade_in_application?.existing_property?.maximum_value ? results.trade_in_application.existing_property.maximum_value : null)
                    formik.setFieldValue('veros_avm_fsd_value', '')
                    formik.setFieldValue('veros_avm_confidence_score', '')
                    setDisplayProvider("");
                    handleValuationSourceValue();
                    setAverageLoader(false);
                    await sleep(1000);
                    setUpdatedCheckedRows(() => {
                        const initialCheckedRows = {};
                        results?.trade_in_application?.valuation_details_history.forEach(row => {
                            initialCheckedRows[row.valuation_details_history_id] = row.selected_for_average_calc;
                        });
                        return initialCheckedRows;
                    })
                    setOpenSnackbar(false);
                    setMgpLoader(false)
                    isAvmUpdatedValue = false
                }
                else {
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setMgpLoader(false)
                    }
                    else {
                        setDetail("something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                        setMgpLoader(false)
                    }
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }

    const showMarketInsights =()=>{
        setPlunkLoader(true);
        let tokenStr = localStorage.getItem('access_token')
        let headers = { "Authorization": `Bearer ${tokenStr}` }
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/get-plunk-market-insights?zipcode=${forms.existing_property?.zip_code?.zip_code}`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setMarketInsights(result);
                    setPlunkLoader(false);
                    handlePlunkMarketInsights();
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
        })
    }

    const formik = useFormik({
        initialValues,
        validate,
        onSubmit,
    });


    var label_array
    var name_label = '';
    var names_label = '';
    const useStyles = makeStyles((theme) => ({
        table: {
            size: 'large',
            height: '100%',
            minWidth: '500px !important',
            borderCollapse: 'collapse'
        },
    }));
    const classes = useStyles();
    const getApplicationData = (id) => {
        let tokenStr = localStorage.getItem('access_token')
        let headers = { "Authorization": `Bearer ${tokenStr}` }
        fetch(`${baseUrl.baseUrl}trade-in-application/${id}/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setForms(result)
                    setGarageSpaces(result?.veros_avm_property_data[0]?.garage_spaces);
                    setMarketInsights(result?.plunk_market_insights_data[0]);
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
    }

    useEffect(()=>{
        let localID = localStorage.getItem('application_id');
        let tokenStr = localStorage.getItem('access_token')
        let headers = { "Authorization": `Bearer ${tokenStr}` }

        if (forms && forms.length !== 0) {
            getApplicationData(localID);
            editValues();
        } else if (forms && forms.length === 0 && localID !== null) {
            getApplicationData(localID);
        }

        fetch(`${baseUrl.baseUrl}get-valuation-source-details/`,
        {
            headers
        })
        .then(async response => {
            const result = await response.json()
            if (response.status === 200 || response.status === 201) {
                setValidationSource(result.result)
            }
            else if (response.status === 403) {
                localStorage.setItem('access_token', '')
                props.history.push('/');
            }
        })
    },[])

    const editValues = () => {
        
        if ( forms?.existing_property?.estimated_home_value != null || forms?.existing_property?.minimum_value != null || forms?.existing_property?.maximum_value != null || forms?.existing_property?.valuation_provider?.valuation_provider != null){
            setValuationDetails(true);
        }

        formik.setFieldValue('propertyInfoValuation', forms?.existing_property?.valuation_source ? forms.existing_property.valuation_source.valuation_source_id : null)

        formik.setFieldValue('veros_avm_confidence_score', forms?.existing_property?.valuation_provider?.valuation_provider?.toLowerCase()?.includes('veros') && forms?.existing_property?.valuation_source?.valuation_source_id !== 1 ? forms?.existing_property?.veros_avm_confidence_score : forms?.existing_property?.valuation_provider?.valuation_provider !== "Veros" && forms?.existing_property?.valuation_source?.valuation_source_id !== 1 ? '' : forms?.existing_property?.valuation_provider?.valuation_provider === "Veros" && forms?.existing_property?.valuation_source?.valuation_source_id !== 1 ? forms?.existing_property?.veros_avm_confidence_score : null)
        formik.setFieldValue('veros_avm_fsd_value', forms?.existing_property?.valuation_provider?.valuation_provider?.toLowerCase()?.includes('veros') && forms?.existing_property?.valuation_source?.valuation_source_id !== 1 ? forms?.existing_property?.veros_avm_fsd_value : forms?.existing_property?.valuation_provider?.valuation_provider !== "Veros" && forms?.existing_property?.valuation_source?.valuation_source_id !== 1 ? '' : forms?.existing_property?.valuation_provider?.valuation_provider === "Veros" && forms?.existing_property?.valuation_source?.valuation_source_id !== 1 ? forms?.existing_property?.veros_avm_fsd_value : null)

        formik.setFieldValue('propertyInfoValuationProvider', forms?.existing_property?.valuation_provider?.valuation_provider ? setDisplayProvider(forms?.existing_property?.valuation_provider?.valuation_provider) : null)
        formik.setFieldValue('propertyInfoEstimated', forms?.existing_property?.estimated_home_value ? forms.existing_property.estimated_home_value : null)
        formik.setFieldValue('propertyInfoMinValue', forms?.existing_property?.minimum_value ? forms.existing_property.minimum_value : '')
        formik.setFieldValue('propertyInfoMaxValue', forms?.existing_property?.maximum_value ? forms.existing_property.maximum_value : '')

        formik.setFieldValue('downpayment_percentage', forms?.calculated_offer?.downpayment_percentage ? forms.calculated_offer.downpayment_percentage : 0)
        formik.setFieldValue('months_of_piti', forms?.calculated_offer?.months_of_piti ? forms.calculated_offer.months_of_piti : 0)
        formik.setFieldValue('closing_cost_percentage', forms?.calculated_offer?.closing_cost_percentage ? forms.calculated_offer.closing_cost_percentage : 0)
        formik.setFieldValue('commision_percentage', forms?.calculated_offer?.commision_percentage ? forms.calculated_offer.commision_percentage : 0)
        formik.setFieldValue('ppg_percent', forms?.calculated_offer?.ppg_percent ? forms.calculated_offer.ppg_percent : 0)
        setGarageSpaces(forms?.veros_avm_property_data[0]?.garage_spaces);
        setMarketInsights(forms?.plunk_market_insights_data[0]);
        
        if (forms?.trade_in_application_id) {
            setAvmValuationManual(forms?.is_entry_type_manual)
            valuationProviderData = {
                valuationSource: forms?.existing_property?.valuation_source?.valuation_source_id,
                valuationHomeValue: forms?.existing_property?.estimated_home_value,
                valuationMinimumValue: forms?.existing_property?.minimum_value,
                valuationMaximumValue: forms?.existing_property?.maximum_value,
                valuationFSDValue: forms?.existing_property?.valuation_provider?.valuation_provider.toString().toLowerCase()?.includes('veros') ? forms?.existing_property?.veros_avm_fsd_value : null,
                valuationConfidenceScore: forms?.existing_property?.valuation_provider?.valuation_provider.toString().toLowerCase()?.includes('veros') ? forms?.existing_property?.veros_avm_confidence_score : null,
                valuationProvider: forms?.existing_property?.valuation_provider?.valuation_provider_id,
                displayProvider: forms?.existing_property?.valuation_provider?.valuation_provider
            }
            localStorage.setItem("valuationProviderData", JSON.stringify(valuationProviderData))
        }
    
    }

    const handleAVMRes = (forms, displayProvider, valuationProvider, avmValuationManual, setAvmValuationManual, borrowerFirstName, borrowerLastName, borrowerEmail, borrowerPhoneNumber) => {
        setGetAVMLoader(true)
        let avmValuesData = {
            "existing_property": {
                "street_address": forms.existing_property.street_address,
                "city": forms.existing_property.city,
                "state": forms.existing_property.state.state_id,
                "zipcode": forms.existing_property.zip_code.zip_code
            },
            "borrower": {
                "first_name": forms.borrowers[0].first_name,
                "last_name": forms.borrowers[0].last_name,
                "email": forms.borrowers[0].email,
                "phone_no": forms.borrowers[0].phone_no
            },
            "valuation_source": Number(formik.values.propertyInfoValuation),
            "valuation_provider": checkBoxValue,
            "trade_in_application_id":forms.trade_in_application_id,
        }
        let tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}get-avm-values/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(avmValuesData)
        })
            .then(async response => {
                const result = await response.json()
                localStorage.setItem('finalValPrevious', checkBoxValuePrevious)
                localStorage.setItem('finalVal', checkBoxValue)
                setAvmProviderRes(result.result)
                setGetAVMLoader(false)
                setValuationDetails(true)
                setAvmValuationManual(false)
                // setAddressAVMValidate([{
                //     street_address: formik.values.propertyInfoStreet,
                //     zip_code: zipCodeValue,
                //     city: formik.values.propertyInfoCity,
                //     county: formik.values.propertyInfoCounty,
                //     state: formik.values.propertyInfoState
                // }])
                let res = result && result.result;
                isAvmUpdatedValue = true;
                if (res.length === 1 && (res[0]['veros'] && res[0]['veros'] !== null)) {
                    formik.values.propertyInfoEstimated = res[0]['veros'].veros_avm_value
                    formik.values.propertyInfoMinValue = res[0]['veros'].veros_avm_minimum_value
                    formik.values.propertyInfoMaxValue = res[0]['veros'].veros_avm_maximum_value
                    formik.values.veros_avm_fsd_value = res[0]['veros'].veros_avm_fsd_value
                    formik.values.veros_avm_confidence_score = res[0]['veros'].veros_avm_confidence_score
                    formik.setFieldValue('propertyInfoEstimated', res[0]['veros'].veros_avm_value)
                    formik.setFieldValue('propertyInfoMinValue', res[0]['veros'].veros_avm_minimum_value)
                    formik.setFieldValue('propertyInfoMaxValue', res[0]['veros'].veros_avm_maximum_value)
                    formik.setFieldValue('veros_avm_fsd_value', res[0]['veros'].veros_avm_fsd_value)
                    formik.setFieldValue('veros_avm_confidence_score', res[0]['veros'].veros_avm_confidence_score)
                    setOpenAVMValuesPopup(!openAVMValuesPopup)
                    localStorage.setItem('displayProvider', 'Veros')
                    setDisplayProvider('Veros')
                    if (forms?.trade_in_application_id) {
                        handleEditValues(formik.values, forms, displayProvider, result.result, valuationProvider, avmValuationManual)
                    }
                    setDisplayPropertyError(false)
                } else if (res.length === 1 && (res[0]['plunk'] && res[0]['plunk'] !== null)) {
                    formik.values.propertyInfoEstimated = res[0]['plunk'].plunk_avm_value
                    formik.values.propertyInfoMinValue = res[0]['plunk'].plunk_avm_value - (res[0]['plunk'].plunk_avm_value) * 0.1;
                    formik.values.propertyInfoMaxValue = res[0]['plunk'].plunk_avm_value + (res[0]['plunk'].plunk_avm_value) * 0.1;
                    formik.values.veros_avm_fsd_value = ''
                    formik.values.veros_avm_confidence_score = ''
                    formik.setFieldValue('propertyInfoEstimated', res[0]['plunk'].plunk_avm_value)
                    formik.setFieldValue('propertyInfoMinValue', res[0]['plunk'].plunk_avm_value - (res[0]['plunk'].plunk_avm_value) * 0.1)
                    formik.setFieldValue('propertyInfoMaxValue', res[0]['plunk'].plunk_avm_value + (res[0]['plunk'].plunk_avm_value) * 0.1)
                    setOpenAVMValuesPopup(!openAVMValuesPopup)
                    localStorage.setItem('displayProvider', 'Plunk')
                    setDisplayProvider('Plunk')
                    if (forms?.trade_in_application_id) {
                        handleEditValues(formik.values, forms, displayProvider, result.result, valuationProvider, avmValuationManual)
                    }
                    setDisplayPropertyError(false)
                } else if (res.length === 1 && (res[0]['veros'] === null || res[0]['plunk'] === null)) {
                    if (forms?.trade_in_application_id) {
                        formik.values.propertyInfoValuation = forms?.existing_property?.valuation_source?.valuation_source_id
                        formik.values.propertyInfoEstimated = forms?.existing_property?.estimated_home_value
                        formik.values.propertyInfoMinValue = forms?.existing_property?.minimum_value === null ? '':forms?.existing_property?.minimum_value;
                        formik.values.propertyInfoMaxValue = forms?.existing_property?.maximum_value === null ? '' : forms?.existing_property?.maximum_value;
                        formik.values.veros_avm_fsd_value = forms?.existing_property?.valuation_provider?.valuation_provider == 'Veros' ? forms?.existing_property?.veros_avm_fsd_value : ''
                        formik.values.veros_avm_confidence_score = forms?.existing_property?.valuation_provider?.valuation_provider == 'Veros' ? forms?.existing_property?.veros_avm_confidence_score : ''
                        setDisplayProvider(forms?.existing_property?.valuation_source?.valuation_source_id == 2 ? forms?.existing_property?.valuation_provider?.valuation_provider : '')
                    } else {
                        formik.values.propertyInfoValuation = ''
                        formik.values.propertyInfoEstimated = ''
                        formik.values.propertyInfoMinValue = ''
                        formik.values.propertyInfoMaxValue = ''
                        formik.values.veros_avm_fsd_value = ''
                        formik.values.veros_avm_confidence_score = ''
                        setDisplayProvider("")
                    }
                    setDisplayProvider(forms?.existing_property?.valuation_provider?.valuation_provider)
                    setDetail('AVM details are not available for the specified address');
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    await sleep(3000)
                    setOpenAVMValuesPopup(!openAVMValuesPopup)
                    setOpenAVMValuationSourcePopup(!openAVMValuesPopup)
                    setDisplayPropertyError(false)
                } else if (res[0]['veros'] === null && res[1]['plunk'] === null) {
                    if (forms?.trade_in_application_id) {
                        formik.values.propertyInfoValuation = forms?.existing_property?.valuation_source?.valuation_source_id
                        formik.values.propertyInfoEstimated = forms?.existing_property?.estimated_home_value
                        formik.values.propertyInfoMinValue = forms?.existing_property?.minimum_value
                        formik.values.propertyInfoMaxValue = forms?.existing_property?.maximum_value
                        formik.values.veros_avm_fsd_value = forms?.existing_property?.valuation_provider?.valuation_provider == 'Veros' ? forms?.existing_property?.veros_avm_fsd_value : ''
                        formik.values.veros_avm_confidence_score = forms?.existing_property?.valuation_provider?.valuation_provider == 'Veros' ? forms?.existing_property?.veros_avm_confidence_score : ''
                    } else {
                        formik.values.propertyInfoValuation = ''
                        formik.values.propertyInfoEstimated = ''
                        formik.values.propertyInfoMinValue = ''
                        formik.values.propertyInfoMaxValue = ''
                        formik.values.veros_avm_fsd_value = ''
                        formik.values.veros_avm_confidence_score = ''
                    }
                    setDisplayProvider(forms?.existing_property?.valuation_provider?.valuation_provider)
                    setDetail('AVM details are not available for the specified address');
                    setOpenAVMValuationSourcePopup(!openAVMValuesPopup)
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    setOpenAVMValuesPopup(!openAVMValuesPopup)
                    setDisplayPropertyError(false)
                } else if (res?.[0]?.['veros']?.veros_avm_value < res?.[1]?.['plunk']?.plunk_avm_value) {
                    formik.values.propertyInfoEstimated = res[0]['veros'].veros_avm_value
                    formik.values.propertyInfoMinValue = res[0]['veros'].veros_avm_minimum_value
                    formik.values.propertyInfoMaxValue = res[0]['veros'].veros_avm_maximum_value
                    formik.values.veros_avm_fsd_value = res[0]['veros'].veros_avm_fsd_value
                    formik.values.veros_avm_confidence_score = res[0]['veros'].veros_avm_confidence_score
                    formik.setFieldValue('propertyInfoEstimated', res[0]['veros'].veros_avm_value)
                    formik.setFieldValue('propertyInfoMinValue', res[0]['veros'].veros_avm_minimum_value)
                    formik.setFieldValue('propertyInfoMaxValue', res[0]['veros'].veros_avm_maximum_value)
                    formik.setFieldValue('veros_avm_fsd_value', res[0]['veros'].veros_avm_fsd_value)
                    formik.setFieldValue('veros_avm_confidence_score', res[0]['veros'].veros_avm_confidence_score)
                    formik.setFieldValue('propertyInfoValuationProvider', 'Veros')
                    setOpenAVMValuesPopup(!openAVMValuesPopup)
                    localStorage.setItem('displayProvider', 'Veros')
                    setDisplayProvider('Veros')
                    setDisplayPropertyError(false)
                    if (forms?.trade_in_application_id) {
                        handleEditValues(formik.values, forms, displayProvider, result.result, valuationProvider, avmValuationManual)
                    }
                } else if (res?.[0]?.['veros']?.veros_avm_value > res?.[1]?.['plunk']?.plunk_avm_value) {
                    formik.values.propertyInfoEstimated = res[1]['plunk'].plunk_avm_value
                    formik.values.propertyInfoMinValue = res[1]['plunk'].plunk_avm_value - (res[1]['plunk'].plunk_avm_value) * 0.1
                    formik.values.propertyInfoMaxValue = res[1]['plunk'].plunk_avm_value + (res[1]['plunk'].plunk_avm_value) * 0.1
                    formik.values.veros_avm_fsd_value = ''
                    formik.values.veros_avm_confidence_score = ''
                    formik.setFieldValue('propertyInfoEstimated', res[1]['plunk'].plunk_avm_value)
                    formik.setFieldValue('propertyInfoMinValue', res[1]['plunk'].plunk_avm_value - (res[1]['plunk'].plunk_avm_value) * 0.1)
                    formik.setFieldValue('propertyInfoMaxValue', res[1]['plunk'].plunk_avm_value + (res[1]['plunk'].plunk_avm_value) * 0.1)
                    formik.setFieldValue('veros_avm_fsd_value', '')
                    formik.setFieldValue('veros_avm_confidence_score', '')
                    formik.setFieldValue('propertyInfoValuationProvider', 'Plunk')
                    setOpenAVMValuesPopup(!openAVMValuesPopup)
                    localStorage.setItem('displayProvider', 'Plunk')
                    setDisplayProvider('Plunk')
                    setDisplayPropertyError(false)
                    if (forms?.trade_in_application_id) {
                        handleEditValues(formik.values, forms, displayProvider, result.result, valuationProvider, avmValuationManual)
                    }
                } else if (res?.[0]?.['veros'] === null && res?.[1]?.['plunk'] !== null) {
                    formik.values.propertyInfoEstimated = res[1]['plunk'].plunk_avm_value
                    formik.values.propertyInfoMinValue = res[1]['plunk'].plunk_avm_value - (res[1]['plunk'].plunk_avm_value) * 0.1;
                    formik.values.propertyInfoMaxValue = res[1]['plunk'].plunk_avm_value + (res[1]['plunk'].plunk_avm_value) * 0.1;
                    formik.setFieldValue('propertyInfoEstimated', res[1]['plunk'].plunk_avm_value)
                    formik.setFieldValue('propertyInfoMinValue', res[1]['plunk'].plunk_avm_value - (res[1]['plunk'].plunk_avm_value) * 0.1)
                    formik.setFieldValue('propertyInfoMaxValue', res[1]['plunk'].plunk_avm_value + (res[1]['plunk'].plunk_avm_value) * 0.1)
                    formik.values.veros_avm_fsd_value = ''
                    formik.values.veros_avm_confidence_score = ''
                    formik.setFieldValue('propertyInfoValuationProvider', 'Plunk')
                    setOpenAVMValuesPopup(!openAVMValuesPopup)
                    setDisplayProvider('Plunk')
                    localStorage.setItem('displayProvider', 'Plunk')
                    setDisplayPropertyError(false)
                    if (forms?.trade_in_application_id) {
                        handleEditValues(formik.values, forms, displayProvider, result.result, valuationProvider, avmValuationManual)
                    }
                }
                else if (res?.[0]?.['veros'] !== null && res?.[1]?.['plunk'] === null) {
                    formik.values.propertyInfoEstimated = res[0]['veros'].veros_avm_value
                    formik.values.propertyInfoMinValue = res[0]['veros'].veros_avm_minimum_value
                    formik.values.propertyInfoMaxValue = res[0]['veros'].veros_avm_maximum_value
                    formik.values.veros_avm_fsd_value = res[0]['veros'].veros_avm_fsd_value
                    formik.values.veros_avm_confidence_score = res[0]['veros'].veros_avm_confidence_score
                    formik.setFieldValue('propertyInfoEstimated', res[0]['veros'].veros_avm_value)
                    formik.setFieldValue('propertyInfoMinValue', res[0]['veros'].veros_avm_minimum_value)
                    formik.setFieldValue('propertyInfoMaxValue', res[0]['veros'].veros_avm_maximum_value)
                    formik.setFieldValue('veros_avm_fsd_value', res[0]['veros'].veros_avm_fsd_value)
                    formik.setFieldValue('veros_avm_confidence_score', res[0]['veros'].veros_avm_confidence_score)
                    formik.setFieldValue('propertyInfoValuationProvider', 'Veros')
                    setOpenAVMValuesPopup(!openAVMValuesPopup)
                    setDisplayProvider('Veros')
                    localStorage.setItem('displayProvider', 'Veros')
                    setDisplayPropertyError(false)
                    if (forms?.trade_in_application_id) {
                        handleEditValues(formik.values, forms, displayProvider, result.result, valuationProvider, avmValuationManual)
                    }
                }
            })
            .catch((error) => {
                setGetAVMLoader(false)
                setOpenAVMValuesPopup(!openAVMValuesPopup)
                console.error(error)
            })
    }

    const handleDownpayment=()=>{
        let downPaymentVal = formik.values?.downpayment_percentage?.toString().replace(/%/g, '')
        let closingCostVal = formik.values?.closing_cost_percentage?.toString().replace(/%/g, '')
        let commisionPerVal = formik.values?.commision_percentage?.toString().replace(/%/g, '')
        let ppgPerVal = formik.values?.ppg_percent?.toString().replace(/%/g, '')
        if(downPaymentVal === undefined || downPaymentVal === ""){
            formik.setFieldValue('downpayment_percentage',0)
        } 
        if(formik?.values?.months_of_piti === undefined || formik?.values?.months_of_piti === ""){
            formik.setFieldValue('months_of_piti',0)
        }  
        if(closingCostVal === undefined || closingCostVal === ""){
            formik.setFieldValue('closing_cost_percentage',0)
        }
        if(commisionPerVal === undefined || commisionPerVal === ""){
            formik.setFieldValue('commision_percentage',0)
        } 
        if(ppgPerVal === undefined || ppgPerVal === ""){
            formik.setFieldValue('ppg_percent',0)
        }
        let tokenStr = localStorage.getItem('access_token')
        let headers = {'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json' }
        let body = {
            "downpayment": Number(downPaymentVal),
            "months_of_piti": Number(formik?.values?.months_of_piti),
            "closing_cost_percentage": Number(closingCostVal),
            "commision_percentage": Number(commisionPerVal),
            "ppg_percent": Number(ppgPerVal)
        }
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/get-calculated-offer-values/`,
            {
                method:"POST",
                headers,
                body:JSON.stringify(body)
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setRecalculateAmt(result);
                    setRecalculuteStatus(true);
                    if(downPaymentVal) {
                        formik.setFieldValue('downpayment_percentage', result.downpayment_percentage)
                    }
                    if(formik?.values?.months_of_piti) {
                        formik.setFieldValue('months_of_piti', result.months_of_piti)
                    }
                    if(closingCostVal) {
                        formik.setFieldValue('closing_cost_percentage', result.closing_cost_percentage)
                    }
                    if(commisionPerVal) {
                        formik.setFieldValue('commision_percentage', result.commision_percentage)
                    }
                    if(ppgPerVal) {
                        formik.setFieldValue('ppg_percent', result.ppg_percent)
                    }
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
        })
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleEditValues = (values, forms, displayProvider, avmProviderRes) => {
        setDisplayPropertyError(false)
        // setDisplayAddLo(false)
        // setDisplayAddRSA(false)
        let provider = valuationProvider && valuationProvider?.valuation_provider?.filter(provider => localStorage.getItem('displayProvider') == provider.valuation_provider)
        let tokenStr = localStorage.getItem('access_token')
        let body = {
            existing_property: {
                street_address: forms.existing_property.street_address,
                city: forms.existing_property.city,
                state: forms.existing_property.state.state_id,
                county: forms.existing_property.county.county_id,
                zip_code: forms.existing_property.zip_code.zip_code_id,
                estimated_home_value: values.propertyInfoEstimated === null ? null : values?.propertyInfoEstimated?.toString().replace(/,/g, ''),
                valuation_source: Number(values.propertyInfoValuation),
                property_type: forms.existing_property.property_type.property_type_id,
                owner_estimated_home_value: parseFloat(forms?.existing_property?.owner_estimated_home_value?.replace(/,/g, '')),
                home_condition:forms.existing_property?.home_condition?.home_condition_id,
                house_built_year:forms.existing_property.house_built_year,
                condo_unit_number:forms.existing_property.condo_unit_number,
                condo_association_name:forms.existing_property.condo_association_name,
                property_in_sqft:parseFloat(forms?.existing_property?.property_in_sqft?.replace(/,/g, '')),
                tax_escrow_flag:forms.existing_property.tax_escrow_flag,
                insurance_escrow_flag:forms.existing_property.insurance_escrow_flag,
                reason_for_sale: forms.existing_property.reason_for_sale,
                defects:forms.existing_property.defects,
                alterations_and_problems:forms.existing_property.alterations_and_problems,
                ready_for_selling: forms.existing_property.ready_for_selling,
                new_home_purchase_price: parseFloat(forms?.existing_property?.new_home_purchase_price?.replace(/,/g, '')),
                how_did_you_hear_about_us:forms.existing_property.how_did_you_hear_about_us,
                owner_occupied: forms.existing_property.owner_occupied,
                does_home_have_a_septic_system: forms?.existing_property?.does_home_have_a_septic_system,
                purchase_date:forms.existing_property.purchase_date,
                purchase_price:parseFloat(forms?.existing_property?.purchase_price?.replace(/,/g, '')),
                takeout_offer:parseFloat(forms?.existing_property?.takeout_offer?.replace(/,/g, '')),
                takeout_offer_percentage:forms.existing_property.takeout_offer_percentage,
                valuation_provider: (provider != undefined || provider != null) && provider[0]?.valuation_provider_id != null ? provider[0].valuation_provider_id : forms?.existing_property?.valuation_provider?.valuation_provider == displayProvider ? forms?.existing_property?.valuation_provider?.valuation_provider_id : null,

                veros_avm_minimum_value: values.propertyInfoValuation == 2 ? ((avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_minimum_value !== null) ? forms?.existing_property?.veros_avm_minimum_value :
                    (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined) ? avmProviderRes?.[0]?.['veros']?.veros_avm_minimum_value : null) : null,
                veros_avm_maximum_value: values.propertyInfoValuation == 2 ? ((avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_maximum_value !== null) ? forms?.existing_property?.veros_avm_maximum_value :
                    (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined) ? avmProviderRes?.[0]?.['veros']?.veros_avm_maximum_value : null) : null,

                veros_avm_value: values.propertyInfoValuation == 2 ? ((avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_value !== null) ? forms?.existing_property?.veros_avm_value :
                    (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined) ? avmProviderRes?.[0]?.['veros']?.veros_avm_value : null) : null,

                plunk_avm_value: values.propertyInfoValuation == 2 ? ((avmProviderRes.length === 0 && forms?.existing_property?.plunk_avm_value !== null) ? forms?.existing_property?.plunk_avm_value :
                    (avmProviderRes.length === 1 && avmProviderRes?.[0]?.['plunk'] !== null && avmProviderRes?.[0]?.['plunk'] !== undefined) ? avmProviderRes?.[0]?.['plunk']?.plunk_avm_value :
                        (avmProviderRes.length === 2 && avmProviderRes?.[1]?.['plunk'] !== null && avmProviderRes?.[1]?.['plunk'] !== undefined) ? avmProviderRes?.[1]?.['plunk']?.plunk_avm_value : null) : null,

                veros_avm_confidence_score: values.propertyInfoValuation == 2 ? ((avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_confidence_score !== null) ? forms?.existing_property?.veros_avm_confidence_score :
                    (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined) ? avmProviderRes?.[0]?.['veros']?.veros_avm_confidence_score : null) : null,

                veros_avm_fsd_value: values.propertyInfoValuation == 2 ? ((avmProviderRes.length === 0 && forms?.existing_property?.veros_avm_fsd_value !== null) ? forms?.existing_property?.veros_avm_fsd_value :
                    (avmProviderRes.length > 0 && avmProviderRes?.[0]?.['veros'] !== null && avmProviderRes?.[0]?.['veros'] !== undefined) ? avmProviderRes?.[0]?.['veros']?.veros_avm_fsd_value : null) : null,
                minimum_value: (values.propertyInfoMinValue === null || values.propertyInfoMinValue === "") ? null : parseFloat(values.propertyInfoMinValue.toString().replace(/,/g, '')).toFixed(2),
                maximum_value: (values.propertyInfoMaxValue === null || values.propertyInfoMaxValue === "") ? null : parseFloat(values.propertyInfoMaxValue.toString().replace(/,/g, '')).toFixed(2),
            },
            existing_mortgage:{
                mort1_payoff_amount:forms?.existing_mortgage?.mort1_payoff_amount,
                mort1_curr_piti_payment:forms?.existing_mortgage?.mort1_curr_piti_payment,
                mort2_type:forms?.existing_mortgage?.mort2_type?.mortgage_type_id,
                mort2_payoff_amount:forms?.existing_mortgage?.mort2_payoff_amount,
                mort2_curr_piti_payment:forms?.existing_mortgage?.mort2_curr_piti_payment,
                about_other_loans_or_liens:forms?.existing_mortgage?.about_other_loans_or_liens,
                describe_fees:forms?.existing_mortgage?.describe_fees,
                monthly_amount:forms?.existing_mortgage?.monthly_amount,
            },
            borrower:{
                first_name:forms.borrowers[0]?.first_name,
                last_name:forms.borrowers[0]?.last_name,
                email:forms.borrowers[0]?.email,
                phone_no:forms.borrowers[0]?.phone_no,
                extension:forms.borrowers[0]?.extension,
            },
            co_borrower:{
                first_name:forms.borrowers[1]?.first_name,
                last_name:forms.borrowers[1]?.last_name,
                email:forms.borrowers[1]?.email,
                phone_no:forms.borrowers[1]?.phone_no,
                extension:forms.borrowers[1]?.extension,
            },
            is_avm_updated: isAvmUpdatedValue == true ? true : false,
            is_entry_type_manual: avmValuationManual,
        }

        let url = '', method = '';

        if (forms?.trade_in_application_id) {
            url = `${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/`
            method = 'PATCH'
        }

        fetch(url, {
            method,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setDetail("Data has been recorded");
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    // setCheckedRows({});
                    setGarageSpaces(results.trade_in_application?.veros_avm_property_data[0]?.garage_spaces)
                    setMarketInsights(results.trade_in_application?.plunk_market_insights_data[0]);
                    isAvmUpdatedValue = false
                    setTimeout(() => {
                        setOpenSnackbar(false);
                    }, 2000)
                    setForms(results.trade_in_application)
                    if (forms?.trade_in_application_id) {
                        valuationProviderData = {
                            valuationSource: results.trade_in_application?.existing_property?.valuation_source?.valuation_source_id,
                            valuationHomeValue: results.trade_in_application?.existing_property?.estimated_home_value,
                            valuationMinimumValue: results.trade_in_application?.existing_property?.minimum_value,
                            valuationMaximumValue: results.trade_in_application?.existing_property?.maximum_value,
                            valuationFSDValue: results.trade_in_application?.existing_property?.valuation_provider?.valuation_provider.toString().toLowerCase()?.includes('veros') ? forms?.existing_property?.veros_avm_fsd_value : null,
                            valuationConfidenceScore: results.trade_in_application?.existing_property?.valuation_provider?.valuation_provider.toString().toLowerCase()?.includes('veros') ? forms?.existing_property?.veros_avm_confidence_score : null,
                            valuationProvider: results.trade_in_application?.existing_property?.valuation_provider?.valuation_provider_id,
                            displayProvider: results.trade_in_application?.existing_property?.valuation_provider?.valuation_provider
                        }
                        localStorage.setItem("valuationProviderData", JSON.stringify(valuationProviderData))
                    }
                }
                else {
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                    }
                    else {
                        setDetail("something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000);
                        setOpenSnackbar(false);
                    }
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }

    const handleValuationDetailsSave = () => {
        setDisplayPropertyError(true)
        let downPaymentVal = formik.values?.downpayment_percentage?.toString().replace(/%/g, '')
        if(downPaymentVal === undefined || downPaymentVal === ""){
            formik.setFieldValue('downpayment_percentage',0)
        }
    }

    useEffect(() => {
        if (formik.values.veros_avm_fsd_value) {
            if (formik.values.veros_avm_fsd_value > 1) {
                formik.setFieldValue('veros_avm_fsd_value', formik.values.veros_avm_fsd_value / 10)
            }
        }
    }, [formik.values.veros_avm_fsd_value])

    useEffect(() => {
        if (formik.values.propertyInfoValuation == "1" || formik.values.propertyInfoValuation === "") {
            formik.values.propertyInfoValuationProvider = '';
        }
    }, [formik.values.propertyInfoValuation])
    useEffect(() => {
        if (addressAVMValidate.length > 0) {
            if (formik.values.propertyInfoStreet !== addressAVMValidate?.[0]?.street_address || formik.values.propertyInfoCity !== addressAVMValidate?.[0]?.city || formik.values.propertyInfoCounty !== addressAVMValidate?.[0]?.county || formik.values.propertyInfoState !== addressAVMValidate?.[0]?.state) {
                localStorage.setItem("valuationProviderData", JSON.stringify({}))
                formik.values.propertyInfoValuation = ''
                formik.values.propertyInfoEstimated = ''
                formik.values.propertyInfoMinValue = ''
                formik.values.propertyInfoMaxValue = ''
                formik.values.veros_avm_fsd_value = ''
                formik.values.veros_avm_confidence_score = ''

                formik.setFieldValue('propertyInfoValuation', '')
                formik.setFieldValue('propertyInfoEstimated', '')
                formik.setFieldValue('propertyInfoMinValue', '')
                formik.setFieldValue('propertyInfoMaxValue', '')
                formik.setFieldValue('veros_avm_fsd_value', '')
                formik.setFieldValue('veros_avm_confidence_score', '')

                if (forms?.trade_in_application_id !== undefined) {
                    forms.existing_property.valuation_source = null
                    forms.existing_property.estimated_home_value = ''
                    forms.existing_property.minimum_value = ''
                    forms.existing_property.maximum_value = ''
                    forms.existing_property.veros_avm_fsd_value = ''
                    forms.existing_property.veros_avm_confidence_score = ''
                    forms.existing_property.valuation_provider = null
                }
                setDisplayProvider([])
            }
        }
    }, [addressAVMValidate])
    useEffect(() => {
        if (forms?.existing_property?.plunk_avm_value !== '' && forms?.existing_property?.veros_avm_value !== '' && localStorage.getItem('finalVal') != '1' && localStorage.getItem('finalVal') != '2') {
            localStorage.setItem('finalVal', '1,2')
        }
    }, [forms])

    const handleAVMValuationSourcePopup = () => {
        setCheckBoxValue([])
        setOpenAVMValuationSourcePopup(!openAVMValuationSourcePopup)
    }

    const handleAVMValuationType = () => {
        setAvmValuationType(!avmValuationType)
        if (avmValuationType === true) {
            formik.setFieldValue('propertyInfoValuation', forms?.existing_property?.valuation_source?.valuation_source_id)
        }
    }

    const handleAVMValuesPopup = () => {
        setAvmValuationType(false)
        let dataAll = localStorage.getItem("valuationProviderData")
        let data = JSON.parse(dataAll !== undefined ? dataAll : {})
        setDisplayPropertyError(false)
        if (openAVMValuesPopup === true && Object.keys(data).length !== 0) {
            formik.values.propertyInfoValuation = data.valuationSource
            formik.values.propertyInfoEstimated = data.valuationHomeValue
            formik.values.propertyInfoMinValue = data.valuationMinimumValue
            formik.values.propertyInfoMaxValue = data.valuationMaximumValue
            formik.values.veros_avm_fsd_value = data?.displayProvider?.toString().toLowerCase().includes('veros') ? data.valuationFSDValue : null
            formik.values.veros_avm_confidence_score = data?.displayProvider?.toString().toLowerCase().includes('veros') ? data.valuationConfidenceScore : null
            formik.values.propertyInfoValuationProvider = data.valuationProvider
            setDisplayProvider(data?.displayProvider)
            setOpenAVMValuesPopup(false)
        } else {
            formik.values.propertyInfoValuation = ""
            formik.values.propertyInfoEstimated = ""
            formik.values.propertyInfoMinValue = ""
            formik.values.propertyInfoMaxValue = ""
            formik.values.veros_avm_fsd_value = ""
            formik.values.veros_avm_confidence_score = ""
            formik.values.propertyInfoValuationProvider = ""
            formik.setFieldValue('veros_avm_confidence_score', "")
            formik.setFieldValue('veros_avm_fsd_value', "")
            formik.setFieldValue('propertyInfoValuationProvider', "")
            formik.setFieldValue('propertyInfoEstimated', "")
            formik.setFieldValue('propertyInfoMinValue', "")
            formik.setFieldValue('propertyInfoMaxValue', "")
            setDisplayPropertyError(false)
            setDisplayProvider("")
            setOpenAVMValuesPopup(!openAVMValuesPopup)
        }
    }

    const handleValuationSourceValue = () => {
        let source = validationSource && validationSource.find((data) => data.valuation_source_id == formik.values.propertyInfoValuation)
        if (source) {
            return source && source.valuation_source;
        } else {
            return forms?.existing_property?.valuation_source?.valuation_source;
        }
    }

    const handleValuationSource = () => {
        formik.setFieldValue('propertyInfoValuationProvider', '')
        if (formik.values.propertyInfoValuation !== "") {
            setValuationDetails(true)
        }
        if (formik.values.propertyInfoValuation != "2" || formik.values.propertyInfoValuation == 2) {
            formik.setFieldValue('propertyInfoEstimated', '')
            formik.setFieldValue('propertyInfoMinValue', '')
            formik.setFieldValue('propertyInfoMaxValue', '')
            formik.setFieldValue('veros_avm_fsd_value', '')
            formik.setFieldValue('veros_avm_confidence_score', '')
            formik.setFieldValue('propertyInfoValuationProvider', '')
            setDisplayProvider('')
        }
    }

    const handleAVMValuationTypeValue = (val) => {
        setOpenAVMValuationSourcePopup(false)
        setAvmValuationTypeValue(val)
        setAvmValuationType(false)
        if (val === "true") {
            setAvmValuationManual(false)
            let result = validationSource && validationSource.find((res) => formik.values.propertyInfoValuation == res.valuation_source_id)
            if (result.valuation_provider.length !== 0) {
                setOpenAVMValuesPopup(true)
                setValuationProvider(result)
            } else {
                setOpenAVMValuesPopup(false)
                setValuationProvider([])
            }
            if (formik.values.propertyInfoValuation == 2) {
                formik.setFieldValue('propertyInfoEstimated', '')
                formik.setFieldValue('propertyInfoMinValue', '')
                formik.setFieldValue('propertyInfoMaxValue', '')
                formik.setFieldValue('veros_avm_fsd_value', '')
                formik.setFieldValue('veros_avm_confidence_score', '')
                formik.setFieldValue('propertyInfoValuationProvider', '')
                setDisplayProvider('')
            } 
            else {
                if (formik.values.propertyInfoValuation) {
                    if (formik.values.propertyInfoValuation == forms?.existing_property?.valuation_source?.valuation_source_id) {
                        formik.setFieldValue('propertyInfoEstimated', forms?.existing_property?.estimated_home_value)
                        formik.setFieldValue('propertyInfoMinValue', forms?.existing_property?.minimum_value)
                        formik.setFieldValue('propertyInfoMaxValue', forms?.existing_property?.maximum_value)
                        formik.setFieldValue('veros_avm_fsd_value', forms?.existing_property?.valuation_provider?.valuation_provider === 'Veros' ? forms?.existing_property?.veros_avm_fsd_value : '')
                        formik.setFieldValue('veros_avm_confidence_score', forms?.existing_property?.valuation_provider?.valuation_provider === 'Veros' ? forms?.existing_property?.veros_avm_confidence_score : '')
                        formik.setFieldValue('propertyInfoValuationProvider', forms?.existing_property?.valuation_provider && forms?.existing_property?.valuation_source?.valuation_source === 'AVM' ? forms?.existing_property?.valuation_provider?.valuation_provider_id : '')
                        setDisplayProvider(forms?.existing_property?.valuation_provider !== null ? forms?.existing_property?.valuation_provider?.valuation_provider : '')
                    } else {
                        formik.setFieldValue('propertyInfoEstimated', '')
                        formik.setFieldValue('propertyInfoMinValue', '')
                        formik.setFieldValue('propertyInfoMaxValue', '')
                        formik.setFieldValue('veros_avm_fsd_value', '')
                        formik.setFieldValue('veros_avm_confidence_score', '')
                        formik.setFieldValue('propertyInfoValuationProvider', '')
                        setDisplayProvider('')
                    }

                }
            }
            setValuationDetails(true)
            setOpenAVMValuationSourcePopup(false)
        } else if (val === "false") {
            setAvmValuationManual(true)
            let result = validationSource && validationSource.find((res) => formik.values.propertyInfoValuation == res.valuation_source_id)
            if (result.valuation_provider.length !== 0) {
                setOpenAVMValuesPopup(true)
                setValuationProvider(result)
            } else {
                setOpenAVMValuesPopup(false)
                setValuationProvider([])
            }
        }
    }

    const handlePlunkMarketInsights = () => {
        setPlunkMarketInsights(!plunkMarketInsights);
    }

    function handleGetAVMValuationSource(validationSource, formik, setOpenAVMValuationSourcePopup, setValuationDetails) {
        const handleSelectedValue = (eve, validationSource) => {
            setOpenAVMValuationSourcePopup(!openAVMValuationSourcePopup)
            let result = validationSource.filter((res) => {
                if (res.valuation_provider.length === 0) {
                    return res.valuation_provider
                }
            })
            let check = result.filter((res) => { if (res.valuation_source_id == eve) { return res.valuation_provider } })
            if (eve) {
                setValuationDetails(true)
            }
            if (check && check?.[0]?.valuation_provider.length !== 0) {
                setAvmValuationType(true)
                setAvmValuationManual(false)
            } else if (check && check.length !== 0 && check?.[0]?.valuation_provider.length === 0) {
                formik.setFieldValue('propertyInfoEstimated', '')
                formik.setFieldValue('propertyInfoMinValue', '')
                formik.setFieldValue('propertyInfoMaxValue', '')
                formik.setFieldValue('veros_avm_fsd_value', '')
                formik.setFieldValue('veros_avm_confidence_score', '')
                setDisplayProvider('')
                setAvmValuationType(false)
                setAvmValuationManual(true)
            }
        }

        function StyledRadio(props) {
            const useStyles = makeStyles({
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }
            })
            const classes = useStyles();
            return (
                <Radio
                    className={classes.root}
                    disableripple="true"
                    color="default"
                    checkedIcon={<CheckCircleIcon className="checkedIcon" />}
                    icon={<RadioButtonUncheckedIcon />}
                    {...props}
                />
            );
        }
        return (
            <div>
                <div className='pt-2'>
                    <RadioGroup name='propertyInfoValuation' value={formik.values.propertyInfoValuation} onChangeCapture={formik.handleChange} onChange={(e) => handleSelectedValue(e.target.value, validationSource)}>
                        {validationSource && validationSource.map((source, index) => {
                            return (
                                <div key={index}>
                                    <FormControlLabel
                                        key={source.valuation_source_id}
                                        label={source.valuation_source}
                                        control={<StyledRadio />}
                                        value={source.valuation_source_id.toString()}
                                        checked={false}
                                    />
                                </div>
                            )
                        })}
                    </RadioGroup>
                </div>
            </div>
        )
    }

    function handleGetAvmValuationType(valSource) {
        return (
            <div>
                <RadioGroup
                    aria-label="Avm Valuation Type"
                    name="avm_type"
                    className=""
                    value={avmValuationTypeValue}
                    onChange={(e) => handleAVMValuationTypeValue(e.target.value)}
                >
                    <div className="w-100">
                        <div>
                            <FormControlLabel disableripple className="mr-10 w-100" value="true" control={<Radio disableripple color='primary' />} label='Order AVM' labelPlacement="end" checked={false} disabled={valSource != "2"} />
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                            <FormControlLabel disableripple className="mr-10 w-100" value="false" control={<Radio disableripple color='primary' />} label='Enter Values Manually' labelPlacement="end" checked={false} />
                        </div>
                    </div>
                </RadioGroup>
            </div>
        )
    }

    function handleGetAVMValues(props, data, setCheckBoxValue, displayProvider, setDisplayProvider, valuationProvider, avmValuationTypeValue, propertySource, setAvmValuationManual) {
        let finalVal = [];
        const handleCheckBox = (e) => {
            let id = document.getElementsByClassName('checkbox')
            for (let i = 0; i < id.length; i++) {
                if (id[i].checked === true && !finalVal.includes(id[i].value)) {
                    finalVal.push(Number(id[i].value))
                } else if (id[i].checked === false) {
                    finalVal.slice(id[i])
                }
            }
            setCheckBoxValuePrevious(localStorage.getItem('finalVal'))
            setCheckBoxValue(finalVal)
        }

        const handleSelectedProviderValue = (eve) => {
            data && data?.valuation_provider?.map((res) => {
                if (eve == res?.valuation_provider_id) {
                    setDisplayProvider(res.valuation_provider)
                }
            })
            if (avmValuationType == false && (displayProvider != '' || displayProvider.length == 0)) {
                formik.setFieldValue('veros_avm_confidence_score', "")
                formik.setFieldValue('veros_avm_fsd_value', "")
                formik.setFieldValue('propertyInfoEstimated', "")
                formik.setFieldValue('propertyInfoMinValue', "")
                formik.setFieldValue('propertyInfoMaxValue', "")
            }
            setOpenAVMValuesPopup(!openAVMValuesPopup)
        }
        function StyledRadio(props) {
            const useStyles = makeStyles({
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }
            })
            const classes = useStyles();
            return (
                <Radio
                    className={classes.root}
                    disableripple="true"
                    color="default"
                    checkedIcon={<CheckCircleIcon className="checkedIcon" />}
                    icon={<RadioButtonUncheckedIcon />}
                    {...props}
                />
            );
        }
        return (
            <div>
                {
                    data && data.valuation_source_id != 1 ? avmValuationTypeValue === "true" ?
                        <div>
                            {
                                data && data?.valuation_provider?.map((res) => {
                                    return (
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <input type="checkbox" className="checkbox" onClick={(e) => handleCheckBox(e.target.value)} value={res.valuation_provider_id} name={res.valuation_provider} style={{ height: '25px', width: '12px' }}
                                                    disabled={propertySource == 2 && (res.valuation_provider == "Veros" || res.valuation_provider == "Plunk") ? false : true}
                                                />
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div>
                                                <h4 style={propertySource == 2 && (res.valuation_provider == "Veros" || res.valuation_provider == "Plunk") ? { marginLeft: '0px' } : { marginLeft: '0px', color: 'lightgray' }}>{res.valuation_provider}</h4>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div>
                                <Grid container spacing={2} className="form_layout_btn" style={{ justifyContent: 'center' }}>
                                    <Button className="primary_btn" variant="contained" color="primary"
                                     onClick={(e) => { handleAVMRes(forms, displayProvider, valuationProvider, avmValuationManual, setAvmValuationManual) }} 
                                     disabled={getAVMLoader === true || checkBoxValue.length === 0}
                                     >
                                        {getAVMLoader === false ? "Confirm" : <CircularProgress size={18} color="white" className="circularProgess" />}
                                        </Button>
                                    <Button className="primary_btn_border" variant="outlined" color="primary" onClick={handleAVMValuesPopup} style={{ background: '#fff' }}>
                                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create" onClick={props && props.handleChange}>
                                            Cancel
                                        </NavLink>
                                    </Button>
                                </Grid>
                            </div>
                        </div>
                        :
                        <div>
                            <RadioGroup
                                onChangeCapture={formik.handleChange}
                                onChange={(e) => handleSelectedProviderValue(e.target.value)}
                                style={{ minWidth: '250px' }}
                            >
                                {data && data?.valuation_provider?.map((source, index) => {
                                    return (
                                        <div key={index}>
                                            <FormControlLabel
                                                key={source.valuation_provider_id}
                                                label={source.valuation_provider}
                                                control={<StyledRadio />}
                                                value={source.valuation_provider_id.toString()}
                                                checked={false}
                                                id="valuation_radio_label"
                                            />
                                        </div>
                                    )
                                })}
                            </RadioGroup>
                        </div> : null
                }
            </div>
        )
    }

    function handleOfferApi() {
        var tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/get-offer-matrix/`,
            {
                headers: {
                    'Authorization': `Bearer ${tokenStr}`
                }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {  
                    handleClickOpens()
                    let res = result;
                    setOfferRes(res)
                    let terms = []
                    let tempTermData = []
                    if (res?.message) {
                    } else {
                        res.map((item, index) => {
                            let term_data = item.term_data;
                            tempTermData.push({ period: item.term_days, data: term_data })
                            terms.push({ period: item.term_days, data: term_data })
                        })
                    }
                    setOfferMatrixObj(tempTermData)
                    setLoadProgressing(true)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                } else {
                    setDetail(result.detail);
                    setSeverityType("error");
                    setOpenSnackbar(true);
                }
            }
            );
    }

    const handleAddress = (event) => {
        return (
            <>
              {event?.split(",").map((part, index) => (
                <div key={index}>{part.trim()}</div>
              ))}
            </>
          );
    }

    function reviewmatrix(props) {
        const handleChangeRadio = (event, pTerm, col, ruleVer, ruleId) => {
            let selectedMat = offerMatrixObj.find(o => o.period === pTerm)
            if (selectedMat) {
                let selectedTd = selectedMat.data.find(s => s['guarantee_as_%_of_estimated_value'] === col["guarantee_as_%_of_estimated_value"])
                setHomeValue(selectedTd)
            }
            setRadioEvent(event)
            setRadioPterm(pTerm)
            setRadioCol(col)
            setRadioRulever(ruleVer)
            setRadioRuleId(ruleId)
            setMatrixSelect(true);
        }

        const handleClickSave = () => {
            if(matrixSelect){
            let body = {
                term:radiopTerm, 
                guarantee_percentage:homevalue["guarantee_as_%_of_estimated_value"],
                rule_version:radioruleVer,
                homeowner_ppg:homevalue["homeowner_PPG_at_this_%_of_estimated_value"],
                is_make_manual_offer:false
            }
            let tokenStr = localStorage.getItem('access_token')
            fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/save-offer-details/`,{ 
            method:"POST",
            headers:{
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(body)
            })
            .then(async response => {
                props.setIscalculated(true);
                const result = await response.json()
                setCalculatedValues(result);
                props.handleTab(2);
                setNewStatus(2);
                setMatrixSelect(false)
                handleClickOpens()
            })
            }else{
                handleClickOpens()
            }
            // props.setSelectedValue(radioevent)
            // props.setSelectedTerm(radiopTerm);
            // props.setSelectedPer(radioCol);
            // props.setOfferVersion(radioruleVer);
            // props.setHomeOfferValue(homevalue);
            // props.setOfferId(radioruleId);
        }
        const handleClickCancel = () => {
            handleClickOpens()
        }

        return (
            <div className="apps">
                {loadProgressing === false ? <div className="loaderProgress"><PageLoader /> </div> :
                    offerRes?.message ?
                        <Typography variant="h6" gutterBottom className="fontBold">
                            <Grid container style={{ justifyContent: 'center' }}>
                                <div className="TableContainer_layout" style={{ width: '50vh', height: '50vh' }}>
                                    <h1 style={{ padding: '10vh' }}>{offerRes?.message}</h1>
                                </div>
                            </Grid>
                        </Typography>
                        :
                        <Typography variant="h6" gutterBottom className="fontBold">
                            <Grid container style={{ justifyContent: 'center' }}>
                                <div className="TableContainer_layout ">
                                    <TableContainer component={Paper} className="TableContainer">
                                        <Table className={classes.table}>
                                            {offerRes.map((data) => {
                                                return (
                                                    <>
                                                        <TableHead className={classes.TblHead}>
                                                            <TableRow className="TblHeadRow">
                                                                <TableCell align="left" id="TblHeadTitle">{data.term_days + " " + "Days Term"} </TableCell>
                                                                {
                                                                    data && data['term_data'].map((guarantee_per, index) => {
                                                                        return (
                                                                            <TableCell align="center" key={index} id="TableHeadCell">
                                                                                {guarantee_per['guarantee_as_%_of_estimated_value']}%
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                            </TableRow>
                                                            <TableRow id="TblRadioRow" style={{ color: 'green' }}>
                                                                <TableCell align="right" className="TblRadioCell">
                                                                </TableCell>
                                                                {
                                                                    data && data['term_data'].map((guarantee_per, i) => {
                                                                        return (
                                                                            <TableCell align="center" className="TblRadioCell" style={{ height: "0px !important" }}>
                                                                                <Radio
                                                                                    checked={radioevent === `${i + 1}` && radiopTerm === data.term_days}
                                                                                    onChange={(e) => handleChangeRadio(e.target.value, data.term_days, guarantee_per, data.rule_version, data.rule_version_id)}
                                                                                    value={`${i + 1}`}
                                                                                    id="TableRadioBtnCLR"
                                                                                    name="radio-button-demo"
                                                                                    inputProps={{ 'aria-label': 'A' }}
                                                                                    className={(radioevent === `${i + 1}` && radiopTerm === data.term_days) ? 'RadioStyle' : ''}
                                                                                />
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                data && data['term_data'].map((title) => {
                                                                    label_array = Object.keys(title)
                                                                    const value = 'guarantee_as_%_of_estimated_value'
                                                                    label_array = label_array.filter(function (item) {
                                                                        return item !== value
                                                                    })
                                                                })

                                                            }
                                                            {
                                                                label_array && label_array.map((elem) => {
                                                                    names_label = elem.replaceAll("_", " ")
                                                                    name_label = names_label.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                                                                    return (

                                                                        <TableRow className="MatrixtblRow TblRow">
                                                                            <TableRow className="MatrixtblRow TblRow">
                                                                                <TableCell align="left" className="TblCell" id="TblBodyTitle">
                                                                                    {name_label}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {
                                                                                data && data['term_data'].map((guarantee_per, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            {guarantee_per[elem] !== guarantee_per['guarantee_as_%_of_estimated_value'] ? (
                                                                                                <TableCell align="center" className="TblCell" key={index} style={{ background: radioevent === `${index + 1}` && radiopTerm === data.term_days ? '#f4f4f4' : '#fff' }}>
                                                                                                    {guarantee_per[elem] === guarantee_per['mean_time_to_sell_in_market'] ? guarantee_per[elem] : guarantee_per[elem] === guarantee_per['guarantee_period'] ? guarantee_per[elem] : guarantee_per[elem] === guarantee_per['standard_deviation'] ? guarantee_per[elem] : guarantee_per[elem] === guarantee_per['risk_of_guarantee_being_triggered'] ? guarantee_per[elem] + "%" : "$" + "" + guarantee_per[elem].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                                </TableCell>
                                                                                            ) : <TableCell align="center" className="TblCell" style={{ background: radioevent === `${index + 1}` && radiopTerm === data.term_days ? '#f4f4f4' : '#fff' }}>
                                                                                                {guarantee_per[elem] === guarantee_per['mean_time_to_sell_in_market'] ? guarantee_per[elem] : guarantee_per[elem] === guarantee_per['guarantee_period'] ? guarantee_per[elem] : guarantee_per[elem] === guarantee_per['standard_deviation'] ? guarantee_per[elem] : guarantee_per[elem] === guarantee_per['risk_of_guarantee_being_triggered'] ? guarantee_per[elem] + "%" : "$" + "" + guarantee_per[elem].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                            </TableCell>}
                                                                                        </>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                        </TableBody>
                                                    </>
                                                )
                                            })
                                            }
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div className="btn_head">
                                    <Button
                                        className="primary_btn11"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClickSave}
                                    >
                                        Select
                                    </Button>
                                    <Button
                                        className="primary_btn12"
                                        variant="outlined"
                                        onClick={handleClickCancel}
                                        color="primary"
                                        style={{ background: '#fff' }}
                                    >
                                        <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-create">
                                            Cancel
                                        </NavLink>
                                    </Button>
                                </div>
                            </Grid>
                        </Typography>
                }
            </div>
        );
    }

    const manualmatrix = () =>{

        const handleManualSave=()=>{
            if(manualSelect){
                let body = {
                    term:guaranteePeriod, 
                    guarantee_percentage:manualResults?.['guarantee_as_%_of_estimated_value'],
                    rule_version:manualResults?.rule_version,
                    homeowner_ppg:manualResults?.['homeowner_PPG_at_this_%_of_estimated_value'],
                    is_make_manual_offer:true
                }
                let tokenStr = localStorage.getItem('access_token')
                fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/save-offer-details/`,{ 
                method:"POST",
                headers:{
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify(body)
                })
                .then(async response => {
                    props.setIscalculated(true);
                    const result = await response.json()
                    setCalculatedValues(result);
                    props.handleTab(2);
                    setNewStatus(2);
                    handleManualOfferOpens()
                    setManualSelect(false);
                })
            }else{
                handleManualOfferOpens()
            }
        }

        const handleManualCancel=()=>{
            handleManualOfferOpens()
            setPpgPercentage('');
            setPpgAmount('');
            setManualResults();
            setGuaranteePeriod('');
            setManualCalc(true);
        }

        const handlePpgPercentage = (e) => {
            setPpgPercentage(e.target.value);
        }
        const handlePpgAmount = (e) => {
            setPpgAmount(e.target.value);
        }

        const handleGuaranteePeriod = (e) => {
            setGuaranteePeriod(e.target.value)
        }

        const handleCalculateManual = (e) => {
            if((guaranteePeriod !== '' && ppgPercentage !== '' && ppgPercentage !== "0") || (guaranteePeriod !== '' && ppgAmount !== '' && ppgAmount !== "0")){
                let tokenStr = localStorage.getItem('access_token')
                let headers = { "Authorization": `Bearer ${tokenStr}` }
                let newPpgAmount = ppgAmount?.replace(/\,/g,'');
                let url;
                if(manualOption === 1){
                    url = `${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/calculate-make-manual-offer?ppg_percentage=${ppgPercentage}&guarantee_period=${guaranteePeriod}`;
                }else{
                    url = `${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/calculate-make-manual-offer?ppg_amount=${Number(newPpgAmount)}&guarantee_period=${guaranteePeriod}`
                }
                fetch(url,
                    {
                        headers
                    })
                    .then(async response => {
                        const result = await response.json()
                        if (response.status === 200 || response.status === 201) {
                            setManualResults(result);
                            setManualCalc(false);
                            setManualSelect(true);
                        } else if (response.status === 403) {
                            localStorage.setItem('access_token', '')
                            props.history.push('/');
                        }else{
                            setDetail(result.detail);
                            setManualResults(null);
                            setManualCalc(true);
                            setSeverityType("error");
                            setOpenSnackbar(true);
                        }
                })
            }
        }
        return(
            <div className="apps">
                <Grid container className='manualoffer-content'>
                    {manualOption === 1 ?
                    <Grid item xs={12} sm={12} md={12} lg={12} className="row formDiv">
                        <label>PPG Percentage:<span className="red-color">*</span></label>
                        <NumberFormat
                          name='ppgPercentage'
                          className="numberField"
                          thousandSeparator={','}
                          decimalSeparator={'.'}
                          autoComplete='off'
                          decimalScale={2}
                          allowNegative={false}
                          inputMode="numeric"
                          placeholder='0'
                          allowEmptyFormatting
                          maxLength={6}
                          value={ppgPercentage}
                          onChange={handlePpgPercentage}
                          isAllowed={(values) => {
                            const { floatValue } = values;
                            return (floatValue >= 0 && floatValue <= 100) || !floatValue;
                          }}
                        />
                    </Grid>:
                    <Grid item xs={12} sm={12} md={12} lg={12} className="row formDiv">
                        <label>PPG Amount:<span className="red-color">*</span></label>
                        <NumberFormat
                          name='ppgAmount'
                          className="numberField"
                          thousandSeparator={','}
                          decimalSeparator={'.'}
                          autoComplete='off'
                          decimalScale={2}
                          allowNegative={false}
                          inputMode="numeric"
                          maxLength="13"
                          placeholder='0'
                          allowEmptyFormatting
                          value={ppgAmount}
                          onChange={handlePpgAmount}
                        />
                    </Grid>}
                    <Grid item xs={12} sm={12} md={12} lg={12} className="row formDiv">
                        <label>Guarantee Period:</label>
                        <div class="views-widget" style={{ marginLeft:"4px" }}>
                            <select
                                id="guarantee"
                                name='guaranteePeriod'
                                className="form_select form-select-mgp"
                                style={{ color: '#000' }}
                                onChange={handleGuaranteePeriod}
                                value={guaranteePeriod}
                            >
                                <option value="">---Select---</option>
                                {guarantee_period && guarantee_period.map((item, index) => (
                                    <option key={index} value={item} >
                                        {item} days
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button
                            className={((guaranteePeriod !== '' && ppgPercentage !== '' && ppgPercentage !== "0")||(guaranteePeriod !== '' && ppgAmount !== '' && ppgAmount !== "0")) ?"calculate_btn":"calculate_btn_disable"}
                            style={{marginLeft:"10px"}}
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={handleCalculateManual}
                        >
                            Calculate
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{padding:"8px 10px",marginTop:"10px"}}>
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <Typography className='manualoffer-text'>Mean Time to Sell in Market:</Typography> 
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4}>
                                <Typography className='manualoffer-text'>{manualResults? manualResults.mean_time_to_sell_in_market.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):""}</Typography> 
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <Typography className='manualoffer-text'>Standard Deviation:</Typography> 
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4}>
                                <Typography className='manualoffer-text'>{manualResults? manualResults.standard_deviation.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):""}</Typography> 
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <Typography className='manualoffer-text'>Risk of Guarantee Being Triggered:</Typography> 
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4}>
                                <Typography className='manualoffer-text'>{manualResults? manualResults.risk_of_guarantee_being_triggered.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "%":""}</Typography> 
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <Typography className='manualoffer-text'>Estimated Home Value:</Typography> 
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4}>
                                <Typography className='manualoffer-text'>{manualResults? "$" + manualResults.estimated_home_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):""}</Typography> 
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <Typography className='manualoffer-text'>Homeowner PPG Amount:</Typography> 
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4}>
                                <Typography className='manualoffer-text'>{manualResults? manualResults['homeowner_PPG_at_this_%_of_estimated_value'] < 0 ? "$" + "(" + Math.abs(manualResults['homeowner_PPG_at_this_%_of_estimated_value']).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")" :"$" + manualResults['homeowner_PPG_at_this_%_of_estimated_value'].toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):""}</Typography> 
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <Typography className='manualoffer-text'>Max Buyer Takeout Offer:</Typography> 
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4}>
                                <Typography className='manualoffer-text'>{manualResults?  manualResults.max_buyer_takeout_offer < 0 ? "$" + "(" + Math.abs(manualResults.max_buyer_takeout_offer).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")" : "$" + manualResults.max_buyer_takeout_offer.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):""}</Typography> 
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <Typography className='manualoffer-text'> Pricing Gap:</Typography> 
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4}>
                                <Typography className='manualoffer-text'>{manualResults? manualResults.pricing_gap < 0 ? "$" + "(" + Math.abs(manualResults.pricing_gap).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")":"$" + manualResults.pricing_gap.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):""}</Typography> 
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <Typography className='manualoffer-text'>Potential Loss:</Typography> 
                            </Grid>
                            <Grid xs={12} sm={12} md={4} lg={4}>
                                <Typography className='manualoffer-text'>{manualResults? manualResults.anticipated_loss < 0 ? "$" + "(" + Math.abs(manualResults.anticipated_loss).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")" : "$" + manualResults.anticipated_loss.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):""}</Typography> 
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{justifyContent:"center"}} className="form_layout_btn">
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleManualSave}
                            disabled={manualCalc === true}
                        >
                            Save
                        </Button>
                        <Button
                            className="secondary_btn"
                            variant="outlined"
                            color="primary"
                            style={{ background: '#fff' }}
                            onClick={handleManualCancel}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const manualOptions = () =>{
        function StyledRadio(props) {
            const useStyles = makeStyles({
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }
            })
            const classes = useStyles();
            return (
                <Radio
                    className={classes.root}
                    disableripple="true"
                    color="default"
                    checkedIcon={<CheckCircleIcon className="checkedIcon" />}
                    icon={<RadioButtonUncheckedIcon />}
                    {...props}
                />
            );
        }
        return(
            <div style={{ padding:"25px 150px 25px 25px" }}>
                <div className='pt-2'>
                    <RadioGroup>
                        <FormControlLabel
                            label="Enter the PPG Percentage"
                            control={<StyledRadio />}
                            onChange={()=>{handleManualOption(1)}}
                            checked={false}
                        />                        
                        <FormControlLabel
                            label="Enter the PPG Amount"
                            control={<StyledRadio />}
                            onChange={()=>{handleManualOption(2)}}
                            checked={false}
                        />
                    </RadioGroup>
                </div>
            </div>
        )
    }


    const MarketInsightsConfirm = () =>{
        return(
            <Grid container className='avg_confirm'>
                <Grid item xs={12} sm={12} md={12} lg={12} className='avg_align'>
                    <h3> Do you want to get Plunk Real-Time Market Insights Data?</h3>
                </Grid>
                <Grid container className='avg_btns'>
                    <Grid item xs={6} sm={6} md={3} lg={3} className='avg_align'>
                        <Button className="primary_btn" variant="contained" color="primary" onClick={showMarketInsights}>
                            {plunkLoader === false ? 'Yes' : <CircularProgress size={18} color="white" className="circularProgess" />}
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <Button className="primary_btn" variant="contained" color="primary" onClick={handlePlunkMarketInsights}>
                            No
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const handleManualOfferApi = () =>{
        handleManualOfferOpens()
    }

    const handleManualOptions = () =>{
        handleManualOptionsOpen()
    }

    const handleManualOption = (id) =>{
        setManualOption(id);
        handleManualOfferOpens()
    }

    // const handleNutshell = () => {
    //     setNutshellAPILoader(true)
    //     let tokenStr = localStorage.getItem('access_token')
    //     fetch(`${baseUrl.baseUrl}trade-in-application/${forms?.trade_in_application_id}/sync-with-nutshell/`, {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Authorization': `Bearer ${tokenStr}`,
    //             'Content-Type': 'application/json'
    //         },
    //     })
    //         .then(async response => {
    //             const results = await response.json()
    //             if (response.status === 201 || response.status === 200) {
    //                 setDetail(results.message);
    //                 setSeverityType("success");
    //                 setOpenSnackbar(true);
    //                 await sleep(1000);
    //                 setOpenSnackbar(false);
    //                 setNutshellAPILoader(false)
    //                 setSubmittedCRM(true);
    //             } else {
    //                 if (response.status === 403) {
    //                     localStorage.setItem('access_token', '')
    //                     props.history.push('/');
    //                     setNutshellAPILoader(false)
    //                 }
    //                 if (results.detail) {
    //                     setDetail(results.detail)
    //                     setSeverityType("error");
    //                     setOpenSnackbar(true);
    //                     await sleep(1000);
    //                     setOpenSnackbar(false);
    //                     setNutshellAPILoader(false)
    //                 }
    //                 else {
    //                     setDetail("something went wrong")
    //                     setSeverityType("error");
    //                     setOpenSnackbar(true);
    //                     await sleep(1000);
    //                     setOpenSnackbar(false);
    //                     setNutshellAPILoader(false)
    //                 }
    //             }
    //         })
    //         .catch((error) => {
    //             console.log('error', error)
    //         })
    // }


    const handleManualOfferOpens = () =>{
        setManualOpen(!manualOpen);
        setManualOptionsOpen(false);
        setPpgPercentage('');
        setPpgAmount('');
        setManualResults();
        setGuaranteePeriod('');
        setManualCalc(true);
    }

    const handleManualOptionsOpen = () =>{
        setManualOptionsOpen(!manualOptionsOpen);
    }

    const handleClickOpens = () => {
        setOpen(!open);
    };
    const toggleValuationDetails = () => {
        setValuationDetails(!valuationDetails)
    }

    function checkInputLength(inputField) {
        var currentLength = inputField.value.length;

        if (currentLength > 100) {
            inputField.value = inputField.value.substring(0, 100);
        }
    }

    const handleCheckboxChange = (id) => {
        setCheckedRows((prevCheckedRows) => {
            const updatedCheckedRows = {
                ...prevCheckedRows,
                [id]: !prevCheckedRows[id],
            };
            const trueIds = Object.keys(updatedCheckedRows).filter(id => updatedCheckedRows[id] === true);
            setTrueRows(trueIds);

            // Determine which delete icons to disable
            const disabledIcons = {};
            forms.valuation_details_history.forEach(row => {
                if (updatedCheckedRows[row.valuation_details_history_id]) {
                    disabledIcons[row.valuation_details_history_id] = true;
                } else {
                    disabledIcons[row.valuation_details_history_id] = false;
                }
            });
            setDisabledDeleteIcons(disabledIcons);

            return updatedCheckedRows;
        });
    }
    // Function to calculate the average of checked rows
    const calculateEstHomeAverage = () => {
        const checkedValues = forms?.valuation_details_history
            .filter((_) => checkedRows[_.valuation_details_history_id])
            .map((row) => row.estimated_home_value);

        let numberArray = [];
        let length = checkedValues.length;
        for (let j = 0; j < length; j++){
            const parsedValue = parseInt(checkedValues[j]);
            if (!isNaN(parsedValue)) {
                numberArray.push(parsedValue);
            }
            // numberArray.push(isNaN(parsedValue) ? 0 : parsedValue);
            // numberArray.push(parseInt(checkedValues[j]));
        }
        const sum = numberArray.reduce((acc, value) => acc + value, 0);
        const average = sum / numberArray.length || 0;
        if(length > 0){
            estimatedAvg = average.toFixed(2);
        }

        return isNaN(average) ? '$0.00' : `$${average.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    };
    const calculateMinAverage = () => {
        const checkedValues = forms?.valuation_details_history
            .filter((_) => checkedRows[_.valuation_details_history_id])
            .map((row) => row.minimum_value);

        let numberArray = [];
        let length = checkedValues.length;
        for (let j = 0; j < length; j++){
            const parsedValue = parseInt(checkedValues[j]);
            if (!isNaN(parsedValue)) {
                numberArray.push(parsedValue);
            }
            // numberArray.push(isNaN(parsedValue) ? 0 : parsedValue);
            // numberArray.push(parseInt(checkedValues[j]));
        }
        const sum = numberArray.reduce((acc, value) => acc + value, 0);
        const average = sum / numberArray.length || 0;
        if(length > 0){
            minimumAvg = average.toFixed(2);
        }

        return isNaN(average) ? '$0.00' : `$${average.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    };
    const calculateMaxAverage = () => {
        const checkedValues = forms?.valuation_details_history
            .filter((_) => checkedRows[_.valuation_details_history_id])
            .map((row) => row.maximum_value);

        let numberArray = [];
        let length = checkedValues.length;
        for (let j = 0; j < length; j++){
            const parsedValue = parseInt(checkedValues[j]);
            if (!isNaN(parsedValue)) {
                numberArray.push(parsedValue);
            }
            // numberArray.push(isNaN(parsedValue) ? 0 : parsedValue);
            // numberArray.push(parseInt(checkedValues[j]));
        }
        const sum = numberArray.reduce((acc, value) => acc + value, 0);
        const average = sum / numberArray.length || 0;
        if(length > 0){
            maximumAvg = average.toFixed(2);
        }

        return isNaN(average) ? '$0.00' : `$${average.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    };

    const deleteHistory = (id,selectedEntry) => {
        const isFirstEntry = forms?.valuation_details_history && forms.valuation_details_history.length > 0 && forms.valuation_details_history[0].valuation_details_history_id === id;
        if (isFirstEntry) {
            setDeleteAlertText('This is the most recent entry into the history table and is being used to drive the financial calculations, you must add a new entry before deleting this entry.')
            setOpenDeleteAlert(true);
            return;
        }else if(selectedEntry === true){
            setDeleteAlertText("This entry is part of the average calculation, you cannot delete it.  First, remove it from the average if you wish to delete it.")
            setOpenDeleteAlert(true);
            return;
        }else{
            setHistoryId(id);
        }
        setDeleteClicked(!deleteClicked);
    }

    const deleteHistoryConfirm = () =>{
        let tokenStr = localStorage.getItem('access_token')
        let headers = { "Authorization": `Bearer ${tokenStr}` }
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/delete-valuation-details-history?valuation_details_history_id=${historyId}`,
        {
            headers
        })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setForms(result);
                    deleteHistory();
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
        })
    }

    const handleDeleteConfirm = () =>{
        return(
            <Grid container className='avg_confirm'>
                <Grid item xs={12} sm={12} md={12} lg={12} className='avg_align'>
                    <h3> Do you want to delete the selected history record?</h3>
                </Grid>
                <Grid container className='avg_btns'>
                    <Grid item xs={6} sm={6} md={3} lg={3} className='avg_align'>
                        <Button className="primary_btn" variant="contained" color="primary" onClick={deleteHistoryConfirm}>
                            Yes
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <Button className="primary_btn" variant="contained" color="primary" onClick={deleteHistory}>
                            No
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    } 
        
    return(
        <>
            <CommonHeader cxSpecialists={props.cxSpecialists} cxSpecialist={props.cxSpecialist} setCxSpecialist={props.setCxSpecialist}  statusOffer={props.statusOffer}/>
            <form onSubmit={formik.handleSubmit}>
            <Grid container className='section-level'>
                <Grid container className="row formDiv valuationDetails">
                    <Grid item xs={12} sm={12} md={3} lg={3} className="row formDiv">
                        <Button
                            disableripple
                            focusRipple='true'
                            onClick={toggleValuationDetails}
                            id="valuationDetailBtn"
                        >
                            {
                                valuationDetails === false ? <AddSharpIcon color="secondary" style={{ color: '#1976d2 !important' }} /> : <RemoveIcon color="secondary" style={{ color: '#1976d2 !important' }} />
                            }
                            <span className="font-weight-bold val-details-text">Valuation Details</span><span className="red-color" id="validationAstrick">*</span>
                        </Button>
                    </Grid>
                    <Grid item xs={8} sm={8} md={3} lg={3} className="row formDiv">
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            id="valuationBtnSource"
                            onClick={(e) => { handleAVMValuationSourcePopup(e) }}
                            // disabled={!formik.values.propertyInfoStreet || formik.values.propertyInfoCity === "" || formik.values.propertyInfoState === "" || formik.values.propertyInfoCounty === "" || zipCodeValue === null || zipCodeValue === ""}
                        >
                            Select Valuation Source
                        </Button>
                    </Grid>
                    <div id="valSaveBtnOne">
                        <Grid item xs={12} sm={12} md={3} lg={3} className="row formDiv">
                            <Button
                                className="primary_btn"
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={(e) => handleValuationDetailsSave(e)}
                                disabled={mgpLoader === true}
                            >
                                {mgpLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                            </Button>
                        </Grid>
                    </div>
                </Grid>
                <Grid container className="row formDiv" id="avmSave">
                    <Grid item xs={3} sm={3} md={3} lg={4}>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                    </Grid>
                </Grid>
                <div id="valSaveBtnTwo">
                    <Grid item xs={12} sm={12} md={3} lg={3} className="row formDiv">
                        <Button
                            className="primary_btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => handleValuationDetailsSave(e)}
                            disabled={mgpLoader === true}
                        >
                            {mgpLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                        </Button>
                    </Grid>
                </div>
                <Grid container style={{padding:"10px"}}>
                    <Grid container className="row formDiv section-level-inner" style={valuationDetails === true ? show : hide} id="valuationSection">
                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                            <label>Valuation Source</label>
                            <div class="views-widget">
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name='propertyInfoValuation'
                                    value={handleValuationSourceValue()}
                                    disabled="true"
                                    onChange={formik.handleChange}
                                    onChangeCapture={handleValuationSource}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                            <label>Estimated Home Value<span className="red-color">*</span></label>
                            <NumberFormat
                                className="numberField"
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                autoComplete='off'
                                decimalScale={2}
                                allowNegative={false}
                                maxLength="12"
                                variant="outlined"
                                name="propertyInfoEstimated"
                                onChange={formik.handleChange}
                                value={formik.values.propertyInfoEstimated}
                                disabled={avmValuationManual !== true && (formik.values.propertyInfoValuation === null || formik.values.propertyInfoValuation === "" || (formik.values.propertyInfoValuation == "2" && ((avmValuationTypeValue == "true" || avmValuationTypeValue == null) && displayProvider && displayProvider.length != 0)))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                            <label>Minimum Value</label>
                            <NumberFormat
                                className="numberField"
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                autoComplete='off'
                                decimalScale={2}
                                allowNegative={false}
                                maxLength="12"
                                variant="outlined"
                                name="propertyInfoMinValue"
                                onChange={formik.handleChange}
                                value={formik.values.propertyInfoMinValue}
                                disabled={avmValuationManual !== true && (formik.values.propertyInfoValuation === null || formik.values.propertyInfoValuation === "" || (formik.values.propertyInfoValuation == "2" && ((avmValuationTypeValue == "true" || avmValuationTypeValue == null) && displayProvider && displayProvider.length != 0)))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                            <label>Maximum Value</label>
                            <NumberFormat
                                className="numberField"
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                autoComplete='off'
                                allowNegative={false}
                                decimalScale={2}
                                maxLength="12"
                                variant="outlined"
                                name="propertyInfoMaxValue"
                                onChange={formik.handleChange}
                                value={formik.values.propertyInfoMaxValue}
                                disabled={avmValuationManual !== true && (formik.values.propertyInfoValuation === null || formik.values.propertyInfoValuation === "" || (formik.values.propertyInfoValuation == "2" && ((avmValuationTypeValue == "true" || avmValuationTypeValue == null) && displayProvider && displayProvider.length != 0)))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                            <label>FSD</label>
                            <NumberFormat
                                className="numberField"
                                autoComplete='off'
                                maxLength="5"
                                variant="outlined"
                                allowNegative={false}
                                name="veros_avm_fsd_value"
                                onChange={formik.handleChange}
                                value={formik.values.veros_avm_fsd_value}
                                disabled={(avmValuationManual === true && avmValuationTypeValue == "false" && displayProvider && displayProvider === 'Veros' && (displayProvider.length != 0 || displayProvider != '') || avmValuationManual === true && (displayProvider && displayProvider?.toString().toLowerCase().includes('veros')) ? false : true)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                            <label>Confidence Score</label>
                            <NumberFormat
                                className="numberField"
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                autoComplete='off'
                                allowNegative={false}
                                decimalScale={2}
                                maxLength="12"
                                variant="outlined"
                                name="veros_avm_confidence_score"
                                onChange={formik.handleChange}
                                value={formik.values.veros_avm_confidence_score}
                                disabled={(avmValuationManual === true && avmValuationTypeValue == "false" && displayProvider && displayProvider === 'Veros' && (displayProvider.length != 0 || displayProvider != '') || avmValuationManual === true && (displayProvider && displayProvider?.toString().toLowerCase().includes('veros')) ? false : true)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv valuationProvider">
                            <label>Valuation Provider</label>
                            <div class="views-widget">
                                <TextField
                                    autoComplete='off'
                                    variant="outlined"
                                    formNovalidate
                                    name='propertyInfoValuationProvider'
                                    value={displayProvider}
                                    disabled="true"
                                    onChange={formik.handleChange}
                                    id="valuationProvider"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>      
                {  formik.touched.propertyInfoEstimated && formik.errors.propertyInfoEstimated && displayPropertyError === true ?
                        <div  style={{width:"100%"}} className="error-msg">{formik.errors.propertyInfoEstimated}</div>
                        :
                        ""
                }
            </Grid>
            <TableContainer>
                <Table aria-label="simple table" className="table">
                    <TableHead className="head">
                        <TableRow style={{ width: '100%' }}>
                            <TableCell className="fontBold" style={{width:"25%"}}>Address</TableCell>
                            <TableCell className="fontBold">Entry Type</TableCell>
                            <TableCell className="fontBold">Valuation Source</TableCell>
                            <TableCell className="fontBold">Valuation Provider</TableCell>
                            <TableCell className="fontBold">Estimated Home Value</TableCell>
                            <TableCell className="fontBold">Minimum Value</TableCell>
                            <TableCell className="fontBold">Maximum Value</TableCell>
                            <TableCell className='fontBold'>Average</TableCell>
                            <TableCell className="fontBold">FSD</TableCell>
                            <TableCell className="fontBold">Confidence Score</TableCell>
                            <TableCell className="fontBold" style={{width:"80px"}}>Updated Date</TableCell>
                            <TableCell className="fontBold">Updated By</TableCell>
                            <TableCell className="fontBold"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="table_border_body">
                        {forms?.valuation_details_history && forms?.valuation_details_history.map((row, index) => {
                            return (<TableRow key={row.valuation_details_history_id}>
                                <TableCell component="th" scope="row">{handleAddress(row?.property_address)}</TableCell>
                                <TableCell component="th" scope="row">{row?.entry_type}</TableCell>
                                <TableCell component="th" scope="row">{row?.valuation_source}</TableCell>
                                <TableCell component="th" scope="row">{row?.valuation_provider}</TableCell>
                                <TableCell component="th" scope="row">{row?.estimated_home_value !== null ? "$" + row?.estimated_home_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null}</TableCell>
                                <TableCell component="th" scope="row">{row?.minimum_value !== null ? "$" + row?.minimum_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null}</TableCell>
                                <TableCell component="th" scope="row">{row?.maximum_value !== null ? "$" + row?.maximum_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null}</TableCell>
                                <TableCell component="th" scope="row"> <Checkbox checked={checkedRows[row.valuation_details_history_id] || false}  onChange={() => handleCheckboxChange(row.valuation_details_history_id)} /></TableCell>
                                <TableCell component="th" scope="row">{row?.veros_avm_fsd_value}</TableCell>
                                <TableCell component="th" scope="row">{row?.veros_avm_confidence_score}</TableCell>
                                <TableCell component="th" scope="row">{row?.created_at ? new Date(row.created_at).toLocaleString('en-US', 'options', { hour12: false }) : 'NA'}</TableCell>
                                <TableCell component="th" scope="row">{row?.created_by}</TableCell>
                                <TableCell component="th" scope="row"><DeleteIcon style={{cursor:"pointer"}} onClick={() => deleteHistory(row.valuation_details_history_id,row.selected_for_average_calc)}/></TableCell>
                            </TableRow>)
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell component="th" scope="row">Average</TableCell>
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell component="th" scope="row">{calculateEstHomeAverage()}</TableCell>
                            <TableCell component="th" scope="row">{calculateMinAverage()}</TableCell>
                            <TableCell component="th" scope="row">{calculateMaxAverage()}</TableCell>
                            <TableCell component="th" scope="row" colSpan={4}>
                                <Button className="primary_btn" disabled={trueRows.length <= 1 ?true:false} onClick={averageValues} variant="contained" color="primary">
                                    {averageLoader === false ? "Record Average Value" : <CircularProgress size={18} color="white" className="circularProgess" />}
                                </Button>
                            </TableCell>
                            <TableCell component="th" scope="row"></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <Grid container>
                        <Grid item md={7} lg={7}>                                
                            <Grid container className='insights-cell'>
                                <Grid item md={12} lg={12} style={{marginTop: '20px'}}>
                                    <h3>VeroValue AVM Property Data</h3>
                                </Grid>
                                <Grid item md={6} lg={6}>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Prop Type: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.prop_type}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Owner Name: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.owner_name}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Parcel ID: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.parcel_id}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Sale Price: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.sale_price ? "$"+ forms?.veros_avm_property_data[0]?.sale_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):""}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Sale Date: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.sale_date}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Legal Description: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.legal_description}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid md={6} lg={6}>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Year Built: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.year_built}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Gross Living Area: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.gross_living_area}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Bedrooms: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.bedrooms}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Bathrooms: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.bathrooms}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Units: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{forms?.veros_avm_property_data[0]?.units}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='insights-cell'>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>Garage Spaces: </Typography>
                                        </Grid>
                                        <Grid md={6} lg={6}>
                                            <Typography className='val-details'>{garageSpaces}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: '76px' }}>
                                <Grid item md={12} lg={12}>
                                    <h3>Property Financial Analysis</h3>
                                </Grid>
                                <Grid item md={6} lg={6}>
                                    <Grid md={12} lg={12} className='insights-cell'>
                                        <Typography className='val-details'>Home 1 Value: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} className='insights-cell'>
                                        <Typography className='val-details'>Home 1 Purchase Price: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} className='insights-cell'>
                                        <Typography className='val-details'>Monthly PITI: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} className='insights-cell'>
                                        <Typography className='val-details'>Home 1 Mortgage Balance: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} className='insights-cell'>
                                        <Typography className='val-details'>Max Available Home Equity (80% CLTV): </Typography>
                                    </Grid>
                                </Grid>
                                <Grid md={6} lg={6}>
                                    <Grid md={12} lg={12} className='insights-cell'>
                                        <Typography className='val-details'>${forms?.calculated_offer?.home_1_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid>
                                    <Grid md={6} lg={6} className='insights-cell'>
                                        <Typography className='val-details'>${forms?.calculated_offer?.home_1_purchase_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid>
                                    <Grid md={6} lg={6} className='insights-cell'>
                                        <Typography className='val-details'>${forms?.calculated_offer?.monthly_piti.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid>
                                    <Grid md={6} lg={6} className='insights-cell'>
                                        <Typography className='val-details'>${forms?.calculated_offer?.home_1_mortgage_balance.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid>
                                    <Grid md={6} lg={6} className='insights-cell'>
                                        <Typography className='val-details'>{(forms?.calculated_offer?.max_available_home_equity_80_percent_cltv < 0) ? "$" + "" + "(" + Math.abs(forms?.calculated_offer?.max_available_home_equity_80_percent_cltv)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")" : "$" + forms?.calculated_offer?.max_available_home_equity_80_percent_cltv.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</Typography>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={5} lg={5}>
                            { forms.veros_avm_property_data[0]?.avm_price_forecast ? ( <>
                            <p className='graph-heading'>Home Price Forecast</p>
                            <img className='graph-img' src={forms.veros_avm_property_data[0]?.avm_price_forecast} /></>) : <></> }
                            { forms.veros_avm_property_data[0]?.neighborhood_price_comparison ? <img className='graph-img' src={forms.veros_avm_property_data[0]?.neighborhood_price_comparison} /> : <></> }
                            { forms.veros_avm_property_data[0]?.neighborhood_vs_zipcode ? <img className='graph-img' src={forms.veros_avm_property_data[0]?.neighborhood_vs_zipcode} /> : <></> }
                        </Grid>
                    </Grid>
                </Grid>
                <hr style={{width:"61%",textAlign:"left",marginLeft:"0"}}></hr>
                <Grid item md={12} lg={12}>
                    <Grid container>
                        <Grid item md={7} lg={7}>                                       
                            <Grid container>
                                <Grid item md={6} lg={6}>
                                    <Grid md={12} lg={12}>
                                        <Typography className='val-details insights-cell'>New House Maximum Purchase Price: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} style={{ paddingTop:"8px" }}>
                                        <Typography className='val-details insights-cell'>Est. Down Payment Expense: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} style={{paddingTop:"8px" }}>
                                        <Typography className='val-details insights-cell'>Est. PITI Expenses: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} style={{ paddingTop:"8px" }}>
                                        <Typography className='val-details insights-cell'>Est. Closing Costs: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} style={{ paddingTop:"8px" }}>
                                        <Typography className='val-details insights-cell'>Est. Commisions: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} style={{ paddingTop:"8px" }}>
                                        <Typography className='val-details insights-cell'>Est. Calque Fees: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} style={{ paddingTop:"8px" }}>
                                        <Typography className='val-details insights-cell'>Equity Required: </Typography>
                                    </Grid>
                                    <Grid md={12} lg={12} style={{ paddingTop:"8px" }}>
                                        <Typography className='val-details insights-cell'>Max HE - Equity Required: </Typography>
                                    </Grid>
                                </Grid>
                                <Grid md={6} lg={6}>
                                    <Grid md={12} lg={12} style={{ paddingTop:"5px" }}>
                                        <Typography className='val-details val-details-amnt'>${forms?.calculated_offer?.new_house_maximum_purchase_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</Typography>
                                    </Grid>
                                    <Grid md={6} lg={6} style={{ paddingTop:"17px" }}>
                                        <Typography className='val-details val-details-amnt'>${recalculateStatus ? recalculatedAmt.estimated_downpayment_expense.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : forms?.calculated_offer?.estimated_downpayment_expense.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid>
                                    <Grid md={6} lg={6} style={{ paddingTop:"17px" }}>
                                        <Typography className='val-details val-details-amnt'>${recalculateStatus ? recalculatedAmt.estimated_piti_expenses.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : forms?.calculated_offer?.estimated_piti_expenses.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid>
                                    <Grid md={6} lg={6} style={{ paddingTop:"17px" }}>
                                        <Typography className='val-details val-details-amnt'>${recalculateStatus ? recalculatedAmt.estimated_closing_costs.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : forms?.calculated_offer?.estimated_closing_costs.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid>
                                    <Grid md={6} lg={6} style={{ paddingTop:"17px" }}>
                                        <Typography className='val-details val-details-amnt'>${recalculateStatus ? recalculatedAmt.estimated_commisions.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : forms?.calculated_offer?.estimated_commisions.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid> 
                                    <Grid md={6} lg={6} style={{ paddingTop:"17px" }}>
                                        <Typography className='val-details val-details-amnt'>${recalculateStatus ? recalculatedAmt.estimated_calque_fees.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : forms?.calculated_offer?.estimated_calque_fees.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid> 
                                    <Grid md={6} lg={6} style={{ paddingTop:"17px" }}>
                                        <Typography className='val-details val-details-amnt'>${recalculateStatus ? recalculatedAmt.equity_required.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : forms?.calculated_offer?.equity_required.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                    </Grid> 
                                    <Grid md={6} lg={6} style={{ paddingTop:"17px" }}>
                                        <Typography className='val-details val-details-amnt'>{(recalculateStatus ? recalculatedAmt.max_he_minus_equity_required < 0 ?  "$" + "" + "(" + Math.abs(recalculatedAmt.max_he_minus_equity_required)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")": "$" + recalculatedAmt.max_he_minus_equity_required?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : forms?.calculated_offer?.max_he_minus_equity_required < 0 ? "$" + "" + "(" + Math.abs(forms?.calculated_offer?.max_he_minus_equity_required)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ")" : "$" + forms?.calculated_offer?.max_he_minus_equity_required.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))}</Typography>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} lg={3} style={{ position:"relative" }}>
                            <Grid container style={{ position:"absolute", right:"160px" }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='percentage-fields'>
                                    <button
                                    className="calculate_btn"
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={handleDownpayment}
                                    >
                                    Recalculate
                                    </button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='percentage-fields' style={{display:"flex", paddingTop:"12px"}}>
                                    <NumberFormat
                                    name='downpayment_percentage'
                                    style={{width:"45px",height:"21px",marginLeft:"6px",marginRight:"5px"}}
                                    decimalSeparator={'.'}
                                    autoComplete='off'
                                    decimalScale={2}
                                    allowNegative={false}
                                    inputMode="numeric"
                                    placeholder='0%'
                                    allowEmptyFormatting
                                    maxLength={6}
                                    value={formik.values.downpayment_percentage}
                                    onChange={formik.handleChange}
                                    isAllowed={(values) => {
                                        const { floatValue } = values;
                                        return (floatValue >= 0 && floatValue <= 100) || !floatValue;
                                    }}
                                    suffix="%"
                                    />
                                    <Typography className='val-details'> Downpayment %</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='percentage-fields' style={{display:"flex", paddingTop:"12px"}}>
                                    <NumberFormat
                                    name='months_of_piti'
                                    style={{width:"45px",height:"21px",marginLeft:"6px",marginRight:"5px"}}
                                    autoComplete='off'
                                    allowNegative={false}
                                    inputMode="numeric"
                                    decimalSeparator={'.'}
                                    decimalScale={2}
                                    maxLength={6}
                                    allowEmptyFormatting
                                    value={formik.values.months_of_piti}
                                    onChange={formik.handleChange}
                                    isAllowed={(values) => {
                                        const { floatValue } = values;
                                        return (floatValue >= 0 && floatValue <= 999) || !floatValue;
                                    }}
                                    />
                                    <Typography className='val-details'> Months of PITI</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='percentage-fields' style={{display:"flex", paddingTop:"12px"}}>
                                    <NumberFormat
                                    name='closing_cost_percentage'
                                    style={{width:"45px",height:"21px",marginLeft:"6px",marginRight:"5px"}}
                                    decimalSeparator={'.'}
                                    autoComplete='off'
                                    decimalScale={2}
                                    allowNegative={false}
                                    inputMode="numeric"
                                    placeholder='0%'
                                    allowEmptyFormatting
                                    maxLength={6}
                                    value={formik.values.closing_cost_percentage}
                                    onChange={formik.handleChange}
                                    isAllowed={(values) => {
                                        const { floatValue } = values;
                                        return (floatValue >= 0 && floatValue <= 100) || !floatValue;
                                    }}
                                    suffix="%"
                                    />
                                    <Typography className='val-details'> Closing cost %</Typography>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12} className='percentage-fields' style={{display:"flex", paddingTop:"12px"}}>
                                    <NumberFormat
                                    name='commision_percentage'
                                    style={{width:"45px",height:"21px",marginLeft:"6px",marginRight:"5px"}}
                                    decimalSeparator={'.'}
                                    autoComplete='off'
                                    decimalScale={2}
                                    allowNegative={false}
                                    inputMode="numeric"
                                    placeholder='0%'
                                    allowEmptyFormatting
                                    maxLength={6}
                                    value={formik.values.commision_percentage}
                                    onChange={formik.handleChange}
                                    isAllowed={(values) => {
                                        const { floatValue } = values;
                                        return (floatValue >= 0 && floatValue <= 100) || !floatValue;
                                    }}
                                    suffix="%"
                                    />
                                    <Typography className='val-details'> Commision %</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='percentage-fields' style={{display:"flex", paddingTop:"12px"}}>
                                    <NumberFormat
                                    name='ppg_percent'
                                    style={{width:"45px",height:"21px",marginLeft:"6px",marginRight:"5px"}}
                                    decimalSeparator={'.'}
                                    autoComplete='off'
                                    decimalScale={2}
                                    allowNegative={false}
                                    inputMode="numeric"
                                    placeholder='0%'
                                    allowEmptyFormatting
                                    maxLength={6}
                                    value={formik.values.ppg_percent}
                                    onChange={formik.handleChange}
                                    isAllowed={(values) => {
                                        const { floatValue } = values;
                                        return (floatValue >= 0 && floatValue <= 100) || !floatValue;
                                    }}
                                    suffix="%"
                                    />
                                    <Typography className='val-details'> PPG %</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="form_layout_btn">
                <Button
                    className="primary_btn"
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={(e) => handleValuationDetailsSave(e)}
                    disabled={mgpLoader === true}
                >
                    {mgpLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                </Button>
                <Button
                    onClick={handleOfferApi}
                    className="primary_btn"
                    variant="contained"
                    color="primary"
                    style={forms?.trade_in_application_id ? show : hide}
                >
                    Review Offer Matrix
                </Button>
                <Button
                    // onClick={handleManualOfferApi}
                    onClick={handleManualOptions}
                    className="primary_btn"
                    variant="contained"
                    color="primary"
                    // disabled={mgpLoader === true}
                >
                    Make Manual Offer
                </Button>
                {/* {forms?.trade_in_application_id && forms?.is_test_app === false ?
                    <Button
                        className="primary_btn"
                        variant="contained"
                        style={{width:"180px"}}
                        color="primary"
                        onClick={(e) => { handleNutshell(e) }}
                        disabled={nuthshellAPILoader === true}
                    >
                        {nuthshellAPILoader === false ? 'Sync With Nutshell' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                    </Button>
                    : null
                } */}
                <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact  to="/application-listing">
                    <Button
                        className="secondary_btn"
                        variant="outlined"
                        color="primary"
                        style={{ background: '#fff' }}
                    >
                        Cancel
                    </Button>
                </NavLink>
            </Grid>  
            </form>
            <Snackbar open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleClose1}
                anchorOrigin={{ vertical, horizontal }} >
                <Alert onClose={handleClose1} severity={severity_type}>
                    <div className="error" style={{ color: 'white' }}>
                        <span>{detail}</span>
                    </div>
                </Alert>
            </Snackbar>
            <Snackbar open={openDeleteAlert}
                autoHideDuration={7000}
                onClose={handleDeleteClose}
                anchorOrigin={{ vertical, horizontal }} >
                <Alert onClose={handleDeleteClose} variant="filled" severity="error">
                    {deleteAlertText}
                </Alert>
            </Snackbar>
            <Reviewmodal open={open} class="modal_body1" handleClickOpen={handleClickOpens} deactiveUser={reviewmatrix({ ...props })} header="Premium Review Matrix" offerMatrix />
            <Reviewmodal open={manualOpen} class="modal_body1" handleClickOpen={handleManualOfferOpens} deactiveUser={manualmatrix({ ...props })} header="Make Manual Offer" offerMatrix />
            <Reviewmodal open={manualOptionsOpen} class="modal_body1" handleClickOpen={handleManualOptionsOpen} deactiveUser={manualOptions({ ...props })} header="Select The Below Option" offerMatrix />
            <Reviewmodal open={plunkMarketInsights} class="modal_body1" handleClickOpen={handlePlunkMarketInsights} deactiveUser={MarketInsightsConfirm({ ...props })} header="Plunk Market Insights" offerMatrix />
            <Reviewmodal open={deleteClicked} class="modal_body1" handleClickOpen={deleteHistory} deactiveUser={handleDeleteConfirm({ ...props })} header="Valuation History Delete" offerMatrix />
            <Modalpopup open={openAVMValuationSourcePopup} handleClickOpen={handleAVMValuationSourcePopup} deactiveUser={handleGetAVMValuationSource(validationSource, formik, setOpenAVMValuationSourcePopup, setValuationDetails, setAvmValuationManual)} header="Select Valuation Source" />
            <Modalpopup open={avmValuationType} handleClickOpen={handleAVMValuationType} deactiveUser={handleGetAvmValuationType(formik.values.propertyInfoValuation)} header="Select Entry Type" />
            <Modalpopup open={openAVMValuesPopup} handleClickOpen={handleAVMValuesPopup} deactiveUser={handleGetAVMValues( forms, valuationProvider, setCheckBoxValue, displayProvider, setDisplayProvider, valuationProvider, avmValuationTypeValue, formik.values.propertyInfoValuation, setAvmValuationManual)} header="Select Valuation Provider" />
        </>
    )
}