import React, { useState, createContext } from 'react';

const FormContext = createContext();
function FormProvider(props){
	const [forms, setForms] = useState([]);
	return(
		<FormContext.Provider value={[forms,setForms]}>
			{props.children}
		</FormContext.Provider>
	);
}

const ReviewMatrixContext = createContext();
function ReviewMatrixProvider(props){
	const [calculatedValues, setCalculatedValues] = useState([]);
	return(
		<ReviewMatrixContext.Provider value={[calculatedValues,setCalculatedValues]}>
			{props.children}
		</ReviewMatrixContext.Provider>
	);
}

const LoanOfficerContext = createContext();
function LoanOfficerProvider(props){
	const [newEntries, setNewEntries] = useState([]);
	return(
		<LoanOfficerContext.Provider value={[newEntries,setNewEntries]}>
			{props.children}
		</LoanOfficerContext.Provider>
	);
}

const ApplicationStatusContext = createContext();
function ApplicationStatusProvider(props){
	const [newStatus, setNewStatus] = useState(1);
	return(
		<ApplicationStatusContext.Provider value={[newStatus,setNewStatus]}>
			{props.children}
		</ApplicationStatusContext.Provider>
	);
}

const TestApplicationContext = createContext();
function TestApplicationProvider(props){
	const [isTest, setIsTest] = useState(null);
	return(
		<TestApplicationContext.Provider value={[isTest,setIsTest]}>
			{props.children}
		</TestApplicationContext.Provider>
	);
}

const UserRoleContext = createContext();
function UserRoleProvider(props){
	const [role, setRole] = useState(null);
	return(
		<UserRoleContext.Provider value={[role,setRole]}>
			{props.children}
		</UserRoleContext.Provider>
	);
}

export { FormContext, FormProvider, ReviewMatrixContext, ReviewMatrixProvider, LoanOfficerContext, LoanOfficerProvider, ApplicationStatusContext, ApplicationStatusProvider, TestApplicationContext, TestApplicationProvider, UserRoleContext, UserRoleProvider };