import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from '@material-ui/core/Container';
import '../../styles/Viewhistory.css';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import RemoveIcon from '@material-ui/icons/Remove';
import { FormContext } from '../../../context/FormContext';
import baseUrl from '../../config/config';
import CommonHeader from "./CommonHeader";

const useRowStyles = makeStyles(() => ({
  root: {
    "& > *": {
      borderBottom: "unset"
    },
  }
}));

function Row(props) {
  const { row } = props;
  const [historyinsideData, setHistoryInsideData] = React.useState([]);
  const getHistory = (row, forms) => {
    if (forms?.trade_in_application_id) {
      if (row?.change_history_id) {
        var tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/get-change-history-detail/`,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${tokenStr}`
            },
            body: JSON.stringify({ change_history_id: row?.change_history_id })
          })
          .then(async response => {
            const results = await response.json()
            if (response.status === 200) {
              setHistoryInsideData(results);
            } else if (response.status === 403) {
              localStorage.setItem('access_token', '')
              props.history.push('/');
            }
          })
      }
    }
  }
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const date = new Date(row.updated_date);
  const options = { weekday: 'long', year: 'numeric', month: 'full', day: 'numeric' };
  options.timeZone = 'UTC';
  options.timeZoneName = 'short';
  const [forms, setForms] = useContext(FormContext);

  return (
    <React.Fragment>
      <TableRow className={open === true ? 'table_border boder_bottom_0' : props.index % 2 === 1 ? 'rowcls' : ''}>
        <TableCell>
          {(row?.user_action === 'Updated The Application') ?
            <IconButton
              className={open === false ? "primaryColor" : ''}
              aria-label="expand row"
              disableripple
              size="small"
              onClick={function (event) {
                if (row?.user_action === 'Updated The Application') {
                  getHistory(row, forms);
                  setOpen(!open);
                }
              }
              }
            >
              {open ?
                <RemoveIcon /> : <AddSharpIcon />
              }
            </IconButton>
            : ''}
        </TableCell>
        <TableCell align="left">{row?.user_action}</TableCell>
        <TableCell align="left">{row?.updated_by}</TableCell>
        {open === true ?
          <TableCell style={{ borderRight: "4px solid #067de2" }} align="left">{row?.updated_date ? new Date(row.updated_date).toLocaleString('en-US', 'options', { hour12: false }) : 'NA'}</TableCell>
          :
          <TableCell align="left">{row?.updated_date ? new Date(row.updated_date).toLocaleString('en-US', 'options', { hour12: false }) : 'NA'}</TableCell>
        }
      </TableRow>

      <TableRow className={open === true ? 'table_border boder_top_0' : props.index % 2 === 1 ? 'rowcls' : ''}>
        <TableCell style={{ padding: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="boxsize">
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="left"><b>SECTION NAME</b></TableCell>
                    <TableCell align="left"><b>FIELDS</b></TableCell>
                    <TableCell align="left"><b>OLD</b></TableCell>
                    <TableCell align="left"><b>NEW</b></TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyinsideData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell />
                      <TableCell align="left" style={{ fontSize: "13px", opacity: '0.9' }}>{item.section_name}</TableCell>
                      <TableCell align="left" style={{ fontSize: "13px", opacity: '0.9' }}>{item.field ?? 'None'}</TableCell>
                      <TableCell align="left" style={{ fontSize: "13px", opacity: '0.9' }}>{item.old_value != null ? (item.field == "Rule Version" || item.field == "Guarantee Percentage" || item.field == "Email ID" || item.field == "Last Name" || item.field == "First Name" || item.field == "Calque Fee" || item.field == "DTI Calculation" || item.field == "Loan Type" || item.field == "Loan Purpose" || item.field == "Origination Date" || item.field == "Mortgage Payoff Date" || item.field == "Term Months" || item.field == "Purchase Date" || item.field == "Home Condition" || item.field == "Takeout Offer Percentage" || item.field == "Loan Program" || item.field == "Term Days" || item.field == "Purpose" || item.field == "Type" || item.field == "Mortgage Term Days" || item.field == "Mortgage Purpose" || item.field == "Mortgage Type" || item.field == "Maximum Funding Days" || item.field == "City" || item.field == "Owner Occupied" || item.field == "Street Address" || item.field == "State" || item.field == "County" || item.field == "Zipcode" || item.field == "Property Type" || item.field == "Valuation Source" || item.field == "Originator Name" || item.field == "Valuation Provider" || item.field == "Current LTV %" || item.field == "External Reference No" || item.field == "Veros AVM FSD Value" || item.field == "Veros AVM Confidence Score" || item.field == "Is Test App" || item.field == "Mortgage Title Cleared" || item.field == "Loan Officer Name" || item.field == "Property Type" || item.field == "Phone Number" || item.field == "Real Estate Agent Name" || item.field == "Mortgage Title Comments" || item.field == "Submitted To CRM" || item.field == "nutshell_lead_id" || item.field == "nutshell_lead_name" || item.field == "nutshell_contact_id") ? item.old_value : item.old_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'None'}</TableCell>
                      <TableCell align="left" style={{ fontSize: "13px", opacity: '0.9' }}>{item.new_value != null ? (item.field == "Rule Version" || item.field == "Guarantee Percentage" || item.field == "Email ID" || item.field == "Last Name" || item.field == "First Name" || item.field == "Calque Fee" || item.field == "DTI Calculation" || item.field == "Loan Type" || item.field == "Loan Purpose" || item.field == "Origination Date" || item.field == "Mortgage Payoff Date" || item.field == "Term Months" || item.field == "Purchase Date" || item.field == "Home Condition" || item.field == "Takeout Offer Percentage" || item.field == "Loan Program" || item.field == "Term Days" || item.field == "Purpose" || item.field == "Type" || item.field == "Mortgage Term Days" || item.field == "Mortgage Purpose" || item.field == "Mortgage Type" || item.field == "Maximum Funding Days" || item.field == "City" || item.field == "Owner Occupied" || item.field == "Street Address" || item.field == "State" || item.field == "County" || item.field == "Zipcode" || item.field == "Property Type || item.field == " || item.field == "Valuation Source" || item.field == "Originator Name" || item.field == "Valuation Provider" || item.field == "Current LTV %" || item.field == "External Reference No" || item.field == "Veros AVM Confidence Score" || item.field == "Veros AVM FSD Value" || item.field == "Is Test App" || item.field == "Mortgage Title Cleared" || item.field == "Loan Officer Name" || item.field == "Property Type" || item.field == "Phone Number" || item.field == "Real Estate Agent Name" || item.field == "Mortgage Title Comments" || item.field == "Submitted To CRM" || item.field == "nutshell_lead_id" || item.field == "nutshell_lead_name" || item.field == "nutshell_contact_id") ? item.new_value : item.new_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'None'}</TableCell>
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Viewhistory(props) {
  const [forms, setForms] = useContext(FormContext);
  const [historyData, setHistoryData] = useState([])

  useEffect(() => {
    setHistoryData(forms.change_history)
  }, [forms])

  return (
    <>
      <CommonHeader cxSpecialists={props.cxSpecialists} statusOffer={props.statusOffer} cxSpecialist={props.cxSpecialist} setCxSpecialist={props.setCxSpecialist}/>
      <Container className="History">
        <TableContainer className="table_layout_off" component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead className="head">
              <TableRow colSpan={12}>
                <TableCell align="left"></TableCell>
                <TableCell align="left" className="fontBold">User Action</TableCell>
                <TableCell align="left" className="fontBold">Updated by</TableCell>
                <TableCell align="left" className="fontBold">Date & Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table_border_body">
              {historyData.map((row, index) => (
                <Row key={row} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
