import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import baseUrl from '../../config/config';
import { useHistory } from "react-router-dom";
import '../../styles/Bgimages.css';
import '../../styles/style.css'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function Changepassword(props) {
	const [open, setOpen] = React.useState(false);
	const history = useHistory();
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [detail, setDetail] = React.useState('');
	const [severity_type, setSeverityType] = React.useState('');
	const vertical = 'top';
	const horizontal = 'center';
	const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
	const [showNewPassword, setShowNewPassword] = React.useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

	const handleKeyDown = e => {
		if (e.key === " ") {
		  e.preventDefault();
		}
	  };

	const handleClose = () => {
		props('false')
		setOpen(false);
	};
	const handleClickCurrent = () => {
		setShowCurrentPassword(prev => !prev);
	}
	const handleClickNew = () => {
		setShowNewPassword(prev => !prev);
	}
	const handleClickConfirm = () => {
		setShowConfirmPassword(prev => !prev);
	}
	const handleClick = () => {
		setOpenSnackbar(true);
	};
	const handleClose1 = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};
	const initialValues = {
		current_password: '',
		new_password: '',
		confirm_password: ''
	}

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	const onSubmit = values => {
		var tokenStr = localStorage.getItem('access_token')
		var user_id = localStorage.getItem('user_id')

		fetch(`${baseUrl.baseUrl}user/${user_id}/change-password/`, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				'Accept': 'application/json',
				"Authorization": `Bearer ${tokenStr}`
			},
			body: JSON.stringify({ current_password: values.current_password, new_password: values.new_password, confirm_password: values.confirm_password })
		})
			.then(async response => {
				const results = await response.json()
				if (response.status === 200) {
					setDetail(results.message);
					setSeverityType("success");
					setOpenSnackbar(true);
					await sleep(2000);
					localStorage.clear()

					history.push('./')
				}
				else {
					setDetail(results.detail)
					setSeverityType("error");
					setOpenSnackbar(true);
				}
			})
			.catch(console.log)
	}

	const validate = values => {
		let errors = {}

		if (!values.current_password) {
			errors.current_password = 'Please enter  current password'
		}
		if (!values.new_password) {
			errors.new_password = 'Please enter new password'
		} else if (values.new_password.length <= 6) {
			errors.new_password = "Must be more than 6 characters";
		}
		if (!values.confirm_password) {
			errors.confirm_password = 'Please enter confirm password'
		} else if (values.confirm_password.length <= 6) {
			errors.confirm_password = "Must be more than 6 characters";
		}
		return errors;
	}

	const formik = useFormik({
		initialValues,
		onSubmit,
		validate
	})

	return (
		<>
			<form autoComplete="off" className="color" onSubmit={formik.handleSubmit}>
				<TextField
					margin="normal"
					fullWidth
					id="current_password"
					name="current_password"
					type={showCurrentPassword ? "text" : "password"}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.current_password}
					autoComplete="off"
					placeholder="Current Password"
					style={{ color: 'black' }}
					className="showPsw"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LockOpenRoundedIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={handleClickCurrent}
									edge="end"
								>
									{showCurrentPassword ? <Visibility className="showPsw" /> : <VisibilityOff className="showPsw" />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
				{formik.touched.current_password && formik.errors.current_password ? <div className="error">{formik.errors.current_password}</div> : null}
				<TextField
					margin="normal"
					fullWidth
					id="new_password"
					name="new_password"
					type={showNewPassword ? "text" : "password"}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.new_password}
					autoComplete="off"
					onKeyDown={handleKeyDown}
					placeholder="New Password"
					style={{ color: 'black' }}
					className="showPsw"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LockOpenRoundedIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={handleClickNew}
									edge="end"
								>
									{showNewPassword ? <Visibility className="showPsw" /> : <VisibilityOff className="showPsw" />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
				{formik.touched.new_password && formik.errors.new_password ? <div className="error">{formik.errors.new_password}</div> : null}
				<TextField
					margin="normal"
					fullWidth
					id="confirm_password"
					name="confirm_password"
					type={showConfirmPassword ? "text" : "password"}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.confirm_password}
					autoComplete="off"
					onKeyDown={handleKeyDown}
					placeholder="Confirm Password"
					style={{ color: 'black' }}
					className="showPsw"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LockOpenRoundedIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={handleClickConfirm}
									edge="end"
								>
									{showConfirmPassword ? <Visibility className="showPsw" /> : <VisibilityOff className="showPsw" />}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
				{formik.touched.confirm_password && formik.errors.confirm_password ? <div className="error">{formik.errors.confirm_password}</div> : null}
				<Grid container spacing={2} className="form_layout_btn" style={{ justifyContent: 'center' }}>
					<Button
						className="primary_btn"
						type="submit"
						variant="contained"
						color="primary" >
						Submit
					</Button>
					<Button
						className="primary_btn_border"
						variant="outlined"
						color="primary"
						onClick={handleClose}
						style={{ background: '#fff' }}
					>
						Cancel
					</Button>
				</Grid>
			</form>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={10000}
				onClose={handleClose1}
				anchorOrigin={{ vertical, horizontal }}
			>
				<Alert onClose={handleClose1} severity={severity_type}>
					<div className="error" style={{ color: 'white' }}>
						<span>{detail}</span>
					</div>
				</Alert>
			</Snackbar>
		</>
	);
}