import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Container, Button, InputBase, Grid, MenuItem, Select } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Navbar from './../Dashboard/Navbar';
import baseUrl from '../../config/config';
import { NavLink } from "react-router-dom";
import '../../styles/style.css';
import '../../styles/Userlisting.css';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { FormContext, UserRoleContext } from '../../../context/FormContext';
import PageLoader from '../../views/commonViews/PageLoader';
import DeleteIcon from '@material-ui/icons/Delete';
import Modalpopup from '../commonViews/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DebouncedInput from '../commonViews/DebouncedInput';

var localTemp = [];

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 10;
var ITEM_MARGIN_TOP = 0;
if (window.innerWidth <= 320) {
    ITEM_MARGIN_TOP = 210;
} else if (window.innerWidth <= 375) {
    ITEM_MARGIN_TOP = 200;
} else if (window.innerWidth <= 425) {
    ITEM_MARGIN_TOP = 195;
} else if (window.innerWidth <= 768) {
    ITEM_MARGIN_TOP = 115;
} else {
    ITEM_MARGIN_TOP = 0;
}
const MenuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
            marginTop: ITEM_MARGIN_TOP
        },
    },
};

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontSize: "13px !important",
        minHeight: "1.2em",
        padding: "0px 0px 2px 10px",
        '&:hover': {
            backgroundColor: '#067de2 !important',
            color: '#fff !important',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: '#OOO !important',
                fontSize: '16px !important',
            },
        },
    },
    selected: {
        backgroundColor: '#ffff !important',
    }
}))(MenuItem);

function descendingComparator(a, b, orderBy) {
    if (orderBy.includes('.')) {
        let nestedOrderBy = orderBy.split('.')
        let nestedBy1 = nestedOrderBy[0]
        let nestedBy2 = nestedOrderBy[1] && nestedOrderBy[1]
        if (b[nestedBy1][nestedBy2].toLowerCase() > a[nestedBy1][nestedBy2].toLowerCase()) {
            return 1;
        }
        if (b[nestedBy1][nestedBy2].toLowerCase() < a[nestedBy1][nestedBy2].toLowerCase()) {
            return -1;
        }
        else if (a[nestedBy1][nestedBy2].toLowerCase() === b[nestedBy1][nestedBy2].toLowerCase()) {
            return 0;
        }
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
    }
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
    }
    else if (a[orderBy].toLowerCase() === b[orderBy].toLowerCase()) {
        return 0;
    }
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null) {
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase())
                    .includes(filter.value.toString().toLowerCase())
                :
                true
        );
    }
}
const headCells = [
    { id: 'trade_in_application_number', numeric: false, disablePadding: true, label: 'Trade in Application Number' },
    { id: 'originator_name.partner_name', numeric: true, disablePadding: false, label: 'Originator Name' },
    { id: 'borrower.name', numeric: true, disablePadding: false, label: 'Borrower Name' },
    // { id: 'borrower.email', numeric: true, disablePadding: false, label: 'Email ID ' },
    { id: 'property_address', numeric: true, disablePadding: false, label: 'Property Address' },
    { id: 'created_by', numeric: true, disablePadding: false, label: 'Created By ' },
    { id: 'created_date', numeric: true, disablePadding: false, label: 'Created Date' },
    { id: 'updated_date', numeric: true, disablePadding: false, label: 'Updated Date' },
    { id: 'cx_specialist', numeric: true, disablePadding: false, label: 'CX Specialist' },
    { id: 'app_status', numeric: true, disablePadding: false, label: 'Status' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell align="left" padding="default" style={{ paddingRight: '1rem' }}>Delete</TableCell>
                {headCells.map((headCell) => (
                    <>
                        <TableCell
                            key={headCell.id}
                            align="left"
                            padding="default"
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                             {headCell.id === "updated_by" ? null : headCell.label}
                            {/* <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.id === "updated_by" ? null : headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel> */}
                        </TableCell>
                    </>
                ))}
                <TableCell align="left" padding="default">Test Application</TableCell>
                <TableCell align="left" padding="default">Submitted to CRM</TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    return (
        <div></div>
    );
};
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    table: {
        minWidth: 750,
        borderRadius: '3px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    search: {
        position: 'relative',
        border: '0.5px solid #707070',
        borderRadius: theme.shape.borderRadius,
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(0),
            width: '100%',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 0,
    },
    inputRoot: {
        color: '#000',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        color: '#000 !important',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        paddingLeft: '20px',
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    selectMenu: {
        "& .MuiSelect-root": {
            color: "#000 !important",
            fontSize: "13px !important",
            width: "200px !important",
            padding: "4px 4px !important",
            paddingTop: "3px !important",
            paddingLeft: "10px !important",
            borderRadius: "5px !important",
            border: "0.5px solid #000 !important",
        }
    }
}));

export default function Applicationlisting(props) {
    const [masterCheck, setMasterCheck] = React.useState([])
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('trade_in_application_number');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);
    const [tblErr, setTblErr] = useState(false);
    const [forms, setForms] = useContext(FormContext);
    const [role,setRole] = useContext(UserRoleContext);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [severity_type, setSeverityType] = React.useState('');
    const vertical = 'top';
    const horizontal = 'center';

    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.email);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleDebouncedChange = (value) => {
        setSearchTerm(value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const history = useHistory();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    var userDataToMap = filteredData.length === 0 ? data : filteredData;
    const [searchTerm, setSearchTerm] = React.useState("");
    const [allStatus, setAllStatus] = useState([]);
    const [appStatus, setAppStatus] = useState('all');
    const [appFilter, setAppFilter] = useState([]);
    const [open, setOpen] = useState(false);
    const [detail, setDetail] = React.useState('');
    const [originatorNames, setOriginatorNames] = useState([]);
    const [originatorValue, setOriginatorValue] = useState('all');
    const [cxSpecialistValue, setCxSpecialistValue] = useState('all');
    const [cxSpecialists, setCxSpecialists] = useState([]);
    const [isApplicationManager,setIsApplicationManager] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [newData, setNewData] = useState([]);
    const [totalRecords, setTotalRecords] = useState();
    const [message,setMessage] = useState();
    const pageSize = 8;
    // let totalRecords = 125;

    useEffect(()=>{
        let userInfo = localStorage.getItem('user_info')
        let parseInfo = JSON.parse(userInfo);
        let isManager;
        if(role !== null){
            if(parseInfo.id === role.user_info.id){
                isManager = role.user_info.groups.some(group => group.name === "Application Manager");
            }
        }else{
            isManager = parseInfo.groups.some(group => group.name === "Application Manager");
        }

        setIsApplicationManager(isManager);
    },[])

    useEffect(() => {
        fetchData();
    }, [currentPage,originatorValue,appStatus,searchTerm,cxSpecialistValue]);

    const fetchData = async () => {
        var tokenStr = localStorage.getItem('access_token')
        let url = `${baseUrl.baseUrl}trade-in-application/?page=${currentPage}`

        if (searchTerm.trim() !== '') {
            url += `&search=${searchTerm}`;
        }

        if (originatorValue !== 'all') {
            url += `&originator_name_id=${originatorValue}`;
        }

        if (appStatus !== 'all') {
            url += `&application_status_id=${appStatus}`;
        }

        if (cxSpecialistValue !== 'all') {
            url += `&cx_specialist_id=${cxSpecialistValue}`;
        }

        fetch(url,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if ((response.status === 201 && result) || (response.status === 200 && result)) {
                    setNewData(result.results);
                    setTotalRecords(result.count);
                    // setTotalPages(Math.ceil(result.count / pageSize));
                    setTotalPages(Math.ceil(result.count / pageSize));
                    if (result.count === 0 ){
                        setMessage("No Records Found");
                    }
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                }
            }).catch((e) => {
                console.log(e)
            });
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };
    
    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    
    const renderPaginationButtons = () => {
        let buttons = [];
    
        // Add pages before the current page
        for (let i = Math.max(currentPage - 3, 1); i < currentPage; i++) {
          buttons.push(
            <span key={i} className="pagination-span" onClick={() => goToPage(i)}>
              {i}
            </span>
          );
        }
    
        // Add current page
        buttons.push(
          <span key={currentPage} className="pagination-span" style={{ color:"red" }} onClick={() => goToPage(currentPage)}>
            {currentPage}
          </span>
        );
    
        // Add pages after the current page
        if(totalPages > 1){
            for (let i = currentPage + 1; i <= Math.min(currentPage + 3, totalPages); i++) {
              buttons.push(
                <span key={i} className="pagination-span" onClick={() => goToPage(i)}>
                  {i}
                </span>
              );
            }
        }
    
        return buttons;
      };

    useEffect(() => {
        localTemp = [];
        var tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}get-app-status-list/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setAllStatus(results)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                }
            }
            ).catch((e) => {
                console.log(e)
            });

        fetch(`${baseUrl.baseUrlCp}partner/`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setOriginatorNames(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                }
            })

            fetch(`${baseUrl.baseUrl}get-cx-specialists/`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 200 || response.status === 201) {
                    setCxSpecialists(result)
                }
                else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
    }, [])

    // useEffect(() => {
    //     handleEmptyFilterData()
    // }, [appStatus, originatorValue]);

    const handleEmptyFilterData = () => {
        if ((!appStatus || appStatus === 'all') && (!originatorValue || originatorValue === '')) {
            setData(appFilter)
            setFilteredData(appFilter)
        } else if ((!appStatus || appStatus === 'all') && originatorValue) {
            let value = appFilter.filter(a => a.originator_name && a.originator_name.partner_id === Number(originatorValue));
            setData(value)
            setFilteredData(value)
        } else if (appStatus && (!originatorValue || originatorValue === '')) {
            let value = appFilter.filter(a => a.app_status && a.app_status === appStatus);
            setData(value)
            setFilteredData(value)
        } else if ((appStatus && appStatus !== 'all') && (originatorValue && originatorValue !== '')) {
            let value = appFilter.filter(a => a.app_status && a.app_status === appStatus && a.originator_name && a.originator_name.partner_id === Number(originatorValue));
            setData(value)
            setFilteredData(value)
        }
        handleAppStatus(appStatus);
        handleAppOriginator(originatorValue)
    }

    const handleAppStatus = (event) => {
        setAppStatus(event)
        setCurrentPage(1);
    }

    const handleAppOriginator = (event) => {
        setOriginatorValue(event)
        setCurrentPage(1);
    }
    const handleCxSpecialists = (event) => {
        setCxSpecialistValue(event)
        setCurrentPage(1);
    }

    // useEffect(() => {
    //     var tokenStr = localStorage.getItem('access_token')
    //     fetch(`${baseUrl.baseUrl}trade-in-application/`,
    //         {
    //             headers: { "Authorization": `Bearer ${tokenStr}` }
    //         })
    //         .then(async response => {
    //             const result = await response.json()
    //             if ((response.status === 201 && result) || (response.status === 200 && result)) {
    //                 setAppFilter(result)
    //                 handleEmptyFilterData()
    //                 setData(result)
    //             } else if (response.status === 403) {
    //                 localStorage.setItem('access_token', '')
    //                 history.push('/');
    //             }
    //         }).catch((e) => {
    //             console.log(e)
    //         });
    // }, []);

    // useEffect(() => {
    //     if (localTemp.length !== 0) {
    //         var tokenStr = localStorage.getItem('access_token')
    //         fetch(`${baseUrl.baseUrl}trade-in-application/`,
    //             {
    //                 headers: { "Authorization": `Bearer ${tokenStr}` }
    //             })
    //             .then(async response => {
    //                 const result = await response.json()
    //                 if ((response.status === 201 && result) || (response.status === 200 && result)) {
    //                     setAppFilter(result)
    //                     handleEmptyFilterData()
    //                     setData(result)
    //                 } else if (response.status === 403) {
    //                     localStorage.setItem('access_token', '')
    //                     history.push('/');
    //                 }
    //             }).catch((e) => {
    //                 console.log(e)
    //             });
    //     }
    // }, [localTemp])

    RegExp.escape = function (s) {
        if (s) {
            return s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        }
    };
    const handleChange = event => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (!searchTerm) {
            setFilteredData([])
            return
        }

        const results = data.filter(item => {
            const query = searchTerm.toLowerCase();
            return (
                item.trade_in_application_number.toLowerCase().indexOf(query) >= 0 ||
                item.borrower?.name.toLowerCase().indexOf(query) >= 0 ||
                item.borrower?.email.toLowerCase().indexOf(query) >= 0 ||
                item.property_address.toLowerCase().indexOf(query) >= 0
            )
        });
        setFilteredData(results);
        setTblErr(
            !searchTerm === results
        )
    }, [searchTerm]);

    useEffect(() => {
        if (props.history.location.pathname === "/application-listing") {
            localStorage.setItem('originator_id', null)
        }
    })

    const handleFormDefault = () => {
        localStorage.removeItem('application_id');
        localStorage.setItem('LoName', null)
        localStorage.setItem('Lofn', null)
        localStorage.setItem('Loln', null)
        localStorage.setItem('Lopn', null)
        localStorage.setItem('Loextn', null)
        localStorage.setItem('Loemail', null)
        localStorage.setItem('RsaName', null)
        localStorage.setItem('Rsafn', null)
        localStorage.setItem('Rsaln', null)
        localStorage.setItem('Rsapn', null)
        localStorage.setItem('Rsaextn', null)
        localStorage.setItem('Rsaemail', null)
        setForms();
    }

    const handleCreate = () => {
        localStorage.setItem('isCreate',true);
    }

    const [loaderStatus, setLoderStatus] = useState(false);

    const onMasterCheck = (e, id, value) => {
        // if (value.is_test_app === true) {
            if (e.target.checked === true) {
                localTemp.push(id)
                var duplicate = [...localTemp]
                setMasterCheck(duplicate)
            } else {
                for (let i = 0; i < localTemp.length; i++) {
                    if (id === localTemp[i]) {
                        localTemp.splice(i, 1)
                    }
                }
                var duplicate2 = [...localTemp]
                setMasterCheck(duplicate2)
            }
        // }
    }

    const handlePopUp = () => {
        if (masterCheck.length !== 0) {
            setOpen(!open);
        }
    };

    const handleClose = () => {
        setOpen(false)
    }

    const handleDelete = () => {
        let tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}trade-in-application/delete-application/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ trade_in_application_id: masterCheck })
        }).then(async response => {
            const results = await response.json()
            if (response.status === 200 || response.status === 201) {
                setDetail(results.message);
                setSeverityType("success");
                setOpenSnackbar(true);
                await sleep(1000);
                setOpenSnackbar(false);
                handleClose();
                window.location.reload()
            }
            else {
                setDetail(results.detail)
                setSeverityType("error");
                setOpenSnackbar(true);
                handleClose()
                window.location.reload()
            }
        }).catch((error) => {
            console.error(error)
        })
    }

    function deleteApplication() {
        return (
            <div>
                <h1>Are you sure you want to delete?</h1>
                <div className="d-flex">
                    <div>
                        <Button
                            className="primary_btn"
                            style={{ color: '#fff' }}
                            onClick={handleDelete}
                        >
                            Ok
                        </Button>
                    </div>
                    <div>
                        <Button
                            className="primary_btn"
                            style={{ color: '#fff' }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    function rowClickHandle(id) {
        setLoderStatus(!loaderStatus)
        localStorage.setItem('LoName', null)
        localStorage.setItem('Lofn', null)
        localStorage.setItem('Loln', null)
        localStorage.setItem('Lopn', null)
        localStorage.setItem('Loextn', null)
        localStorage.setItem('Loemail', null)
        localStorage.setItem('RsaName', null)
        localStorage.setItem('Rsafn', null)
        localStorage.setItem('Rsaln', null)
        localStorage.setItem('Rsapn', null)
        localStorage.setItem('Rsaextn', null)
        localStorage.setItem('Rsaemail', null)
        var tokenStr = localStorage.getItem('access_token');
        fetch(`${baseUrl.baseUrl}trade-in-application/${id}/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const data = await response.json();
                if (response.status === 200 || response.status === 201) {
                    setForms(data)
                    history.push({
                        pathname: '/application-create',
                        state: { rdata: data }
                    });
                    setLoderStatus(!loaderStatus)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                }
            })
            .then((err) => {
                console.error(err);
            })
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const IsChecked = (id) => {
        if (!localTemp || localTemp.length === 0) return false;
        let isCkd = localTemp ? localTemp?.find((item) => item === id) : false;
        return isCkd
    }

    return (
        <>
            <Navbar />
            <div className="application">
                <div className="View">
                    <div className={classes.root}>
                        {loaderStatus === true ?
                            <PageLoader />
                            :
                            (
                                <Container className={classes.paper}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                        <div>
                                            <NavLink style={{ textDecoration: 'none', color: 'white', }} exact to="/application-create" onClick={handleFormDefault}>
                                                <Button
                                                    className="primary_btn"
                                                    style={{ color: '#fff' }}
                                                    onClick={handleCreate}
                                                >
                                                    <EditOutlinedIcon style={{ color: '#fff', marginRight: '5px', fontSize: '16px' }} />
                                                    Create Application
                                                </Button>
                                            </NavLink>
                                        </div>
                                        {masterCheck.length !== 0 ? (<div>
                                            <Button
                                                className="primary_btn"
                                                style={{ color: '#fff' }}
                                                onClick={handlePopUp}
                                            >
                                                <DeleteIcon style={{ color: '#fff', marginRight: '5px', fontSize: '14px' }} />
                                                Delete
                                            </Button>
                                        </div>) : (
                                            <Button
                                                className="primary_btn_disabled"
                                                disabled
                                                variant="outlined"
                                            >
                                                <DeleteIcon id='deleteIconBtn' />

                                                <span className='deleteText'>Delete</span>
                                            </Button>)}
                                    </div>
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems:"center" }}>
                                        <Grid item xs={12} sm={12} md={6} lg={4}>
                                            <div className={classes.search}>
                                                <div className={classes.searchIcon}>
                                                    <SearchIcon />
                                                </div>
                                                <DebouncedInput onDebouncedChange={handleDebouncedChange} />
                                            </div>
                                        </Grid>
                                        <Grid className="app_select" style={{ maxWidth:"none" }} item xs={12} sm={6} md={5} lg={2}>
                                            <label>CX Specialist</label>
                                            <div className="views-widget1">
                                                <Select
                                                    value={cxSpecialistValue}
                                                    className={classes.selectMenu}
                                                    onChange={(e) => handleCxSpecialists(e.target.value)}
                                                    MenuProps={MenuProps}
                                                    style={{ width: '175px' }}
                                                    id="statusSelect"
                                                >
                                                    <StyledMenuItem value={'all'}>All</StyledMenuItem>
                                                    {
                                                        cxSpecialists.map((item, index) => {
                                                            return (
                                                                <StyledMenuItem key={item.id} value={item.id} style={{ width: "208px", overflow: "visible", whiteSpace: "initial" }} >{item.get_user_full_name}</StyledMenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </Grid>
                                        <Grid className="app_select" style={{ maxWidth:"none" }} item xs={12} sm={6} md={5} lg={2}>
                                            <label>Originator Name</label>
                                            <div className="views-widget1">
                                                <Select
                                                    value={originatorValue}
                                                    className={classes.selectMenu}
                                                    MenuProps={MenuProps}
                                                    onChange={(e) => handleAppOriginator(e.target.value)}
                                                    style={{ width: '175px'}}
                                                    id="originatorSelect"
                                                >
                                                    <StyledMenuItem value={'all'}>All</StyledMenuItem>
                                                    {
                                                        originatorNames && originatorNames.map((item, index) => {
                                                            return (
                                                                <StyledMenuItem key={index} value={item.partner_id} style={{ width: "208px", overflow: "visible", whiteSpace: "initial" }}>{item.partner_name}</StyledMenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </Grid>
                                        <Grid className="app_select" style={{ maxWidth:"none" }} item xs={12} sm={6} md={5} lg={2}>
                                            <label>Status</label>
                                            <div className="views-widget1">
                                                <Select
                                                    value={appStatus}
                                                    className={classes.selectMenu}
                                                    onChange={(e) => handleAppStatus(e.target.value)}
                                                    MenuProps={MenuProps}
                                                    style={{ width: '175px' }}
                                                    id="statusSelect"
                                                >
                                                    <StyledMenuItem value={'all'}>All</StyledMenuItem>
                                                    {
                                                        allStatus.map((item, index) => {
                                                            return (
                                                                <StyledMenuItem key={item.application_status_id} value={item.application_status_id} style={{ width: "208px", overflow: "visible", whiteSpace: "initial" }} >{item.application_status}</StyledMenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <EnhancedTableToolbar numSelected={selected.length} defaultFilterMethod={filterCaseInsensitive} />
                                    <TableContainer>
                                        <Table
                                            className={`${classes.table} table`}
                                            aria-labelledby="tableTitle"
                                            size="small"
                                            aria-label="enhanced table"
                                            defaultFilterMethod={filterCaseInsensitive}
                                        >
                                            <EnhancedTableHead
                                                classes={classes}
                                                numSelected={selected.length}
                                                order={order}
                                                orderBy={orderBy}
                                                onSelectAllClick={handleSelectAllClick}
                                                onRequestSort={handleRequestSort}
                                                rowCount={data.length}
                                                defaultFilterMethod={filterCaseInsensitive}
                                            />
                                            {(tblErr === false) ?
                                                (<TableBody>
                                                    {
                                                        // stableSort(userDataToMap, getComparator(order, orderBy))
                                                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        newData.map((row) => {
                                                            const options = { weekday: 'long', year: 'numeric', month: 'full', day: 'numeric' };
                                                            options.timeZone = 'UTC';
                                                            options.timeZoneName = 'short';
                                                            return (
                                                                <TableRow key={row.trade_in_application_number}>
                                                                    <TableCell align="right">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="del_checkbox"
                                                                            disabled={isApplicationManager === true ? false : row?.is_test_app === true ? false : true}
                                                                            style={{ marginLeft: '1rem' }}
                                                                            defaultChecked={masterCheck.length === 0 ? null : IsChecked(row.trade_in_application_id)}
                                                                            key={row.trade_in_application_id}
                                                                            onClick={(e) => onMasterCheck(e, row.trade_in_application_id, row)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="left" onClick={() => { rowClickHandle(row.trade_in_application_id) }} className="app_link">{row.trade_in_application_number}</TableCell>
                                                                    <TableCell align="left">{row.originator_name?.partner_name}</TableCell>
                                                                    <TableCell align="left">{row.borrower?.name}</TableCell>
                                                                    {/* <TableCell align="left" id="app_email_listing" title={row.borrower?.email}>{row.borrower?.email}</TableCell> */}
                                                                    <TableCell align="left" id="property_address" title={row.property_address}>{row.property_address}</TableCell>
                                                                    <TableCell align="left">{row.created_by}</TableCell>
                                                                    <TableCell align="right">{row.created_date ? new Date(row.created_date).toLocaleDateString('en-US', 'options', { hour12: false }) : 'NA'}</TableCell>
                                                                    <TableCell align="right">{row.updated_date ? new Date(row.updated_date).toLocaleDateString('en-US', 'options', { hour12: false }) : 'NA'}</TableCell>
                                                                    <TableCell style={row?.cx_specialist === null ? {textAlign:"center"}:{}} align="left">{row?.cx_specialist === null ? "-" : row?.cx_specialist?.get_user_full_name}</TableCell>
                                                                    <TableCell align="left">{row.app_status}</TableCell>
                                                                    <TableCell align="center" id="app_test">{row.is_test_app === false ? 'No' : 'Yes'}</TableCell>
                                                                    <TableCell align="center" id="app_test">{row.submitted_to_crm === false ? 'No' : 'Yes'}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>) :
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell colSpan={2}><span style={{ color: 'black' }}>Application is Not Found</span></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            }
                                        </Table>
                                    </TableContainer>
                                    
                                    {totalRecords !== 0 ?
                                    <><div style={{ marginBottom:"5px", position:"relative", display:"flex" }}>
                                      <ArrowBackIosIcon className={`prev ${currentPage !== 1 ? 'next-transition' : ''}`} onClick={goToPreviousPage} disabled={currentPage === 1} />
                                      <div>
                                        {renderPaginationButtons()}
                                      </div>
                                    {currentPage + 3 < totalPages &&
                                    <> 
                                        <span style={{ fontWeight:"600", padding:"2px", marginRight:"9px"}}>...</span> 
                                    </>}
                                      <ArrowForwardIosIcon className={`next ${currentPage < totalPages ? 'next-transition' : ''}`} onClick={goToNextPage} />
                                    </div>
                                    <span style={{ marginBottom:"15px", fontWeight:"600", padding:"2px"}}>{(currentPage * 8 )- 7 === totalRecords ? totalRecords : (currentPage * 8) - 7 + "-" + (currentPage * 8 > totalRecords ? totalRecords : currentPage * 8)} of {totalRecords}</span>
                                    </>:
                                    <span>{message}</span>}
                                    {/* <TablePagination
                                        className="custom_pagination"
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={filteredData.length > 0 ? filteredData?.length : data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    /> */}
                                </Container>
                            )
                        }
                    </div>
                </div>
            </div>
            <Modalpopup open={open} handleClickOpen={handlePopUp} deactiveUser={deleteApplication()} header="Delete Applications" />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleClose1}
                anchorOrigin={{ vertical, horizontal }} >
                <Alert onClose={handleClose1} severity={severity_type}>
                    <div className="error" style={{ color: 'white' }}> <span>{detail}</span></div>
                </Alert>
            </Snackbar>
        </>
    );
}