import React from 'react';
import { BrowserRouter as Router, Route,Redirect, Switch } from 'react-router-dom';
import Login from './components/views/commonViews/Login';
import Bgimages from './components/views/commonViews/Bgimages';
import Forgotpassword from './components/views/commonViews/Forgotpassword';
import Resetpassword from './components/views/commonViews/Resetpassword';
import Changepassword from './components/views/commonViews/Changepassword';
import Navbar from './components/views/Dashboard/Navbar';
import Applicationlisting from './components/views/Dashboard/Applicationlisting';
import Mgptab from './components/views/Dashboard/Mgptab';
import Tradeinloan from './components/views/Dashboard/Tradeinloan';
import Newhometab from './components/views/Dashboard/Newhometab';
import Offerdetails from './components/views/Dashboard/Offerdetails';
import Reviewmodal from './components/views/Dashboard/Reviewmodal';
import Applicationcreates from './components/views/Dashboard/Applicationcreates';
import Viewhistory from './components/views/Dashboard/Viewhistory';
import Userlisting from './components/views/Dashboard/Userlisting';
import User from './components/views/Dashboard/User';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export default function RouterComponent() {
    return (
        <Router>
            <Switch>
                <PublicRoute exact path="/" component={Login} />
                <PrivateRoute exact path="/navbar" component={Navbar} />
                <PublicRoute exact path="/bgimages" component={Bgimages} />
                <PrivateRoute exact path="/application-listing" component={Applicationlisting} />
                <PrivateRoute exact path="/application-create" component={Applicationcreates} />
                <PrivateRoute exact path="/view-history" component={Viewhistory} />
                <PrivateRoute exact path="/user-listing" component={Userlisting} />
                <PrivateRoute exact path="/review-modal" component={Reviewmodal} />
                <PrivateRoute exact path="/user" component={User} />
                <PrivateRoute exact path="/mgp-tab" component={Mgptab} />
                <PrivateRoute exact path="/Newhometab" component={Newhometab} />
                <PrivateRoute exact path="/offer-details" component={Offerdetails} />
                <PrivateRoute exact path="/trade-in-loan" component={Tradeinloan} />
                <PublicRoute exact path="/reset-password" component={Resetpassword} />
                <PrivateRoute exact path="/change-password" component={Changepassword} />
                <PublicRoute exact path="/forgot-password" component={Forgotpassword} />
                 <Route  render={() => <Redirect to="/" />} />
            </Switch>
        </Router>

    )
}
