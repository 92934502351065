import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import baseUrl from '../../config/config';
import '../../styles/Bgimages.css';
import '../../styles/style.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/login">
						<CloseIcon /></NavLink>
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function Forgotpassword() {
	const [open, setOpen] = React.useState(true);
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const history = useHistory();
	const [detail, setDetail] = React.useState('');
	const [severity_type, setSeverityType] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);
	const vertical = 'top';
	const horizontal = 'center';
	const handleClose = () => {
		setOpen(false);
	};
	const handleClose1 = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};
	const handleEnd = (event, reason) => {
		localStorage.clear()
		history.push('/');
	}
	const initialValues = {
		email: ''
	}
	const onSubmit = values => {
		setIsLoading(true);
		fetch(`${baseUrl.baseUrl}email-forgot-password/`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ email: values.email })
		})
			.then(async response => {
				const results = await response.json()
				if (response.status === 200) {
					setDetail(results.message);
					setSeverityType("success");
					setOpenSnackbar(true);
					await sleep(2000);
					setIsLoading(false);
					history.push('./')
				}
				else {
					setDetail(results.detail)
					setSeverityType("error");
					setOpenSnackbar(true);
					setIsLoading(false);
				}
			})
	}

	const validate = values => {
		let errors = {}

		if (!values.email && values.email.trim()) {
			errors.email = 'Please enter correct email'

		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'Invalid email id address';
		}
		return errors;
	}

	const handleKeyDown = e => {
		if (e.key === " ") {
		  e.preventDefault();
		}
	  };

	const formik = useFormik({
		initialValues,
		onSubmit,
		validate
	})

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}

	return (
		<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" disableBackdropClick={true} open={open} className="bgimage" style={{ backgroundImage: 'url("/assets/images/loginbg.png")' }}>
			<DialogTitle className="modal_header" id="customized-dialog-title" onClose={handleClose}>
				Forgot Password
			</DialogTitle>
			<DialogContent dividers className="modal_body">
				<Typography variant="h6" gutterBottom className="ForgotBold" style={{ fontSize: '14px', fontFamily: 'PoppinsRegualar', color: 'black' }}>
					<form autoComplete="off" className="color" onSubmit={formik.handleSubmit}>
						<TextField
							margin="normal"
							fullWidth
							id="email"
							name="email"
							type="email"
							onKeyDown={handleKeyDown}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.email && formik.values.email.trimStart()}
							autoComplete="off"
							placeholder="Email"
							style={{ color: 'black' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockOpenRoundedIcon />
									</InputAdornment>
								),
							}}
						/>
						{formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}

						<Grid container spacing={2} className="form_layout_btn" style={{ justifyContent: 'center' }}>
							<Button
								className="primary_btn"
								type="submit"
								variant="contained"
								disabled={isLoading === true}
								color="primary">
							{isLoading === false ? 'Submit' : <CircularProgress className='circular_spiner' size={24} color="white" />}
							</Button>
							<NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/login">
								<Button
									className="primary_btn_border"
									variant="outlined"
									color="primary"
									style={{ background: '#fff' }}>
									Cancel
								</Button>
							</NavLink>
						</Grid>
					</form>
				</Typography>
			</DialogContent>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleClose1}
				onEnded={handleEnd}
				anchorOrigin={{ vertical, horizontal }} >
				<Alert onClose={handleClose1} severity={severity_type}>
					<div className="error" style={{ color: 'white' }}> <span>{detail}</span></div>
				</Alert>
			</Snackbar>
		</Dialog>
	);
}
