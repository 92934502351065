import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Modalpopup from './../commonViews/Modal';
import Container from '@material-ui/core/Container';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { NavLink } from "react-router-dom";
import baseUrl from '../../config/config';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import '../../styles/Bgimages.css';
import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../styles/styleNew.css';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(10),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '30px !important',

    }
}));

export default function Bgimages(props) {
    const classes = useStyles();
    const history = useHistory();
    const [non_field_errors, setNonFeildErrors] = useState('');
    const [open, setOpen] = React.useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const initialValues = {
        username: '',
        password: '',
    }
    const handleClickOpen = () => {
        setOpen(!open);
    };
    const onSubmit = values => {
        setIsLoading(true)
        fetch(`${baseUrl.baseUrl}login/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: values.username, password: values.password })
        }).then((res) => res.json()).then((results) => {
            if (results.non_field_errors) {
                setNonFeildErrors(results.non_field_errors[0])
                setIsLoading(false)
            } else if (results) {
                localStorage.setItem('access_token', results.access_token)
                localStorage.setItem('user_id', results.user_info.id)
                localStorage.setItem('user_info', JSON.stringify(results.user_info));
                setIsLoading(false);
                history.push('./application-listing')
            } else if (!results) {
                localStorage.setItem('access_token', results.access_token)
                localStorage.setItem('user_id', results.user_info.id)
                setIsLoading(false)
                history.push('/');
            }
        })
    }
    const validate = values => {
        let errors = {}

        if (!values.username || values.username.trim()==='') {
            errors.username = 'Please enter  username'
        }
        if (!values.password || values.password.trim()==='') {
            errors.password = "Please enter  password";
        } else if (values.password.length <= 6) {
            errors.password = "Must be more than 6 characters";
        }
        return errors;
    }
    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })
    const handleClick = () => {
        setShowPassword(prev => !prev);
    }

    function forgotpassword() {
        return (
            <>
                <Typography gutterBottom className="forgotBold" >
                    Please Enter Your Email
                </Typography>
                <Typography variant="h6" gutterBottom className="ForgotBold" style={{ fontSize: '14px', fontFamily: 'PoppinsRegualar', color: 'black' }}>
                    <form autoComplete="off" className="color" >
                        <TextField className={classes.textFiled}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            type="text"
                            autoFocus
                            autoComplete="off"
                            placeholder="Email Id"
                            style={{ color: 'black' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MailOutlineIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                    </form>
                </Typography>

                <Grid container spacing={2} className="form_layout_btn" style={{ justifyContent: 'center' }}>
                    <Button
                        className="primary_btn"
                        variant="contained"
                        type="submit"
                        color="primary" >
                        Submit
                    </Button>
                    <Button
                        className="primary_btn_border"
                        variant="outlined"
                        color="primary"
                        onClick={handleClickOpen}
                        style={{ background: '#fff' }}>
                        Cancel
                    </Button>
                </Grid>
            </>
        );
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} autoComplete="off" onSubmit={formik.handleSubmit}
                >
                    <TextField className={classes.textFiled}
                        margin="normal"
                        fullWidth
                        id="username"
                        name="username"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.username && formik.values.username.trimStart().trimEnd()}
                        autoComplete="off"
                        placeholder="Username"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src="/assets\images\user_24px.png" alt="image" className="h-200vh w-100"></img>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {formik.touched.username && formik.errors.username ? <div className="error">{formik.errors.username}</div> : null}

                    <TextField className={`${classes.textFiled} margin_top`}
                        fullWidth
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password && formik.values.password.trimStart().trimEnd()}
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src="/assets\images\unlock_24px.png" alt="image" className="h-200vh w-100"></img>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClick}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility className="show" /> : <VisibilityOff className="show" />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {formik.touched.password && formik.errors.password ? <div className="error">{formik.errors.password}</div> : null}

                    <div className="forgot_title">
                        <Typography>
                            <NavLink to="/forgot-password" onClick={handleClickOpen} style={{ textDecoration: 'none', color: 'white' }}>
                                <span>Forgot Password ?</span>
                            </NavLink>
                        </Typography>
                    </div>
                    <div className="error">
                        <span>{non_field_errors}</span>
                    </div>
                    <div className="d-flex justify-center align-center">
                        <Button className="size"
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={isLoading === true}
                            color="inherit"
                            style={{backgroundColor:'#067de2',color:'white'}}
                            className={classes.submit}
                        >
                            Login
                        </Button>
                    </div>
                </form>
                <Modalpopup
                    open={open}
                    handleClickOpen={handleClickOpen}
                    deactiveUser={forgotpassword()}
                    header="Forgot Password"
                />
            </div>
        </Container>
    );
}