import React, { useState, useContext } from "react";
import { Container, Grid, Select, Typography, Button, TextField } from "@material-ui/core";
import '../../styles/styleNew.css';
import Navbar from './Navbar';
import { NavLink } from "react-router-dom";
import baseUrl from '../../config/config';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react'
import axios from "axios";
import { useFormik } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Modalpopup from '../commonViews/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserRoleContext } from "../../../context/FormContext";

export default function User(props) {
    const [open, setOpen] = React.useState(false);
    const [userData, setUserData] = React.useState([]);
    const [showActbtn, setshowActbtn] = useState(false);
    const [role,setRole] = useContext(UserRoleContext);
    const handleClickOpen = () => {
        setOpen(!open);
    };
    const history = useHistory();

    var tokenStr = localStorage.getItem('access_token')

    const initialValues = {
        id: '',
        email: '',
        username: '',
        last_name: '',
        first_name: '',
        groups: [],
        pType: '',
        is_locked: '',
        is_active: ''
    }
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [detail, setDetail] = React.useState('');
    const [severity_type, setSeverityType] = React.useState('');
    const [postType, setPostType] = React.useState('create');
    const [isLoading,setIsLoading]  = React.useState(false);
    const [loader,setLoader] = React.useState(false);
    const vertical = 'top';
    const horizontal = 'center';
    const [items, setItems] = React.useState([]);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    useEffect(() => {
        var tempGids = []
        if (props && props.history && props.history.location && props.history.location.state) {
            var udata = props.history.location.state
            if (udata && udata.userData) {
                initialValues.id = udata.userData.id
                initialValues.pType = udata.userData.pType
                initialValues.first_name = udata.userData.first_name
                initialValues.last_name = udata.userData.last_name
                udata.userData.groups.forEach(element => {
                    tempGids.push(element.name)
                });
                initialValues.groups = tempGids
                initialValues.email = udata.userData.email
                initialValues.username = udata.userData.username
                initialValues.is_active = udata.userData.is_active
                initialValues.is_locked = udata.userData.is_locked
                setshowActbtn(!udata.userData.is_active)
                if (udata.postType) {
                    setPostType(udata.postType)
                }
            }
        }
    }, [props])
    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const onSubmit = values => {
        setUserData(values)
        var tempGids = []
        values.groups.forEach(element => {
            var tempGr = items.find(i => i.name === element)
            tempGids.push(tempGr.id)
        });
        if (postType === 'create') {
            setIsLoading(true);
            fetch(`${baseUrl.baseUrl}user/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    groups: tempGids,
                    username: values.username,
                })
            })
                .then(async response => {
                    const results = await response.json()
                    if (response.status === 201 || response.status === 200) {
                        setDetail(results.message);
                        setSeverityType("success");
                        setOpenSnackbar(true);
                        setRole(results);
                        await sleep(2000);
                        formik.setFieldValue('id', results.user_info.id)
                        onClickhandle(values);
                        setIsLoading(false);
                    } else if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        history.push('/');
                        setIsLoading(false);
                    }
                    else {
                        if (results.detail) {
                            setDetail(results.detail)
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            setIsLoading(false);
                        }
                        else {
                            setDetail("Something Went Wrong")
                            setSeverityType("error");
                            setOpenSnackbar(true);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((error) => {
                })
        } else {
            setIsLoading(true);
            fetch(`${baseUrl.baseUrl}user/${values.id}/`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: values.id,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    groups: tempGids,
                    username: values.username,
                })
            }).then(async response => {
                const results = await response.json()
                if (response.status === 200) {
                    setDetail(results.message);
                    setRole(results);
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    await sleep(2000);
                    setIsLoading(false);
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                    setIsLoading(false);
                }
                else {
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        setIsLoading(false);
                    }
                    else {
                        setDetail("Something Went Wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        setIsLoading(false);
                    }
                }
            })
                .catch((error) => {
                })
        }
    }

    useEffect(() => {
        var tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}roles/`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenStr}`,
                    'Content-Type': 'application/json'
                }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setItems(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                }
            }
            );
    }, [])

    useEffect(() => {
        var tempshowActBtn = localStorage.getItem('showActbtn')
        if (tempshowActBtn !== null) {
        }
    }, [])

    function handleUserActivation(value) {
        formik.setFieldValue('is_active', false)
        formik.setFieldValue('is_locked', true)
        setLoader(true);
        fetch(`${baseUrl.baseUrl}user/${formik.values.id}/`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
            },
            body: JSON.stringify({ is_active: !formik.values.is_active, is_locked: !formik.values.is_locked })
        })
            .then(async response => {
                handleClickOpen();
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setDetail("User is activated successfully")
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    await sleep(2000);
                    setshowActbtn(!results.user_info.is_active)
                    showActbtn === false ? localStorage.setItem('showActbtn', false) : localStorage.setItem('showActbtn', true)
                    formik.setFieldValue('is_active', results.user_info.is_active)
                    formik.setFieldValue('is_locked', results.user_info.is_locked)
                    setLoader(false);
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                    setLoader(false);
                }
                else {
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        setLoader(false);
                    }
                    else {
                        setDetail("Something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        setLoader(false);
                    }
                }
            })
            .catch((error) => {
            })
    }
    function handleUserDeactivation(value) {
        formik.setFieldValue('is_active', false)
        formik.setFieldValue('is_locked', true)
        setLoader(true);
        fetch(`${baseUrl.baseUrl}user/${formik.values.id}/`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
            },
            body: JSON.stringify({ is_active: !formik.values.is_active, is_locked: !formik.values.is_locked })
        })
            .then(async response => {
                handleClickOpen();
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setDetail("User is deactivated successfully")
                    setSeverityType("success");
                    setOpenSnackbar(true);
                    await sleep(2000);
                    setshowActbtn(!results.user_info.is_active)
                    showActbtn === false ? localStorage.setItem('showActbtn', false) : localStorage.setItem('showActbtn', true)
                    formik.setFieldValue('is_active', results.user_info.is_active)
                    formik.setFieldValue('is_locked', results.user_info.is_locked)
                    setLoader(false);
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                    setLoader(false);
                }
                else {
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        setLoader(false);
                    }
                    else {
                        setDetail("Something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        setLoader(false);
                    }
                }
            })
            .catch((error) => {
            })
    }

    function deactiveUser() {
        return (
            <>
                <Typography variant="h6" gutterBottom className="fontBold">
                    Are you sure you want to deactivate?
                </Typography>
                <Grid container spacing={2} className="form_layout_btn" style={{ justifyContent: 'center' }}>
                    <Button
                        className="primary_btn"
                        variant="contained"
                        color="primary"
                        disabled={loader === true}
                        onClick={() => handleUserDeactivation()}
                    >
                        {loader === false ? 'Deactivate' : <CircularProgress className="circular_spiner" size={24} color="white" />}
                    </Button>
                    <Button
                        className="primary_btn_border"
                        variant="outlined"
                        color="primary"
                        onClick={handleClickOpen}
                        style={{ background: '#fff', color: '#067DE2' }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </>
        );
    }
    function activeUser() {
        return (
            <>
                <Typography variant="h6" gutterBottom className="fontBold">
                    Are you sure you want to Activate?
                </Typography>
                <Grid container spacing={2} className="form_layout_btn" style={{ justifyContent: 'center' }}>
                    <Button
                        className="primary_btn"
                        variant="contained"
                        color="primary"
                        disabled={loader === true}
                        onClick={() => handleUserActivation()}
                    >
                        {loader === false ? 'Activate' : <CircularProgress className="circular_spiner" size={24} color="white" />}
                    </Button>
                    <Button
                        className="primary_btn_border"
                        variant="outlined"
                        color="primary"
                        onClick={handleClickOpen}
                        style={{ background: '#fff', color: '#067DE2' }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </>
        );
    }

    function onClickhandle(userData) {
        setPostType('edit')
    }

    const validate = values => {
        if (postType === 'edit') {
            let errors = {}
            if (!values.email ||
                !values.last_name ||
                !values.first_name ||
                !values.groups ||
                !values.username) {
                errors.username = 'Please fill the mandatory(*) fields';
            }

            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && (errors.username || values.last_name || values.first_name ||
                values.groups || values.username
            ) != "Please fill the mandatory(*) fields") {
                errors.email = 'Invalid email address';
            }
            return errors;
        }
        else {
            let errors = {}

            if (!values.email ||
                !values.last_name ||
                !values.first_name ||
                !values.groups ||
                !values.username) {
                errors.username = 'Please fill the mandatory(*) fields';
            }

            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && (errors.username || values.last_name || values.first_name ||
                values.groups || values.username
            ) != "Please fill the mandatory(*) fields") {
                errors.email = 'Invalid email address';
            }
            return errors;
        }
    }
    const formik = useFormik({
        initialValues,
        validate,
        onSubmit
    })

    return (
        <>
            <Navbar />
            <div className="tab-content tab-content-user">
                <section>
                    <form onSubmit={formik.handleSubmit} novalidate>
                        <h3 className="section-title">{postType === 'create' ? 'Create User' : 'Edit User'}</h3>
                        <Grid container className="section-level">
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Username<span className="mark">*</span></label>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    name="username"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.username}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Email ID<span className="mark">*</span></label>
                                <TextField
                                    variant="outlined"
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    type="text"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>First Name<span className="mark">*</span></label>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    name="first_name"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.first_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Last Name<span className="mark">*</span></label>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    name="last_name"
                                    onChange={formik.handleChange}
                                    value={formik.values.last_name}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label className="user-role">Role<span className="mark">*</span></label>
                                <Select name='groups'
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    displayEmpty
                                    multiple
                                    className="form_select"
                                    style={{ color: '#000' }}
                                    onChange={formik.handleChange}
                                    value={formik.values.groups}
                                    input={<Input />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <option style={{ fontWeight: "normal", alignItems: "center", fontFamily: "Poppins-Regular", fontSize: "14px" }}>---Select Role---</option>;
                                        }

                                        return selected.join(', ');
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {items.map((item) => (
                                        <MenuItem key={item.id} value={item.name}>
                                            <Checkbox checked={formik.values.groups.indexOf(item.name) > -1} />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label htmlFor="name">Active</label>
                                <div className="d-flex justify-start align-center">
                                    {showActbtn === false ? <label htmlFor="name" >Yes</label> :
                                        <label htmlFor="name" >No</label>}
                                </div>
                            </Grid>
                        </Grid>
                        <div className="create">
                            {
                                formik.touched.email ?
                                    <div className="error-msg">{formik.errors.email}</div> : ''
                            }
                        </div>

                        <div className="create">
                            {
                                formik.touched.username ?
                                    <div className="error-msg">{formik.errors.username}</div> : ''
                            }
                        </div>

                        <Grid container spacing={2} className="form_layout_btn">
                            {postType === 'create' ?
                                <Button
                                    className="primary_btn"
                                    variant="contained"
                                    onClick={() => { formik.setFieldValue('pType', 'edit') }}
                                    color="primary"
                                    name='pType'
                                    type="submit"
                                    disabled={isLoading === true}
                                    value="create">
                                    {isLoading === false ? 'Save' : <CircularProgress  className='circular_spiner' size={24} color="white" />}</Button>
                                :
                                <Button
                                    className="primary_btn"
                                    variant="contained"
                                    color="primary"
                                    disabled={isLoading === true}
                                    type="submit" value="edit">
                                    {isLoading === false ? 'Update' : <CircularProgress className='circular_spiner' size={24} color="white" />}
                                </Button>
                            }
                            {postType === 'create' ?
                                null :
                                (showActbtn === false) ?
                                    <Button
                                        className="primary_btn"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClickOpen}>
                                        Deactivate
                                    </Button>
                                    :
                                    <Button
                                        className="primary_btn"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClickOpen}>
                                        Activate
                                    </Button>
                            }

                            {showActbtn === false ?
                                <>
                                    <Modalpopup
                                        open={open}
                                        handleClickOpen={handleClickOpen}
                                        deactiveUser={deactiveUser()}
                                        header="Deactivate User"
                                    />
                                </>
                                :
                                <>
                                    <Modalpopup
                                        open={open}
                                        handleClickOpen={handleClickOpen}
                                        activeUser={activeUser()}
                                        header="Activate User"
                                    />
                                </>
                            }
                            <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/user-listing">
                                <Button
                                    className="primary_btn_border"
                                    variant="outlined"
                                    color="primary"
                                    style={{ background: '#fff' }}>
                                    Cancel
                                </Button>
                            </NavLink>
                        </Grid>
                    </form>
                    <Snackbar open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleClose1}
                        anchorOrigin={{ vertical, horizontal }} >
                        <Alert onClose={handleClose1} severity={severity_type}>
                            <div className="error" style={{ color: 'white' }}>
                                <span>{detail}</span>
                            </div>
                        </Alert>
                    </Snackbar>
                </section>
            </div>
        </>
    );
}