import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Navbar from './../Dashboard/Navbar';
import Box from '@material-ui/core/Box';
import '../../styles/Applicationcreates.css';
import Mgptab from './../Dashboard/Mgptab';
import Viewhistory from './../Dashboard/Viewhistory';
import Newhometab from './../Dashboard/Newhometab';
import Tradeinloan from './../Dashboard/Tradeinloan';
import Offerdetails from './../Dashboard/Offerdetails';
import baseUrl from '../../config/config';
import { FormContext } from '../../../context/FormContext';
import HistoryIcon from '@material-ui/icons/History';
import '../../styles/Modal.css';
import MuiAlert from '@material-ui/lab/Alert';
import Reviewmodal from './Reviewmodal';
import '../../styles/style.css';
import Snackbar from '@material-ui/core/Snackbar';
import Container from '@material-ui/core/Container';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ValuationInformation from './ValuationInformation';

function TabPanel(props) {
  const { children, value, index, className, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={className}
    >
      {value === index && (
        <Box className="boxModal">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Applicationcreates(props) {
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedPer, setSelectedPer] = useState();
  const [selectedTerm, setSelectedTerm] = useState();
  const [offerVersion, setOfferVersion] = useState()
  const [offerId, setOfferId] = useState();
  const [forms, setForms] = useContext(FormContext);
  const [active, setActive] = useState(true)
  const [homeOfferValue, setHomeOfferValue] = useState()
  const [openPopup, setOpenPopup] = React.useState(false);
  const vertical = 'top';
  const horizontal = 'center';
  const [detail, setDetail] = useState('');
  const [severity_type, setSeverityType] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isCalculated,setIscalculated] = useState(false)
  const [cxSpecialists, setCxSpecialists] = useState();
  const [statusOffer, setStatusoffer] = useState();
  const [cxSpecialist,setCxSpecialist] = useState(null);

  const handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleClickOpen = () => {
    setOpenPopup(!openPopup);
  };

  const appendMonth = (n) => {
    let num = n + 1;
    if (num <= 9) {
        return "0" + num;
    }
    return num
  }
  const appendDate = (n) => {
    let num = n;
    if (num <= 9) {
        return "0" + num;
    }
    return num
  }
  const dateFormat = (data) => {
    let date = new Date(data)
    let formatted_date = (appendMonth(date.getMonth())) + "-" + appendDate(date.getDate()) + "-" + date.getFullYear();
    return formatted_date.toString();
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const rdata = props.history.location.state ? props.history.location.state.rdata : {};

  useEffect(() => {
    if (forms?.trade_in_application_id) {
      setActive(false)
    }
  }, [forms])

  const [historyRes, setHistoryRes] = useState([]);

  function handleHistoryApi() {
    if (forms?.trade_in_application_id) {
      let tokenStr = localStorage.getItem('access_token')
      fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/get-application-status-history/`,
        {
          headers: {
            'Authorization': `Bearer ${tokenStr}`
          }
        })
        .then(async response => {
          const result = await response.json()
          if (response.status === 201 || response.status === 200) {
            let res = result;
            setHistoryRes(res)
            handleClickOpen()
          } else if (response.status === 403) {
            localStorage.setItem('access_token', '')
            history.push('/');
          }
          else {
            setDetail("something went wrong")
            setSeverityType("error");
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          console.error('error message', error)
        })
    }
  }

  useEffect(()=>{
    let localID = localStorage.getItem('application_id');
    let tokenStr = localStorage.getItem('access_token')
    let headers = { "Authorization": `Bearer ${tokenStr}` }
    fetch(`${baseUrl.baseUrl}get-cx-specialists/`,
    {
        headers
    })
    .then(async response => {
        const result = await response.json()
        if (response.status === 200 || response.status === 201) {
            setCxSpecialists(result)
        }
        else if (response.status === 403) {
            localStorage.setItem('access_token', '')
            props.history.push('/');
        }
    })
    fetch(`${baseUrl.baseUrl}get-app-status-list/`,
    {
        headers
    })
    .then(async response => {
        const result = await response.json()
        if (response.status === 201 || response.status === 200) {
            setStatusoffer(result)
        } else if (response.status === 403) {
            localStorage.setItem('access_token', '')
            props.history.push('/');
        }
    }
    );
  },[])

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    }
  });
  const classes = useStyles();
  const options = { weekday: 'long', year: 'numeric', month: 'full', day: 'numeric' };
  options.timeZone = 'UTC';
  options.timeZoneName = 'short';
  function History() {
    const classes = useStyles();
    return (
      <Container className="History" id="History">
        <Table aria-label="collapsible table" className={`${classes.table} table`}>
          <TableHead className="head">
            <TableRow colSpan={12}>
              <TableCell align="left" className="fontBold">Application Status</TableCell>
              {/* <TableCell align="left" className="fontBold">Comment</TableCell> */}
              <TableCell align="left" className="fontBold">Updated By</TableCell>
              <TableCell align="left" className="fontBold">Updated Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table_border_body">
            {historyRes.map((item, index) => {
              return (<TableRow key={index}>
                <TableCell align="left" id="TableCellAppStatus" style={{ fontSize: "13px", opacity: '0.9', color: 'black' }}>{item.application_status}</TableCell>
                {/* <TableCell align="left" style={{ fontSize: "13px", opacity: '0.9' }}>{item?.comment}</TableCell> */}
                <TableCell align="left" style={{ fontSize: "13px", opacity: '0.9' }}>{item?.created_by}</TableCell>
                <TableCell align="left" style={{ fontSize: "13px", opacity: '0.9' }}>{item?.created_date ? new Date(item.created_date).toLocaleString('en-US', 'options', { hour12: false }) : 'NA'}</TableCell>
              </TableRow>)
            })}
          </TableBody>
        </Table>
      </Container>
    )
  }
  const handleTab = (n) => {
    setActive(false)
    setValue(n)
  }
  return (
    <>
      <Navbar />
      <div className={classes.root}>
        {
          forms?.trade_in_application_id ? (
            <div className="d-flex justify-center align-center app-status">
              <small style={{ marginRight: 5 }}><b className="secondary-text">{forms?.trade_in_application_number}</b></small>
              <span style={{ marginRight: 5 }}>-</span>
              <small style={{ marginRight: 5 }}><b className="secondary-text">{forms?.borrowers[0]?.get_full_name}</b></small>
              <span style={{ marginRight: 5 }}>-</span>
              <small style={{ marginRight: 5 }}><b className="secondary-text">{forms?.property_full_address}</b></small>
              <span style={{ marginRight: 5 }}>-</span>
              <small style={{ marginRight: 5 }}><b className="secondary-text">{forms?.originator_name?.partner_name}</b></small>
              <span style={{ marginRight: 5 }}>-</span>
              <small style={{ marginRight: 5 }}><b className="secondary-text">Created: {dateFormat(forms?.created_date)}</b></small>
              <span style={{ marginRight: 5 }}>-</span>
              {/* <small style={{ marginRight: 10 }}><b className="secondary-text">{forms?.application_status?.application_status}</b></small> */}
              <small style={{ marginLeft: 5 }}><span onClick={handleHistoryApi} style={{ cursor: 'pointer' }}><b className="primary-text d-flex align-center"><HistoryIcon />History</b></span></small>
            </div>
          ) : (
            <div className="d-flex justify-end align-center app-status"></div>
          )
        }
        <Tabs variant="scrollable" scrollButtons="off" value={value} onChange={handleChange} aria-label="simple tabs example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}>
          <Tab label="PPG Application" {...a11yProps(0)} />
          <Tab label="Valuation Information" {...a11yProps(1)} disabled={active}/>
          <Tab label="Offer Summary" {...a11yProps(2)} disabled={active} />
          <Tab label="Change History" {...a11yProps(3)} disabled={active} />
          <Tab label="Trade In Loan" {...a11yProps(4)} disabled={active} />
          <Tab label="New Home Details" {...a11yProps(5)} disabled={active} />
        </Tabs>
        <div style={{ border: '2px solid #1890ff ', marginLeft: '22px', marginRight: "22px", marginBottom: "22px" }}>
          <TabPanel value={value} index={0}>
            <Mgptab {...rdata} cxSpecialists={cxSpecialists} statusOffer={statusOffer}  cxSpecialist={cxSpecialist !== null ? cxSpecialist : forms?.cx_specialist?.id} setCxSpecialist={setCxSpecialist} handleTab={handleTab} selectedValue={selectedValue} setSelectedValue={setSelectedValue} selectedTerm={selectedTerm} setSelectedTerm={setSelectedTerm} selectedPer={selectedPer} setSelectedPer={setSelectedPer} setOfferVersion={setOfferVersion} offerVersion={offerVersion} setOfferId={setOfferId} offerId={offerId} setHomeOfferValue={setHomeOfferValue} homeOfferValue={homeOfferValue} history={history} />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ValuationInformation cxSpecialists={cxSpecialists} statusOffer={statusOffer} cxSpecialist={cxSpecialist !== null ? cxSpecialist : forms?.cx_specialist?.id} setCxSpecialist={setCxSpecialist} {...rdata} handleTab={handleTab} selectedValue={selectedValue} setIscalculated={setIscalculated}  setSelectedValue={setSelectedValue} selectedTerm={selectedTerm} setSelectedTerm={setSelectedTerm} selectedPer={selectedPer} setSelectedPer={setSelectedPer} setOfferVersion={setOfferVersion} offerVersion={offerVersion} setOfferId={setOfferId} offerId={offerId} setHomeOfferValue={setHomeOfferValue} homeOfferValue={homeOfferValue} history={history}/>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Offerdetails history={history} cxSpecialists={cxSpecialists} statusOffer={statusOffer} cxSpecialist={cxSpecialist !== null ? cxSpecialist : forms?.cx_specialist?.id} setCxSpecialist={setCxSpecialist} isCalculated={isCalculated}/>
          </TabPanel>
          
          <TabPanel value={value} index={3}>
            <Viewhistory cxSpecialists={cxSpecialists} statusOffer={statusOffer} cxSpecialist={cxSpecialist !== null ? cxSpecialist : forms?.cx_specialist?.id} setCxSpecialist={setCxSpecialist} history={history} />
          </TabPanel>

          <TabPanel value={value} index={4}>
            <Tradeinloan {...rdata} cxSpecialists={cxSpecialists} statusOffer={statusOffer} cxSpecialist={cxSpecialist !== null ? cxSpecialist : forms?.cx_specialist?.id} setCxSpecialist={setCxSpecialist} selectedValue={selectedValue} selectedTerm={selectedTerm} selectedPer={selectedPer} offerVersion={offerVersion} offerId={offerId} homeOfferValue={homeOfferValue} history={history} />
          </TabPanel>

          <TabPanel value={value} index={5}>
            <Newhometab {...rdata} cxSpecialists={cxSpecialists} cxSpecialist={cxSpecialist !== null ? cxSpecialist : forms?.cx_specialist?.id} setCxSpecialist={setCxSpecialist} statusOffer={statusOffer} history={history} />
          </TabPanel>

        </div>
      </div>
      <Snackbar open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose1}
        anchorOrigin={{ vertical, horizontal }} >
        <Alert onClose={handleClose1} severity={severity_type}>
          <div className="error" style={{ color: 'white' }}>
            <span>{detail}</span>
          </div>
        </Alert>
      </Snackbar>
      <Reviewmodal open={openPopup} class="history" handleClickOpen={handleClickOpen} deactiveUser={History()} header="Application Status History" />
    </>
  );
}