
import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Container, Button, InputBase } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Navbar from './../Dashboard/Navbar';
import baseUrl from '../../config/config';
import { NavLink } from "react-router-dom";
import axios from "axios";
import '../../styles/style.css';
import '../../styles/Userlisting.css';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

const rows = [];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] && b[orderBy].toLowerCase() > a[orderBy] && a[orderBy].toLowerCase()) {
        return 1;
    }
    if (b[orderBy] && b[orderBy].toLowerCase() < a[orderBy] && a[orderBy].toLowerCase()) {
        return -1;
    }
    else if (b[orderBy] && a[orderBy] && a[orderBy].toLowerCase() === b[orderBy] && b[orderBy].toLowerCase()) {
        return 0;
    }
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null) {
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase())
                    .includes(filter.value.toString().toLowerCase())
                :
                true
        );
    }
}
const headCells = [
    { id: 'username', numeric: false, disablePadding: false, label: 'Username ' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email ID ' },
    { id: 'first_name', numeric: false, disablePadding: false, label: 'First Name ' },
    { id: 'last_name', numeric: false, disablePadding: false, label: 'Last Name' },
    { id: 'role', numeric: false, disablePadding: false, label: 'Role' },
    { id: 'active', numeric: false, disablePadding: false, label: 'Active' },
    { id: 'last_login', numeric: false, disablePadding: false, label: 'Last Login' },
];
function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        if(property!=='role' && property!=='active'){
            onRequestSort(event, property);
        }
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding="default"
                        sortDirection={orderBy === (headCell.id && (headCell.id !=='role'|| headCell.id !=='active'  )) ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === (headCell.id && (headCell.id !=='role'|| headCell.id !=='active'  ))}
                            direction={orderBy === headCell.id && (headCell.id !=='role'|| headCell.id !=='active'  )  ? order : 'asc'}
                            hideSortIcon={(headCell.id ==='role'|| headCell.id ==='active'  )?true:false}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    return (
        <div></div>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    table: {
        minWidth: 750,
        borderRadius: '3px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    search: {
        position: 'relative',
        border: '0.5px solid #707070',
        borderRadius: theme.shape.borderRadius,
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(0),
            width: '100%',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 0,
    },
    inputRoot: {
        color: '#000',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        color: '#000 !important',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        paddingLeft: '20px',
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
}));

export default function Userlisting() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('username');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);
    const [tblErr, setTblErr] = useState(false);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.email);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const history = useHistory();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    var userDataToMap = filteredData.length === 0 ? data : filteredData;
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTermRegx, setSearchTermRegx] = React.useState("");
    const [postType, setPostType] = React.useState('create');
    const [userStatus, setUserStatus] = useState(0);
    const [userList, setUserList] = useState([])

    useEffect(() => {
        var tokenStr = localStorage.getItem('access_token')
        fetch(`${baseUrl.baseUrl}user/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setUserList(result)
                    setData(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                }
            }
            );
    }, []);
    RegExp.escape = function (s) {
        if (s) {
            return s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        }
    };

    const handleChange = event => {
        const regEx = new RegExp(RegExp.escape(event.target.value), 'gi');
        setSearchTerm(event.target.value);
        setSearchTermRegx(regEx)
    };

    useEffect(() => {
        if (!searchTerm) {
            setFilteredData([])
            return
        }
        const results = data.filter(item => {
            const query = searchTerm.toLowerCase();
            return (
                item.username.toLowerCase().indexOf(query) >= 0 ||
                item.email.toLowerCase().indexOf(query) >= 0 ||
                item.first_name.toLowerCase().indexOf(query) >= 0 ||
                item.last_name.toLowerCase().indexOf(query) >= 0
            )
        });
        setFilteredData(results);
        setTblErr(
            !searchTerm == results
        )
    }, [searchTerm]);

    function onClickhandle(userData) {
        setPostType('edit')
        var tokenStr = localStorage.getItem("access_token")
        fetch(`${baseUrl.baseUrl}user/${userData.id}/`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    history.push('/');
                }
            })
        history.push({
            pathname: '/user',
            state: { userData: userData, postType: 'edit' }
        });
    }

    const handleActiveChange = (event) => {
        setUserStatus(event)
        if (event === '0') {
            setData(userList)
        } else if (event === '1') {
            let data = userList.filter(item => item.is_active === true)
            setData(data)
        } else if (event === '2') {
            let data = userList.filter(item => item.is_active === false)
            setData(data)
        }
    }

    return (
        <>
            <Navbar />
            <div className="application">
                <div className="View">
                    <div className={classes.root}>
                        <Container className={classes.paper}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                <NavLink style={{ textDecoration: 'none', color: 'white' }} exact to="/user">
                                    <Button className="primary_btn" style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <AddIcon style={{ color: '#fff', marginRight: '5px', fontSize: '22px', }} />
                                        Add user
                                    </Button>
                                </NavLink>
                            </div>
                            <Grid container>
                                <Grid item xs={12} sm={8} md={9} lg={9}>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase className="colors"
                                            type="text"
                                            value={searchTerm}
                                            onChange={handleChange}
                                            placeholder="Search For Username/Email/First Name/Last Name"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div>
                                </Grid>
                                <Grid className="user_select" item xs={12} sm={4} md={3} lg={2}>
                                    <label>Active</label>
                                    <div className="views-widget">
                                        <select
                                            value={userStatus}
                                            onChange={(e) => handleActiveChange(e.target.value)}
                                        >
                                            <option value={0}>All</option>
                                            <option value={1}>Yes</option>
                                            <option value={2}>No</option>
                                        </select>
                                    </div>
                                </Grid>
                            </Grid>
                            <EnhancedTableToolbar numSelected={selected.length} defaultFilterMethod={filterCaseInsensitive} />
                            <TableContainer>
                                <Table
                                    className={`${classes.table} table`}
                                    aria-labelledby="tableTitle"
                                    size="small"
                                    aria-label="enhanced table"
                                    defaultFilterMethod={filterCaseInsensitive}
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={data.length}
                                        defaultFilterMethod={filterCaseInsensitive}
                                    />

                                    {(tblErr == false) ? (<TableBody>
                                        {stableSort(userDataToMap, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                const isItemSelected = isSelected(row.name);
                                                const date = new Date(row.last_login);
                                                const options = { weekday: 'long', year: 'numeric', month: 'full', day: 'numeric' };
                                                options.timeZone = 'UTC';
                                                options.timeZoneName = 'short';

                                                return (
                                                    <TableRow>
                                                        <TableCell align="right" onClick={() => { onClickhandle(row) }}>{row.username}</TableCell>
                                                        <TableCell align="right" onClick={() => { onClickhandle(row) }}>
                                                            {row.email}
                                                        </TableCell>
                                                        <TableCell align="right" onClick={() => { onClickhandle(row) }}>{row.first_name}</TableCell>
                                                        <TableCell align="right" onClick={() => { onClickhandle(row) }}>{row.last_name}</TableCell>
                                                        <TableCell align="right" onClick={() => { onClickhandle(row) }}><div>
                                                            {row.groups.map(
                                                                (t, i) => <div>{t.name}{row.groups.length - 1 === i ? '' : ','} </div>
                                                            )
                                                            }
                                                        </div>
                                                        </TableCell>
                                                        <TableCell align="right" onClick={() => { onClickhandle(row) }}>{row.is_active ? 'YES' : 'NO'}</TableCell>
                                                        <TableCell align="right" onClick={() => { onClickhandle(row) }}>{row.last_login ? new Date(row.last_login).toLocaleString('en-US', 'options', { hour12: false }) : 'NA'}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>) :
                                        <TableBody>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell><span style={{ color: 'black' }}>User Not Found</span></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableBody>}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className="custom_pagination"
                                rowsPerPageOptions={[]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}