import React, { useEffect, useState, useContext } from 'react';
import '../../styles/styleNew.css';
import { Grid, TextField, Button, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import baseUrl from '../../config/config';
import { NavLink } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { FormContext } from '../../../context/FormContext';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonHeader from './CommonHeader';

function Newhometab(props) {
    const [forms, setForms] = useContext(FormContext);
    const [propTypeHome, setPropTypeHome] = useState([])
    const [loanTypeHome, setLoanTypeHome] = useState([])
    const [countyList, setCountyList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [loanTypes, setLoanType] = useState([]);
    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const vertical = 'top';
    const horizontal = 'center';
    const [detail, setDetail] = useState('');
    const [severity_type, setSeverityType] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [zipcodeId, setZipcodeId] = useState();
    const [newHomeTabLoader, setNewHomeTabLoader] = useState(false);
    const [zipCodeValue, setZipCodeValue] = useState();

    const initialValues = {
        street_address: null,
        property_type: null,
        zipCode: null,
        purchase_price: null,
        newHomeCity: null,
        purchase_date: null,
        newHomeCounty: null,
        ownerOccupied: "false",
        newHomeState: null,
        originationDate: null,
        loanType: null,
        organizationLoanAmount: null,
        loanPurpose: null,
        loanRate: null,
        term_months: null,
    }
    const editValues = () => {
        if (forms?.trade_in_application_id) {
            localStorage.setItem('application_id', forms.trade_in_application_id)
        }
        setZipCodeValue(forms?.new_home?.new_home_zip_code?.zip_code ? forms.new_home.new_home_zip_code.zip_code : '')
        setZipcodeId(forms?.new_home?.new_home_zip_code?.zip_code_id ? forms.new_home.new_home_zip_code.zip_code_id : '')
        formik.setFieldValue('street_address', forms?.new_home?.new_home_street_address ? forms.new_home.new_home_street_address : null)
        formik.setFieldValue('property_type', forms?.new_home?.new_home_property_type?.property_type_id ? forms.new_home.new_home_property_type.property_type_id : null)
        formik.setFieldValue('purchase_price', forms?.new_home?.new_home_purchase_price ? forms.new_home.new_home_purchase_price : null)
        formik.setFieldValue('newHomeCity', forms?.new_home?.new_home_city ? forms.new_home.new_home_city : null)
        formik.setFieldValue('purchase_date', forms?.new_home?.new_home_purchase_date ? moment(forms.new_home.new_home_purchase_date).format('MM/DD/YYYY') : null)
        formik.setFieldValue('newHomeCounty', forms?.new_home?.new_home_county?.county_id ? forms.new_home.new_home_county.county_id : null)
        formik.setFieldValue('ownerOccupied', forms?.new_home?.new_home_owner_occupied ? forms.new_home.new_home_owner_occupied.toString() : 'false')
        formik.setFieldValue('newHomeState', forms?.new_home?.new_home_state?.state_id ? forms.new_home.new_home_state.state_id : null)
        formik.setFieldValue('originationDate', forms?.new_home?.origination_date ? moment(forms.new_home.origination_date).format('MM/DD/YYYY') : null)
        formik.setFieldValue('loanType', forms?.new_home?.type ? forms.new_home.type : null)
        formik.setFieldValue('originalLoanAmt', forms?.new_home?.original_loan_amount ? forms.new_home.original_loan_amount : null)
        formik.setFieldValue('loanPurpose', forms?.new_home?.purpose ? forms.new_home.purpose : null)
        formik.setFieldValue('loanRate', forms?.new_home?.rate ? forms.new_home.rate : null)
        formik.setFieldValue('term_months', forms?.new_home?.term_months ? forms.new_home.term_months : null)
    }
    const getApplicationData = (id) => {
        let tokenStr = localStorage.getItem('access_token')
        let headers = { "Authorization": `Bearer ${tokenStr}` }
        fetch(`${baseUrl.baseUrl}trade-in-application/${id}/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setForms(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
    }

    const restrictNumber = (e) =>{
        var deleteKeyCode = 8;
        var backspaceKeyCode = 46;
        if ((e.which>=48 && e.which<=57) ||
        (e.which>=96 && e.which<=105)  ||
        e.which === deleteKeyCode ||
            e.which === backspaceKeyCode || e.which===37 || e.which===39){
                return true;
            }else{
                e.preventDefault();
            }
    }

    const restrictDecimal = (e,id) =>{
        var deleteKeyCode = 8;
        var backspaceKeyCode = 46;
        if ((e.which>=48 && e.which<=57) ||
        (e.which>=96 && e.which<=105)  ||
        e.which === deleteKeyCode ||
            e.which === backspaceKeyCode||e.which===190 || e.which===37 || e.which===39){
                var valuemortgageInfoCumulative = document.getElementById(id);
                if(valuemortgageInfoCumulative.value.indexOf('.')!==-1 && e.which===190){
                    e.preventDefault();
                }
            }else{
                e.preventDefault();
            }  
    }


    const handleRange = (e) => {
        if(e.target.value>100){
                        return e.target.value= 100
                    }else if(e.target.value<0){
                        return e.target.value = 0
                    }
    }

    const onSubmit = values => {
        let tokenStr = localStorage.getItem('access_token')
        let body = {
            new_home_details: {
                new_home_street_address: values.street_address,
                new_home_property_type: values.property_type,
                new_home_zip_code: zipcodeId,
                new_home_purchase_price: parseFloat(values.purchase_price && values.purchase_price.replace(/,/g, '')),
                new_home_city: values.newHomeCity,
                new_home_purchase_date: (values.purchase_date === '' || values.purchase_date === null) ? null : moment(values.purchase_date).format('YYYY-MM-DD'),
                new_home_county: values.newHomeCounty,
                new_home_owner_occupied: values.ownerOccupied,
                new_home_state: values.newHomeState,
                origination_date: (values.originationDate === '' || values.originationDate === null) ? null : moment(values.originationDate).format('YYYY-MM-DD'),
                type: values.loanType,
                original_loan_amount: parseFloat(values.originalLoanAmt && values.originalLoanAmt.replace(/,/g, '')),
                purpose: values.loanPurpose,
                rate: values.loanRate,
                term_months: values.term_months
            }
        }
        setNewHomeTabLoader(true)
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setOpenSnackbar(true);
                    setForms(results.trade_in_application)
                    setDetail(results.message);
                    setSeverityType("success");
                    setNewHomeTabLoader(false)
                    await sleep(2000);
                }
                else {
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        setNewHomeTabLoader(false)
                        await sleep(2000);
                    }
                    else {
                        setDetail("something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        setNewHomeTabLoader(false)
                        await sleep(2000);
                    }
                }
                if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
            .catch((error) => {
            })
    }
    const formik = useFormik({
        initialValues,
        onSubmit,
    });
    const handleZipcode = (event) => {
        setZipCodeValue(event)
        var tokenStr = localStorage.getItem('access_token')
        if (event.length === 5) {
            fetch(`${baseUrl.baseUrl}get-zip-codes/?search=${event}`,
                {
                    headers: { "Authorization": `Bearer ${tokenStr}` }
                })
                .then(async response => {
                    const result = await response.json()
                    var res = result;
                    if (res.length > 0) {
                        setZipcodeId(res[0].zip_code_id)
                        setZipCodeValue(res[0].zip_code)
                        formik.setFieldValue('newHomeState', res[0].state.state_id)
                        formik.setFieldValue('newHomeCity', res[0].city)
                        formik.setFieldValue('newHomeCounty', res[0].county.county_id)
                    } else {
                        setZipCodeValue('')
                        formik.setFieldValue('newHomeState', '')
                        formik.setFieldValue('newHomeCity', '')
                        formik.setFieldValue('newHomeCounty', '')
                    }
                    if (response.status === 403) {
                        localStorage.setItem('access_token', '')
                        props.history.push('/');
                    }
                });
        }
    }
    const appendMonth = (n) => {
        let num = n + 1;
        if (num <= 9) {
            return "0" + num;
        }
        return num
    }
    const appendDate = (n) => {
        let num = n;
        if (num <= 9) {
            return "0" + num;
        }
        return num
    }
    const handlePurchaseDateChange = (date) => {
        if (date != null) {
            formik.setFieldValue('purchase_date', moment(date).format('MM/DD/YYYY'))
        } else {
            formik.setFieldValue('purchase_date', '')
        }
    };
    const handleOriginationDateChange = (date) => {
        if (date != null) {
            formik.setFieldValue('originationDate', moment(date).format('MM/DD/YYYY'))
        } else {
            formik.setFieldValue('originationDate', '')
        }
    };

    useEffect(() => {
        let localID = localStorage.getItem('application_id');
        let tokenStr = localStorage.getItem('access_token')
        if (forms && forms.length != 0) {
            editValues();
        } else if (forms && forms.length == 0 && localID != null) {
            getApplicationData(localID);
        }
        fetch(`${baseUrl.baseUrl}get-property-type/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setPropTypeHome(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            });
        fetch(`${baseUrl.baseUrl}get-mortgage-purpose/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setLoanType(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            });
        fetch(`${baseUrl.baseUrl}get-mortgage-type/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setLoanTypeHome(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            });
        fetch(`${baseUrl.baseUrl}get-county-list/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setCountyList(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
        fetch(`${baseUrl.baseUrl}get-states/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setStateList(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
    }, [])

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return (
        <>
            <CommonHeader cxSpecialists={props.cxSpecialists} statusOffer={props.statusOffer} cxSpecialist={props.cxSpecialist} setCxSpecialist={props.setCxSpecialist} />
            <div className="tab-content">
                <section>
                    <form onSubmit={formik.handleSubmit}>
                        <h3 className="section-title">New Property Information</h3>
                        <Grid container className="section-level">
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Street Address</label>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    name="street_address"
                                    onChange={formik.handleChange}
                                    value={formik.values.street_address && formik.values.street_address.trimStart()}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Property Type</label>
                                <div class="views-widget" style={{marginLeft:"3px"}}>
                                    <select
                                        name="property_type"
                                        className="form_select form-select-mgp"
                                        value={formik.values.property_type}
                                        onChange={formik.handleChange}
                                        style={{ color: '#000' }}
                                    >
                                        <option value=''>---Select Property Type---</option>
                                        {propTypeHome.map((item, index) => (
                                            <option key={index} value={item.property_type_id}>
                                                {item.property_type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Zipcode</label>
                                <TextField
                                    autoComplete='off'
                                    type="text"
                                    maxLength={5}
                                    variant="outlined"
                                    name="zipCode"
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => handleZipcode(e.target.value)}
                                    value={zipCodeValue}
                                    onKeyDown={(e) => restrictNumber(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Purchase Price</label>
                                <NumberFormat
                                    className="numberField"
                                    thousandSeparator={','}
                                    decimalSeparator={'.'}
                                    autoComplete='off'
                                    decimalScale={2}
                                    maxLength="13"
                                    variant="outlined"
                                    name="purchase_price"
                                    onChange={formik.handleChange}
                                    value={formik.values.purchase_price}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>City</label>
                                <TextField
                                    autoComplete='off'
                                    type="text"
                                    variant="outlined"
                                    name="newHomeCity"
                                    onChange={formik.handleChange}
                                    value={formik.values.newHomeCity && formik.values.newHomeCity.trimStart()}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Purchase Date</label>
                                <KeyboardDatePicker
                                    autoOk
                                    className="date-input"
                                    format="MM/dd/yyyy"
                                    placeholder="MM/DD/YYYY"
                                    name="purchase_date"
                                    onChange={handlePurchaseDateChange}
                                    value={formik.values.purchase_date}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>County</label>
                                <div class="views-widget" style={{marginLeft:"4px"}}>
                                    <select
                                        name='newHomeCounty'
                                        style={{ color: '#000' }}
                                        className="form_select form-select-mgp"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.newHomeCounty}
                                    >
                                        <option value="">---Select Country---</option>
                                        {countyList.map((item, index) => (
                                            <option key={index} value={item.county_id}  >
                                                {item.county_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv radio-btn-div">
                                <label className="radio-label">Owner Occupied</label>
                                <div className="d-flex justify-start align-center">
                                    <RadioGroup
                                        name="ownerOccupied"
                                        className="d-flex justify-start align-center row-direction"
                                        value={formik.values.ownerOccupied}
                                        onChange={formik.handleChange}
                                    >
                                        <div className="d-flex justify-start align-center row-direction">
                                            <FormControlLabel className="mr-10 w-70" value="true" control={<Radio disableripple color='primary' />} label="Yes" labelPlacement="end" />
                                            <FormControlLabel className="mr-10 w-70" value="false" control={<Radio disableripple color='primary' />} label="No" labelPlacement="end" />
                                        </div>
                                    </RadioGroup>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>State</label>
                                <div class="views-widget" style={{marginLeft:"4px"}}>
                                    <select
                                        name='newHomeState'
                                        className="form_select form-select-mgp"
                                        style={{ color: '#000' }}
                                        onChange={formik.handleChange}
                                        value={formik.values.newHomeState}
                                    >
                                        <option value="">---Select State---</option>
                                        {stateList.map((item, index) => (
                                            <option key={index} value={item.state_id} >
                                                {item.state_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                        {
                            formik.touched.newHomeState && formik.errors.newHomeState ?
                                <div className="error-msg">{formik.errors.newHomeState}</div>
                                :
                                <React.Fragment>
                                    {
                                        formik.touched.purchase_date && formik.errors.purchase_date ?
                                            <div className="error-msg">{formik.errors.purchase_date}</div>
                                            :
                                            ''
                                    }
                                </React.Fragment>
                        }
                        <h3 className="section-title">New Mortgage Information</h3>
                        <Grid container className="section-level">
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Origination Date</label>
                                <KeyboardDatePicker
                                    autoOk
                                    className="date-input"
                                    format="MM/dd/yyyy"
                                    placeholder="MM/DD/YYYY"
                                    name="originationDate"
                                    onChange={handleOriginationDateChange}
                                    value={formik.values.originationDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Loan Type</label>
                                <div class="views-widget">
                                    <select
                                        name="loanType"
                                        value={formik.values.loanType}
                                        onChange={formik.handleChange}
                                        className="form_select form-select-mgp"
                                        style={{ color: '#000' }}
                                    >
                                        <option value=''>---Select Loan Type---</option>
                                        {loanTypeHome.map((item, index) => (
                                            <option key={index} value={item.mortgage_type_id}>
                                                {item.mortgage_type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Original Loan Amount</label>
                                <NumberFormat
                                    className="numberField"
                                    thousandSeparator={','}
                                    decimalSeparator={'.'}
                                    autoComplete='off'
                                    decimalScale={2}
                                    maxLength="13"
                                    variant="outlined"
                                    name="originalLoanAmt"
                                    onChange={formik.handleChange}
                                    value={formik.values.originalLoanAmt}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Loan Purpose</label>
                                <div class="views-widget" style={{marginLeft:"4px"}}>
                                    <select loanTypes
                                        name='loanPurpose'
                                        className="form_select form-select-mgp"
                                        style={{ color: '#000' }}
                                        onChange={formik.handleChange}
                                        value={formik.values.loanPurpose}
                                    >
                                        <option value="">---Select Loan Purpose---</option>
                                        {loanTypes.map((item, index) => (
                                            <option key={index} value={item.mortgage_purpose_id} >
                                                {item.mortgage_purpose}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Loan Rate</label>
                                <TextField
                                    autoComplete='off'
                                    type="text"
                                    variant="outlined"
                                    name="loanRate"
                                    id="loanRate"
                                    onChange={formik.handleChange}
                                    value={formik.values.loanRate}
                                    onInput={(e)=>{handleRange(e)}}
                                    onKeyDown={(e) => restrictDecimal(e,"loanRate")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                <label>Term Months</label>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    name="term_months"
                                    onChange={formik.handleChange}
                                    value={formik.values.term_months}
                                    onKeyDown={(e) => restrictDecimal(e,"mortgageInfoCumulative")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="form_layout_btn">
                            <Button
                                className="primary_btn"
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={newHomeTabLoader === true}
                            >
                                {newHomeTabLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                            </Button>
                            <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-listing">
                                <Button
                                    className="secondary_btn"
                                    variant="outlined"
                                    color="primary"
                                    style={{ background: '#fff' }}
                                >
                                    Cancel
                                </Button>
                            </NavLink>
                        </Grid>
                    </form>
                </section>
            </div>
            <Snackbar open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleClose1}
                anchorOrigin={{ vertical, horizontal }} >
                <Alert onClose={handleClose1} severity={severity_type}>
                    <div className="error" style={{ color: 'white' }}>
                        <span>{detail}</span>
                    </div>
                </Alert>
            </Snackbar>
        </>
    )
}

export default Newhometab;
