import React from 'react';
import Bgimages from './Bgimages';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import '../../styles/Login.css';

function Login(props) {
    return (
        <div>
            <div className="bgimage" style={{ backgroundImage: 'url("/assets/images/loginbg.png")' }}>
                <Toolbar>
                    <Typography>
                        <div className="login_img">
                            <img src="/assets/images/calque logo_prototype.png" alt="image" className="h-100vh w-100"></img>
                        </div>
                    </Typography>
                </Toolbar>
                <Bgimages />
            </div>
        </div>
    );
}

export default Login;
