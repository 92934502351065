import React, { useState, useEffect } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: '#000',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        color: '#000 !important',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        paddingLeft: '20px',
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    }
}));

const DebouncedInput = ({ onDebouncedChange }) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onDebouncedChange(searchTerm.trim());
        },500);

        return () => clearTimeout(timeoutId);
    }, [searchTerm, onDebouncedChange]);

    return (
        <InputBase
            className="colors"
            type="text"
            value={searchTerm.trimStart()}
            onChange={handleChange}
            style={{ fontSize: "14px" }}
            placeholder="Application Number/Borrower Name/Property Address"
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
        />
    );
};

export default DebouncedInput;
