import React, { useEffect, useState, useContext } from 'react';
import '../../styles/styleNew.css';
import '../../styles/style.css';
import { Grid, TextField, Button } from '@material-ui/core';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import RemoveIcon from '@material-ui/icons/Remove';
import { useFormik } from 'formik';
import baseUrl from '../../config/config';
import { NavLink } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { FormContext } from '../../../context/FormContext';
import { KeyboardDatePicker } from "@material-ui/pickers";
import PageLoader from '../../views/commonViews/PageLoader';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonHeader from './CommonHeader';

const show = {
    display: 'flex',
}
const hide = {
    display: 'none'
}

function Tradeinloan(props) {
    const history = useHistory();
    const [forms, setForms] = useContext(FormContext);
    const [borrowerMoreInfo, setBorrewerMoreInfo] = useState(false);
    const [itemsp, setItemsp] = useState([]);
    const [itemsmp, setItemsmp] = useState([]);
    const [itemstype, setItemstype] = useState([]);
    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const vertical = 'top';
    const horizontal = 'center';
    const [detail, setDetail] = useState('');
    const [severity_type, setSeverityType] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loadProgress, setLoadProgress] = useState(false);
    const [tradeInLoanBtnLoader, setTradeInLoanBtnLoader] = useState(false);
    useEffect(() => {
        formik.setFieldValue('homeowner', forms?.trade_in_loan?.homeowner_ppg ? forms.trade_in_loan.homeowner_ppg : null)
    }, [forms])
    const initialValues = {
        type: null,
        purpose: null,
        loanPurpose: null,
        itemsp: [],
        rate: null,
        originalLoan: null,
        originalDate: null,
        guarenteePercentage: null,
        currentLoan: null,
        rule_version: null,
        termDays: null,
        homeowner: null,
        estimatedClosingCost: null,
        buyerOffer: null,
        calqueFees: null,
        newPropertyMaxLTV: null,
        mortgageRate: null,
        estimatedClosingCost2: null,
        mortgagePurpose: null,
        downPayment: null,
        estimatedCommissionPaid: null,
        additionalCashDownPayment: null,
        existingPropertyEstimatedProceeds: null,
        maxFundingDays: null,
        EstDaysOnMarket: null,
        mortgageType: null,
        mortgageTermDays: null,
    }

    const restrictDecimal = (e,id) =>{
        var deleteKeyCode = 8;
        var backspaceKeyCode = 46;
        if ((e.which>=48 && e.which<=57) ||
        (e.which>=96 && e.which<=105)  ||
        e.which === deleteKeyCode ||
            e.which === backspaceKeyCode||e.which===190 || e.which===37 || e.which===39){
                var valuemortgageInfoCumulative = document.getElementById(id);
                if(valuemortgageInfoCumulative.value.indexOf('.')!==-1 && e.which===190){
                    e.preventDefault();
                }
            }else{
                e.preventDefault();
            }  
    }


const handleRange = (e) => {
       if(e.target.value>100){
                    return e.target.value= 100
                }else if(e.target.value<0){
                    return e.target.value = 0
                }
}

    const editValues = () => {
        if (forms?.trade_in_application_id) {
            localStorage.setItem('application_id', forms.trade_in_application_id)
        }
        if (forms?.trade_in_finance?.new_prop_max_ltv != null ||
            forms?.trade_in_finance?.mort_rate != null ||
            forms?.trade_in_finance?.estimated_closing_cost_home2 != null ||
            forms?.trade_in_finance?.mort_purpose_id != null ||
            forms?.trade_in_finance?.new_prop_down_payment_min != null ||
            forms?.trade_in_finance?.estimated_commission_paid != null ||
            forms?.trade_in_finance?.additional_cash_down_payment != null ||
            forms?.trade_in_finance?.existing_prop_estimated_proceeds != null ||
            forms?.trade_in_finance?.max_funding_days != null ||
            forms?.trade_in_finance?.mort_type?.mortgage_type_id != null ||
            forms?.trade_in_finance?.mort_term != null
        ) {
            setBorrewerMoreInfo(true);
        } else {
            setBorrewerMoreInfo(false);
        }

        formik.setFieldValue('type', forms?.trade_in_loan?.trade_in_loan_type ? forms.trade_in_loan.trade_in_loan_type : null)
        formik.setFieldValue('purpose', forms?.trade_in_loan?.purpose ? parseInt(forms.trade_in_loan.purpose) : null)
        formik.setFieldValue('rate', forms?.trade_in_loan?.trade_in_loan_rate ? forms.trade_in_loan.trade_in_loan_rate : null)
        formik.setFieldValue('originalLoan', forms?.trade_in_loan?.original_loan ? forms.trade_in_loan.original_loan : null)
        formik.setFieldValue('originalDate', forms?.trade_in_loan?.origination_date ? moment(forms.trade_in_loan.origination_date).format('MM/DD/YYYY') : null)
        formik.setFieldValue('guarenteePercentage', forms?.trade_in_loan?.guarantee_percentage ? forms.trade_in_loan.guarantee_percentage : null)
        formik.setFieldValue('currentLoan', forms?.trade_in_loan?.current_loan ? forms.trade_in_loan.current_loan : null)
        formik.setFieldValue('rule_version', forms?.trade_in_loan?.rule_version ? forms.trade_in_loan.rule_version.rule_version : null)
        formik.setFieldValue('termDays', forms?.trade_in_loan?.term ? forms.trade_in_loan.term : null)
        formik.setFieldValue('homeowner', forms?.calculated_offer?.homeowner_ppg ? forms.calculated_offer.homeowner_ppg : null)
        formik.setFieldValue('estimatedClosingCost', forms?.trade_in_finance?.estimated_closing_cost_home1 ? forms.trade_in_finance.estimated_closing_cost_home1 : null)

        formik.setFieldValue('newPropertyMaxLTV', forms?.trade_in_finance?.new_prop_max_ltv ? forms.trade_in_finance.new_prop_max_ltv : null)
        formik.setFieldValue('mortgageRate', forms?.trade_in_finance?.mort_rate ? forms.trade_in_finance.mort_rate : null)
        formik.setFieldValue('estimatedClosingCost2', forms?.trade_in_finance?.estimated_closing_cost_home2 ? forms.trade_in_finance.estimated_closing_cost_home2 : null)
        formik.setFieldValue('mortgagePurpose', forms?.trade_in_finance?.mort_purpose_id ? forms.trade_in_finance.mort_purpose_id : null)
        formik.setFieldValue('downPayment', forms?.trade_in_finance?.new_prop_down_payment_min ? forms.trade_in_finance.new_prop_down_payment_min : null)
        formik.setFieldValue('estimatedCommissionPaid', forms?.trade_in_finance?.estimated_commission_paid ? forms.trade_in_finance.estimated_commission_paid : null)
        formik.setFieldValue('additionalCashDownPayment', forms?.trade_in_finance?.additional_cash_down_payment ? forms.trade_in_finance.additional_cash_down_payment : null)
        formik.setFieldValue('existingPropertyEstimatedProceeds', forms?.trade_in_finance?.existing_prop_estimated_proceeds ? forms.trade_in_finance.existing_prop_estimated_proceeds : null)
        formik.setFieldValue('maxFundingDays', forms?.trade_in_finance?.max_funding_days ? forms.trade_in_finance.max_funding_days : null)
        formik.setFieldValue('mortgageType', forms?.trade_in_finance?.mort_type?.mortgage_type_id ? forms.trade_in_finance.mort_type.mortgage_type_id : null)
        formik.setFieldValue('mortgageTermDays', forms?.trade_in_finance?.mort_term ? forms.trade_in_finance.mort_term : null)
        setLoadProgress(false)
    }
    const handleOriginalDate = (date) => {
        if (date != null) {
            formik.setFieldValue('originalDate', moment(date).format('MM/DD/YYYY'))
        } else {
            formik.setFieldValue('originalDate', '')
        }
    };
    const getApplicationData = (id) => {
        let tokenStr = localStorage.getItem('access_token')
        let headers = { "Authorization": `Bearer ${tokenStr}` }
        fetch(`${baseUrl.baseUrl}trade-in-application/${id}/`,
            {
                headers
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setForms(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            })
    }
    const validate = values => {
        let errors = {};

        if (!values.rule_version) {
            errors.type = "Please fill the Trade In Loan Information section mandatory(*) fields."
        }
        if (values.originalDate && (!moment(values.originalDate).isAfter('01/01/1899', 'year') || !moment(values.originalDate).isBefore('12/31/2099', 'year'))) {
            errors.originalDate = "Please choose the Origination Date between 01-01-1899 and 12-31-2099."
        }
        return errors
    }
    const onSubmit = values => {
        var tokenStr = localStorage.getItem('access_token')
        let body = {
            trade_in_loan: {
                trade_in_loan_type: values.type,
                purpose: values.purpose,
                trade_in_loan_rate: (values.rate === '' || values.rate === null) ? null : values.rate,
                original_loan: (values.originalLoan === '' || values.originalLoan === null) ? null : parseFloat(values.originalLoan.replace(/,/g, '')),
                origination_date: (values.originalDate === '' || values.originalDate === null) ? null : moment(values.originalDate).format('YYYY-MM-DD'),
                guarantee_percentage: values.guarenteePercentage,
                rule_version: props.offerId,
                current_loan: values.currentLoan,
                term: values.termDays,
                homeowner_ppg: values.homeowner
            },
            trade_in_finance: {
                estimated_closing_cost_home1: (values.estimatedClosingCost === '' || values.estimatedClosingCost === null) ? null : parseFloat(values.estimatedClosingCost.replace(/,/g, '')),
                new_prop_max_ltv: (values.newPropertyMaxLTV === '' || values.newPropertyMaxLTV === null) ? null : values.newPropertyMaxLTV,
                mort_rate: values.mortgageRate != '' ? values.mortgageRate : null,
                estimated_closing_cost_home2: (values.estimatedClosingCost2 === '' || values.estimatedClosingCost2 === null) ? null : parseFloat(values.estimatedClosingCost2.replace(/,/g, '')),
                mort_purpose_id: values.mortgagePurpose != '' ? values.mortgagePurpose : null,
                new_prop_down_payment_min: (values.downPayment === '' || values.downPayment === null) ? null : parseFloat(values.downPayment.replace(/,/g, '')),
                estimated_commission_paid: (values.estimatedCommissionPaid === '' || values.estimatedCommissionPaid === null) ? null : parseFloat(values.estimatedCommissionPaid.replace(/,/g, '')),
                additional_cash_down_payment: (values.additionalCashDownPayment === '' || values.additionalCashDownPayment === null) ? null : parseFloat(values.additionalCashDownPayment.replace(/,/g, '')),
                existing_prop_estimated_proceeds: (values.existingPropertyEstimatedProceeds === '' || values.existingPropertyEstimatedProceeds === null) ? null : parseFloat(values.existingPropertyEstimatedProceeds.replace(/,/g, '')),
                max_funding_days: values.maxFundingDays != '' ? values.maxFundingDays : null,
                mort_type: values.mortgageType != '' ? values.mortgageType : null,
                mort_term: values.mortgageTermDays != '' ? values.mortgageTermDays : null,
            },
        }
        if (body.trade_in_finance['new_prop_max_ltv'] != null || body.trade_in_finance['mort_rate'] != null || body.trade_in_finance['estimated_closing_cost_home2'] != null || body.trade_in_finance['mort_purpose_id'] != null || body.trade_in_finance['new_prop_down_payment_min'] != null || body.trade_in_finance['estimated_commission_paid'] != null || body.trade_in_finance['additional_cash_down_payment'] != null || body.trade_in_finance['existing_prop_estimated_proceeds'] != null || body.trade_in_finance['max_funding_days'] != null || body.trade_in_finance['mort_type'] != null || body.trade_in_finance['mort_term'] != null) {
            toggleborrowerMoreInfo();
        }
        setTradeInLoanBtnLoader(true)
        fetch(`${baseUrl.baseUrl}trade-in-application/${forms.trade_in_application_id}/`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenStr}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(async response => {
                const results = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setOpenSnackbar(true);
                    setForms(results.trade_in_application)
                    setDetail(results.message);
                    setSeverityType("success");
                    await sleep(2000);
                    setTradeInLoanBtnLoader(false)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
                else {
                    if (results.detail) {
                        setDetail(results.detail)
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000)
                        setTradeInLoanBtnLoader(false)
                    }
                    else {
                        setDetail("something went wrong")
                        setSeverityType("error");
                        setOpenSnackbar(true);
                        await sleep(1000)
                        setTradeInLoanBtnLoader(false)
                    }
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }
    const formik = useFormik({
        initialValues,
        validate,
        onSubmit,
    });
    const toggleborrowerMoreInfo = () => {
        setBorrewerMoreInfo(!borrowerMoreInfo);
    }

    useEffect(() => {
        let localID = localStorage.getItem('application_id');
        let tokenStr = localStorage.getItem('access_token')
        setLoadProgress(true)

        if (forms && forms.length != 0) {
            editValues();
        } else if (forms && forms.length == 0 && localID != null) {
            getApplicationData(localID);
        }
        fetch(`${baseUrl.baseUrl}get-mortgage-purpose/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setItemsp(result)
                    setItemsmp(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            }
            );
        fetch(`${baseUrl.baseUrl}get-mortgage-type/`,
            {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            })
            .then(async response => {
                const result = await response.json()
                if (response.status === 201 || response.status === 200) {
                    setItemstype(result)
                } else if (response.status === 403) {
                    localStorage.setItem('access_token', '')
                    props.history.push('/');
                }
            }
            );
    }, [forms])
    useEffect(() => {
        if (props.selectedPer) {
            formik.setFieldValue('guarenteePercentage', props.selectedPer["guarantee_as_%_of_estimated_value"])
        }
        if (props.offerVersion) {
            formik.setFieldValue('rule_version', props.offerVersion)
        }
        if (props.selectedTerm) {
            formik.setFieldValue('termDays', props.selectedTerm)
        }
        if (props.homeOfferValue) {
            formik.setFieldValue('homeowner', props.homeOfferValue["homeowner_PPG_at_this_%_of_estimated_value"])
        }
        if (props.offerId) {
            formik.setFieldValue('rule_version_id', props.offerId)
        }
    }, [])

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return (
        <>
            <CommonHeader cxSpecialists={props.cxSpecialists} statusOffer={props.statusOffer} cxSpecialist={props.cxSpecialist} setCxSpecialist={props.setCxSpecialist} />
            <div className="tab-content">
                <section>
                    {
                        loadProgress === true ? <PageLoader /> : (
                            <form onSubmit={formik.handleSubmit}>
                                <h3 className="section-title">Trade In Loan Information</h3>
                                <Grid container className="section-level">
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Type</label>
                                        <div class="views-widget" style={{marginLeft:"3px"}}>
                                            <select
                                                name="type"
                                                className="form_select form-select-mgp"
                                                style={{ color: '#000' }}
                                                placeholder="select"
                                                onChange={formik.handleChange}
                                                value={formik.values.type}
                                            >
                                                <option value=''>---Select Type---</option>
                                                {itemstype.map((item, index) => (
                                                    <option key={index} value={item.mortgage_type_id}  >
                                                        {item.mortgage_type}
                                                    </option>

                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Purpose</label>
                                        <div class="views-widget" style={{marginLeft:"2px"}}>
                                            <select
                                                name="purpose"
                                                className="form_select form-select-mgp"
                                                style={{ color: '#000' }}
                                                onChange={formik.handleChange}
                                                value={formik.values.purpose}
                                            >
                                                <option value="">---Select Purpose---</option>
                                                {itemsp.map((item) => (
                                                    <option key={'item.mortgage_purpose_id'} value={item.mortgage_purpose_id}  >
                                                        {item.mortgage_purpose}
                                                    </option>

                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Rate</label>
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            name="rate"
                                            id="rate"
                                            onChange={formik.handleChange}
                                            value={formik.values.rate}
                                            onInput={(e)=>{handleRange(e)}}
                                            onKeyDown={(e) => restrictDecimal(e,"rate")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Original Loan Amount</label>
                                        <NumberFormat
                                            className="numberField"
                                            thousandSeparator={','}
                                            decimalSeparator={'.'}
                                            autoComplete='off'
                                            decimalScale={2}
                                            maxLength="13"
                                            variant="outlined"
                                            name="originalLoan"
                                            onChange={formik.handleChange}
                                            value={formik.values.originalLoan}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Origination Date</label>
                                        <KeyboardDatePicker
                                            autoOk
                                            className="date-input"
                                            format="MM/dd/yyyy"
                                            placeholder="MM/DD/YYYY"
                                            name="originalDate"
                                            onChange={handleOriginalDate}
                                            value={formik.values.originalDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Guarantee Percentage<span className="mark">*</span></label>
                                        <TextField
                                            type="text"
                                            disabled
                                            variant="outlined"
                                            id='guarenteePercentage'
                                            name="guarenteePercentage"
                                            onChange={formik.handleChange}
                                            value={formik.values.guarenteePercentage}
                                            style={{ backgroundColor: '#f4f0ec' }}
                                            onInput={(e)=>{handleRange(e)}}
                                            onKeyDown={(e) => restrictDecimal(e,"guarenteePercentage")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Rule Version<span className="mark">*</span></label>
                                        <TextField
                                            type="text"
                                            disabled
                                            variant="outlined"
                                            name="rule_version"
                                            onChange={formik.handleChange}
                                            value={formik.values.rule_version}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Term Days<span className="mark">*</span></label>
                                        <TextField
                                            type="text"
                                            disabled
                                            variant="outlined"
                                            name="termDays"
                                            id="termDays"
                                            onChange={formik.handleChange}
                                            value={formik.values.termDays}
                                            style={{ backgroundColor: '#f4f0ec' }}
                                            onKeyDown={(e) => restrictDecimal(e,"termDays")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Homeowner PPG<span className="mark">*</span></label>
                                        <TextField
                                            type="text"
                                            disabled
                                            variant="outlined"
                                            name="homeowner"
                                            id='homeowner'
                                            onChange={formik.handleChange}
                                            value={formik.values.homeowner}
                                            style={{ backgroundColor: '#f4f0ec' }}
                                            onKeyDown={(e) => restrictDecimal(e,"homeowner")}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    formik.touched.type && formik.errors.type ?
                                        <div className="error-msg">{formik.errors.type}</div>
                                        :
                                        <React.Fragment>
                                            {
                                                formik.touched.originalDate && formik.errors.originalDate ?
                                                    <div className="error-msg">{formik.errors.originalDate}</div>
                                                    :
                                                    ''
                                            }
                                        </React.Fragment>
                                }
                                <h3 className="section-title">Trade In Finance</h3>
                                <Grid container className="section-level">
                                    <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                        <label>Estimated Closing Cost On Home 1</label>
                                        <NumberFormat
                                            className="numberField"
                                            thousandSeparator={','}
                                            decimalSeparator={'.'}
                                            autoComplete='off'
                                            decimalScale={2}
                                            maxLength="13"
                                            variant="outlined"
                                            name="estimatedClosingCost"
                                            onChange={formik.handleChange}
                                            value={formik.values.estimatedClosingCost}
                                        />
                                    </Grid>
                                    <Grid container className="row formDiv" xs={12} sm={12} md={12} lg={12}>
                                        <Button
                                            disableripple='true'
                                            focusRipple='true'
                                            onClick={toggleborrowerMoreInfo}
                                            className="more_btn_min d-flex justify-start"

                                        >
                                            {
                                                borrowerMoreInfo === false ? <AddSharpIcon color="secondary" style={{ color: '#1976d2 !important' }} /> : <RemoveIcon color="secondary" style={{ color: '#1976d2 !important' }} />
                                            }
                                            <span className="font-weight-bold">Additional Trade In Finance Information</span>
                                        </Button>
                                    </Grid>
                                    <Grid container className="row formDiv section-level-inner" style={borrowerMoreInfo === false ? hide : show}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>New Property Max LTV</label>
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                name="newPropertyMaxLTV"
                                                id='newPropertyMaxLTV'
                                                onChange={formik.handleChange}
                                                value={formik.values.newPropertyMaxLTV}
                                                onKeyDown={(e) => restrictDecimal(e,"newPropertyMaxLTV")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Mortgage Rate</label>
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                name="mortgageRate"
                                                id="mortageRate"
                                                onChange={formik.handleChange}
                                                value={formik.values.mortgageRate}
                                                onInput={(e)=>{handleRange(e)}}
                                                onKeyDown={(e) => restrictDecimal(e,"mortgageRate")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv" id='estimatedClosingCost'>
                                            <label>Estimated Closing Cost On Home2</label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="estimatedClosingCost2"
                                                onChange={formik.handleChange}
                                                value={formik.values.estimatedClosingCost2}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Mortgage Purpose</label>
                                            <div class="views-widget" style={{marginLeft:"4px"}}>
                                                <select
                                                    className="form_select form-select-mgp"
                                                    name="mortgagePurpose"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.mortgagePurpose}
                                                    style={{ color: '#000' }}>
                                                    <option value="">---Select Mortgage Purpose---</option>
                                                    {itemsmp.map((item) => (
                                                        <option key={'item.mortgage_purpose_id'} value={item.mortgage_purpose_id}  >
                                                            {item.mortgage_purpose}
                                                        </option>

                                                    ))}
                                                </select>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>New Property Min Down Payment</label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="downPayment"
                                                onChange={formik.handleChange}
                                                value={formik.values.downPayment}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Home 1 Commission Fees</label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="estimatedCommissionPaid"
                                                onChange={formik.handleChange}
                                                value={formik.values.estimatedCommissionPaid}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Additional Cash Down-Payment</label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="additionalCashDownPayment"
                                                onChange={formik.handleChange}
                                                value={formik.values.additionalCashDownPayment}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Existing Property Estimated Proceeds</label>
                                            <NumberFormat
                                                className="numberField"
                                                thousandSeparator={','}
                                                decimalSeparator={'.'}
                                                autoComplete='off'
                                                decimalScale={2}
                                                maxLength="13"
                                                variant="outlined"
                                                name="existingPropertyEstimatedProceeds"
                                                onChange={formik.handleChange}
                                                value={formik.values.existingPropertyEstimatedProceeds}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Maximum Funding Days</label>
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                name="maxFundingDays"
                                                id="maxFundingDays"
                                                onChange={formik.handleChange}
                                                value={formik.values.maxFundingDays}
                                                onKeyDown={(e) => restrictDecimal(e,"maxFundingDays")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Mortgage Type</label>
                                            <div class="views-widget" style={{marginLeft:"4px"}}>
                                                <select
                                                    name="mortgageType"
                                                    className="form_select form-select-mgp"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.mortgageType}
                                                    style={{ color: '#000' }}>
                                                    <option values="">---Select Mortgage Type---</option>
                                                    {itemstype.map((item) => (
                                                        <option key={'item.mortgage_type_id'} value={item.mortgage_type_id}  >
                                                            {item.mortgage_type}
                                                        </option>

                                                    ))}
                                                </select>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} className="row formDiv">
                                            <label>Mortgage Term Days</label>
                                            <TextField
                                                type="text"
                                                variant="outlined"
                                                name="mortgageTermDays"
                                                id="mortgageTermDays"
                                                onChange={formik.handleChange}
                                                value={formik.values.mortgageTermDays}
                                                onKeyDown={(e) => restrictDecimal(e,"mortgageTermDays")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className="form_layout_btn">
                                    <Button variant="contained" color="primary" type="submit" className="primary_btn" disabled={tradeInLoanBtnLoader === true}>
                                        {tradeInLoanBtnLoader === false ? 'Save' : <CircularProgress className='circular_spiner' size={20} color='white' />}
                                    </Button>
                                    <NavLink style={{ textDecoration: 'none', color: '#067DE2' }} exact to="/application-listing">
                                        <Button
                                            className="secondary_btn"
                                            variant="outlined"
                                            color="primary"
                                            style={{ background: '#fff' }}>
                                            Cancel
                                        </Button>
                                    </NavLink>
                                </Grid>
                            </form>
                        )
                    }
                </section>
            </div>
            <Snackbar open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleClose1}
                anchorOrigin={{ vertical, horizontal }} >
                <Alert onClose={handleClose1} severity={severity_type}>
                    <div className="error" style={{ color: 'white' }}>
                        <span>{detail}</span>
                    </div>
                </Alert>
            </Snackbar>
        </>
    )
}

export default Tradeinloan;