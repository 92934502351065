import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import '../../styles/Navbar.css';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import { AppBar, Toolbar, Typography, makeStyles, Button, IconButton, Drawer, Link, MenuItem, Divider } from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ModalPopUp from '../commonViews/Modal';
import Changepassword from '../commonViews/Changepassword';
import { ApplicationStatusContext } from "../../../context/FormContext";

var htBtn = null
const headersData = [
    {
        label: "Trade in Application",
        href: "/application-listing"
    },
    {
        label: "Users",
        href: "/user-listing"
    },
];
const headerDataTrade = [
    {
        label: "Trade in Application",
        href: "/application-listing"
    }
]
const headerNavColor = [
    {
        labels: "Users",
        hrefs: "/user"
    },
    {
        labels: "Trade in Application",
        hrefs: "/application-create"
    }
]
const useStyles = makeStyles(() => ({
    title: {
        flexGrow: 1,
    },
    header: {
        backgroundColor: "transparent",
        color: "black",
        "@media (max-width: 900px)": {
            paddingLeft: 0,
        },
    },
    logo: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 600,
        color: "black",
        textAlign: "left",
    },
    menuButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        size: "18px",
        marginLeft: "38px",
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    drawerContainer: {
        padding: "20px 30px",
    },
}));

export default function Navbar() {
    const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();
    const [newStatus,setNewStatus] = useContext(ApplicationStatusContext);
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });
    const { mobileView, drawerOpen } = state;
    var user_info = localStorage.getItem('user_info');
    let first_name = JSON.parse(user_info).first_name;
    let last_name = JSON.parse(user_info).last_name;
    var icon_name = first_name.charAt(0).concat(last_name.charAt(0))
    const classes = useStyles();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [navbarName, setNavbarName] = useState(false);
    const [htBtn1, setStBtn1] = useState();
    const [modal, setModal] = useState(false)
    function logout() {
        localStorage.clear()
        history.push('/');
    }

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 750
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    useEffect(() => {
        let isMounted = true;
        var data = localStorage.getItem('user_info')
        if (data != null) {
            var tempData = JSON.parse(data)
            if (tempData.is_staff === true) {
                if (isMounted) {
                    setNavbarName(true)
                }
            }
            tempData.groups && tempData.groups.map((tg) => {
                if (tg.name == "Underwriter") {
                    setNavbarName(true)
                }
            })
        }
        return () => {
            isMounted = false;
        }
    }, [])

    const handleClickOpenPass = () => {
        setModal(!modal);
    }

    const displayDesktop = () => {
        return (
            <Toolbar className={toolbar}>
                {femmecubatorLogo}
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div>{navbarName === true ? getMenuButtons() : getMenuButtonsTrade()}</div>
                    {auth && (
                        <div className="account">
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <Avatar className={classes.purple}>{icon_name}</Avatar>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem>
                                    <Avatar className="usericon">{icon_name}</Avatar>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <a style={{ textDecoration: 'none', color: 'black' }} onClick={handleClickOpenPass}>
                                        <LockOutlinedIcon />
                                        Change Password
                                    </a>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={logout}><ExitToAppOutlinedIcon />
                                    <NavLink style={{ textDecoration: 'none', color: 'black' }} exact to="/login" >
                                        <span onClick={logout}>Logout</span>
                                    </NavLink>
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </div>
            </Toolbar>
        );
    };
    const history = useHistory();
    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: true }));
        const handleDrawerClose = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: false }));
        return (
            <Toolbar>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon />
                </IconButton>

                <Drawer
                    {...{
                        anchor: "left",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}
                >
                    <div className={drawerContainer}>{navbarName === true ? getDrawerChoices() : getDrawerChoicesTrade()}</div>
                </Drawer>

                <div>{femmecubatorLogo}</div>

                {auth && (
                    <div className="account">
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar className={classes.purple}>{icon_name}</Avatar>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >   <MenuItem> <Avatar className="usericon">{icon_name}</Avatar></MenuItem>

                            <MenuItem onClick={handleClose}><LockOutlinedIcon />
                                <a style={{ textDecoration: 'none', color: 'black' }} onClick={handleClickOpenPass}>
                                    <LockOutlinedIcon />
                                    Change Password
                                </a>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={logout}>
                                <ExitToAppOutlinedIcon />
                                <NavLink style={{ textDecoration: 'none', color: 'black' }} exact to='/login' >
                                    <span onClick={logout}>Logout</span>
                                </NavLink>
                            </MenuItem>
                        </Menu>
                    </div>
                )}

            </Toolbar>
        );
    };

    const getDrawerChoicesTrade = () => {
        return headerDataTrade.map(({ label, href }) => {
            return (
                <Link
                    {...{
                        component: RouterLink,
                        to: href,
                        color: "inherit",
                        style: { textDecoration: "none" },
                        key: label,
                    }}
                >
                    <MenuItem>{label}</MenuItem>
                </Link>

            );
        });
    }

    const getDrawerChoices = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Link
                    {...{
                        component: RouterLink,
                        to: href,
                        color: "inherit",
                        style: { textDecoration: "none" },
                        key: label,
                    }}
                >
                    <MenuItem>{label}</MenuItem>
                </Link>
            );
        });
    };

    const femmecubatorLogo = (
        <Typography variant="h6" component="h1" className={logo}>
            <div className="login_img">
                <img src="/assets/images/calque logo_prototype.png" alt="image" className="h-100vh w-100"></img>
            </div>
        </Typography>
    );
    {
        auth && (
            <div className="account">
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <Avatar className={classes.purple}>{icon_name}</Avatar>
                </IconButton>

                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem>
                        <Avatar className="usericon">{icon_name}</Avatar>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <LockOutlinedIcon />
                        <NavLink style={{ textDecoration: 'none', color: 'black' }} exact to="/change-password">
                            <LockOutlinedIcon />
                            Change Password
                        </NavLink>
                    </MenuItem>
                    <Divider />

                    <div >
                        <MenuItem onClick={logout}>
                            <ExitToAppOutlinedIcon />
                            <NavLink style={{ textDecoration: 'none', color: 'black' }} exact to="/login" >
                                <span>Logout</span>
                            </NavLink>
                        </MenuItem>
                    </div>
                </Menu>
            </div>
        )
    }

    useEffect(() => {
        headersData.forEach(element => {
            if (window.location.pathname === element.href) {
                htBtn = element.label
            }
        });
        headerNavColor.forEach(ele => {
            if (window.location.pathname === ele.href) {
                setStBtn1(ele.label)
            }
        })
    }, [])

    useEffect(() => {
        headerNavColor.map((item) => {
            if (window.location.pathname === item.hrefs) {
                localStorage.setItem('highlightBtn', item.labels)
                setStBtn1(item.labels)
            }
        })
    }, [])

    function headerFun(e) {
        localStorage.setItem('highlightBtn', e.target.parentNode.id)
        localStorage.setItem("isCreate",false);
        htBtn = e.target.parentNode.id
        setStBtn1(e.target.parentNode.id)
        setNewStatus(1);
    }

    const getMenuButtonsTrade = () => {
        return headerDataTrade.map(({ label, href }) => {
            return (
                <Button style={{ color: 'black' }} id={label}
                    {...{
                        key: label,
                        color: "inherit",
                        to: href,
                        component: RouterLink,
                        className: label === (htBtn === (undefined || null) ? htBtn1 : htBtn) ? `${menuButton} blackColor` : `${menuButton} navbar_item`,
                    }}
                    onClick={(e) => { headerFun(e) }}
                >
                    {label}
                </Button>
            );
        });
    };

    const getMenuButtons = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Button style={{ color: 'black' }} id={label}
                    {...{
                        key: label,
                        color: "inherit",
                        to: href,
                        component: RouterLink,
                        className: label === (htBtn === (undefined || null) ? htBtn1 : htBtn) ? `${menuButton} blackColor` : `${menuButton} navbar_item`,
                    }}
                    onClick={(e) => { headerFun(e) }}
                >{label}
                </Button>
            );
        });
    };

    return (
        <header>
            <AppBar className={header}>
                {mobileView ? displayMobile() : displayDesktop()}
                <ModalPopUp open={modal} handleClickOpen={handleClickOpenPass} changePass={Changepassword(handleClickOpenPass)} header="Change Password" />
            </AppBar>
        </header>
    );
}
