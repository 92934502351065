import React from 'react';
import '../../styles/styleNew.css';

function PageLoader() {
    return (
        <div className="d-flex justify-center align-center vh-50">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default PageLoader;